import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
} from "@ionic/react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import Button from "../../../../components/common/button/Button";
import Header from "../../../../components/common/header/Header";
import ResponsiveContainer from "../../../../components/common/responsive-container/ResponsiveContainer";
import Spacer from "../../../../components/common/spacer/Spacer";
import { useForm } from "../../../../hooks/useForm";
import { useSupport } from "../../../../hooks/useSupport";
import { useToast } from "../../../../hooks/useToast";
import AuthStore from "../../../../store/AuthStore";
import "./ContactUsScreen.scss";

const ContactUsScreen = () => {
  const history = useHistory();
  const user = AuthStore.useState((s) => s.user);
  const { state, handleSend } = useSupport();
  const { presentToast } = useToast();
  const [values, handleInputChange, reset] = useForm({
    fullName: "",
    email: "",
    message: "",
    type: 2,
    countryId: user.countryId,
  });

  const { fullName, email, message, type } = values;

  const goToProfileScreen = () => {
    history.push("/profile");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSend(values);
  };

  const onSuccess = () => {
    reset();
    goToProfileScreen();
    presentToast(
      "Tu mensaje fue enviado con exito, tus comentarios nos ayudan a darte un mejor servicio."
    );
  };

  useEffect(() => {
    state.viewState === "SUCCESS" && onSuccess();
  }, [state.viewState]);

  return (
    <IonPage mode="ios" id="contact_us_screen">
      <Header backButton defaultHref="/profile" />
      <IonContent fullscreen>
        <ResponsiveContainer className="claro-club-content">
          <section>
            <h3>Llámanos a call-center</h3>
            <p>Marca {user.country.phone} desde tu móvil</p>
          </section>
          <section>
            <h3>Escríbenos</h3>
            <form onSubmit={handleSubmit}>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">Nombre completo</IonLabel>
                <IonInput
                  name="fullName"
                  value={fullName}
                  onIonChange={handleInputChange}
                  type="text"
                  placeholder="Ingresa nombre completo"
                  required
                />
              </IonItem>
              <Spacer height={16} />
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">Correo electrónico</IonLabel>

                <IonInput
                  name="email"
                  value={email}
                  onIonChange={handleInputChange}
                  type="email"
                  placeholder="example@mail.com"
                />
              </IonItem>
              <Spacer height={16} />

              <IonRadioGroup
                value={type}
                name="type"
                onIonChange={(e) => handleInputChange(e, e.detail.value)}
              >
                <IonRadio labelPlacement="end" value="2" mode="md">
                  Sugerencia
                </IonRadio>
                <br /><br />
                <IonRadio labelPlacement="end" value="1" mode="md">
                  Problema
                </IonRadio>
              </IonRadioGroup>

              <Spacer height={32} />
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">Comentario</IonLabel>
                <IonTextarea
                  name="message"
                  value={message}
                  onIonChange={handleInputChange}
                  rows="4"
                  placeholder="Escribinos un breve mensaje..."
                  required
                />
              </IonItem>

              <Spacer height={32} />

              <Button type="submit" label="Enviar mensaje" />
            </form>
          </section>
        </ResponsiveContainer>
        <Spacer height={32} />
      </IonContent>
    </IonPage>
  );
};

export default ContactUsScreen;
