import { useEffect, useState } from "react";
import { ViewStates } from "../const/viewStates";
import { SectionService } from "../services/SectionService";
import AuthStore from "../store/AuthStore";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useSections = () => {
    const [state, setState] = useState(initialState);
    const user = AuthStore.useState(s => s.user);

    useEffect(() => {
        loadSections();
    }, [user])

    const loadSections = async() => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });            

            const {data} = await SectionService.getAll(user?.country?.id);

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state
    }
}
