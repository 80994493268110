import { Store } from 'pullstate';
import { countries } from '../const/countries';
import { AuthService } from '../services/authService';
import { FacebookLogin } from '@capacitor-community/facebook-login';

const initialState = () => {
  return (
    JSON.parse(localStorage.getItem('session_mobile')) || {
      role: 'guest',
      country: { ...countries[0] },
    }
  );
};

const AuthStore = new Store({
  user: initialState(),
});

export const updateUser = (data) => {
  AuthStore.update((s) => {
    s.user = { ...s.user, ...data };
  });
};

export const logout = () => {
  AuthStore.update((s) => {
    s.user = initialState();
  });
};

export const handleLogin = async (credentials) => {
  try {
    const response = await AuthService.login(credentials);
    if (response.status === 200) {
      const session = {
        ...response.data.user,
        token: response.data.token,
        country: countries[response.data.user.country.id - 1],
      };
      updateUser(session);
      localStorage.setItem('session_mobile', JSON.stringify(session));
    }
    return response;
  } catch (error) {
    return Promise.reject(error?.response);
  }
};

export const handleRegister = async (params) => {
  try {
    return await AuthService.register(params);
  } catch (error) {
    return Promise.reject(error?.response);
  }
};

export const getProfileFacebook = async (params) => {
  try {
    const FACEBOOK_PERMISSIONS = ['email'];
    let token = localStorage.getItem('FACEBOOK_TOKEN');
    if (!token) {
      const result = await FacebookLogin.login({
        permissions: FACEBOOK_PERMISSIONS,
      });
      token = result?.accessToken?.token;
      console.log(result)
      localStorage.setItem('FACEBOOK_TOKEN', token);
    }
    if (token) {
      let profile = await FacebookLogin.getProfile({ fields: ['id', 'email'] });
      console.log(profile);
      return profile;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default AuthStore;
