import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const SmartNotificationsServices = {
    getSmartNotifications: ({ lat = '', lng = '', segments = [] }) => {
        const { token } = JSON.parse(localStorage.getItem('session_mobile'));

        return apiCall({
            method: 'GET',
            url: `${environment.API_URL}/clients/smart-notifications?lat=${lat}&lng=${lng}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            data: {}
        });
    },
}

export {
    SmartNotificationsServices
}