import { useState } from "react";
import { ViewStates } from "../const/viewStates";
import { SupportService } from "../services/SupportService";

const initialState = {
    viewState: ViewStates.INITIAL,
}


export const useSupport = () => {

    const [state, setState] = useState(initialState);


    const handleSend = async (params) => {

        try {
            setState({
                viewState: ViewStates.LOADING,
            }); 
            
            const response = await SupportService.create(params);
            
            setState({
                viewState: ViewStates.SUCCESS
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state,
        handleSend
    }
}