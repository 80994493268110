import { IonButton, IonIcon, IonModal } from "@ionic/react";
import "./ConfirmationRequestModal.scss";
import { closeCircleOutline } from "ionicons/icons";

const ConfirmationRequestModal = ({
  showModal,
  setShowModal,
  config,
  error,
}) => {
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <IonModal
      mode="md"
      cssClass="confirmation_request_modal"
      isOpen={showModal}
      onDidDismiss={onClose}
    >
      <div className="content">
        <div className="header">
          {!error ? (
            <IonIcon src="assets/icon/phone-confirmation.svg" />
          ) : (
            <IonIcon className="icon-error" icon={closeCircleOutline} />
          )}
          <h3>
            {!error
              ? "Solicitud enviada exitosamente"
              : "Ha ocurrido un error al enviar solicitud"}
          </h3>
          <p>
            {!error
              ? config?.aliados_request_notification_success
              : config?.aliados_request_notification_error}
          </p>
        </div>
        <IonButton
          shape="round"
          className="agreed-button"
          mode="md"
          onClick={onClose}
        >
          Entendido
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ConfirmationRequestModal;
