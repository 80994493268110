import { IonContent, IonPage } from '@ionic/react';
import Header from '../../../../components/common/header/Header';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import ResponsiveContainer from '../../../../components/common/responsive-container/ResponsiveContainer';
import CouponsList from '../../../../components/coupons-list/CouponList';

const MyCouponsScreen = () => {
    const location = useLocation();
    const value = queryString.parse(location.search);
    return (
        <IonPage mode="ios">
            <Header backButton defaultHref="/profile" />
            <IonContent fullscreen>
                <ResponsiveContainer className="claro-club-content">
                    <h3 style={{width: '100%'}}>Cupones {value.type === 'requested' ? 'solicitados' : 'canjeados'}</h3>
                    <CouponsList type={value.type}  />
                </ResponsiveContainer>
            </IonContent>
        </IonPage>
    )
}

export default MyCouponsScreen
