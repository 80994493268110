import { IonButton, IonIcon, IonImg, IonRippleEffect } from "@ionic/react";
import { star, starOutline, timeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { countries } from "../../const/countries";
import AuthStore from "../../store/AuthStore";
import ThemeStore from "../../store/ThemeStore";
import "./Coupon.scss";
import { useState } from "react";
import { useAnalytics } from "../../hooks/useAnalytics";
import { CouponsService } from "../../services/CouponsService";
import { parseDateLocal } from "../../helpers";
import { AsyncImage } from "loadable-image";

const Coupon = ({
  picture,
  title,
  endDate,
  id,
  active = true,
  code = false,
  discount = 0,
  countryId,
  isFavorite = false,
  onChange = () => {},
}) => {
  const [save, setSave] = useState(isFavorite ?? false);
  const user = AuthStore.useState((s) => s.user);
  const history = useHistory();
  const theme = ThemeStore.useState((s) => s.theme);
  const { logEvent } = useAnalytics();
  const goToCouponScreen = () => {
    active && history.push(`/coupon/${id}${code ? "?code=" + code : ""}`);
  };
  const handleInterestedCoupon = async(e) => {
    if(!user.id) return history.push("/login");
    if (!save) logEvent("Cupon_Favoritos", {name: title});
    setSave(!save);
    isFavorite = !save;
    onChange({id, isFavorite: !save});
    try {
      const resp = await CouponsService.save({benefitId: id, clientId: user?.id, isFavorite: !save});
    } catch (error) {
      
    }
  };

  return (
    <div id="claro_club_coupon" onClick={goToCouponScreen}>
      <label
        className="button-interested ion-activatable"
        onClick={(e) => e.stopPropagation()}
      >
        <input
          type="checkbox"
          // disabled={!user.id}
          checked={save}
          onChange={handleInterestedCoupon}
        ></input>
        <IonIcon className="star-solid" src="assets/icon/star.svg" />
        <IonIcon className="star-regular" icon="assets/icon/star-outline.svg" />

        <IonRippleEffect className="ripple"></IonRippleEffect>
      </label>
      <AsyncImage
        loading="lazy"
        alt={title}
        src={picture}
        className="claro_club_coupon_image"
        style={{ width: "100%", height: "160px" }}
        loader={<div style={{ background: "var(--ion-color-medium-shade)" }} />}
        error={<img src="assets/img/placeholder-claro-club.png" alt={title} />}
      />
      <div className="claro_club_coupon_content">
        <h3> {title} </h3>
        {discount > 0 && (
          <span
            className={`claro_club_coupon_discount ${theme.dark ? "dark" : ""}`}
          >
            Ahorra{" "}
            {countries.find(({ id }) => id == countryId)?.currency ||
              user?.country?.currency ||
              countries[0].currency}
            {discount}{" "}
          </span>
        )}
        <div className="claro_club_coupon_time">
          <IonIcon icon={timeOutline} />
          {active ? (
            <p>
              {" "}
              Valido hasta:{" "}
              {new Intl.DateTimeFormat("es-GT").format(new Date(endDate))}{" "}
            </p>
          ) : (
            <p>El cupón ya venció.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
