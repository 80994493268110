import React from "react";
import { categories } from "../../../../const/benefits";
import { IonIcon } from "@ionic/react";
import "./Categories.scss";
import { chevronForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { CategoriesStore } from "../../../../store/CategoriesStores";

export const Categories = ({ user }) => {
  const history = useHistory();
  const { logEvent } = useAnalytics();
  const {categories} = CategoriesStore.useState(s => s);
  const handleDetail = (category) => {
    logEvent(`Visualizar_${category?.title}_Etapa${category?.stage}`, {
      category: category?.title,
      stage: category?.stage,
      owner: user.role !== "guest",
    });
    history.push(`/benefits/${category?.name?.toLowerCase()}`);
  };
  return (
    <ul className="categories-benefits ripple">
      {categories?.map((category, index) => (
        <li
          className={`${category.in ? "active" : ""}`}
          key={category?.name}
          onClick={(e) => {
            handleDetail(category);
          }}
        >
          <div className={`item ${category.in ? "active" : ""}`}>
            <div className="icon">
              <IonIcon
                src={category.in ? category?.icon : category?.iconDisabled}
              />
            </div>
            <div className="content">
              <div className="text">
                <h3>{String(category?.name).toLowerCase()}</h3>
                <p>{category?.description}</p>
              </div>
              <IonIcon icon={chevronForwardOutline} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
