import { IonButton, IonIcon, IonModal } from "@ionic/react";
import "./GiftModal.scss";
import { Ticket } from "../../ticket/Ticket";
import { chatbubbleEllipsesOutline, shareSocial } from "ionicons/icons";
import Header from "../../common/header/Header";
import { Share } from "@capacitor/share";
import { countries } from "../../../const/countries";
import { SmsManager } from "@byteowls/capacitor-sms";
import QRCode from "qrcode.react";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Capacitor } from "@capacitor/core";

const GiftModal = ({
  id,
  coupon,
  user,
  company,
  showModal,
  setShowModal,
  logEvent,
  code,
  phone,
  countryId,
}) => {
  const onClose = () => {
    setShowModal(false);
  };

  const onShare = async () => {
    console.log(coupon?.endDate);
    if (company === "claro") {
      logEvent("Click_compartir_cupon", { name: coupon?.title });
      const canvas = document.getElementById("qr-coupon");
      const pngBase64 = canvas.toDataURL("image/png");
      const base64Data = pngBase64.replace(
        /^data:image\/(png|jpg);base64,/,
        ""
      );
      try {
        const result = await Filesystem.writeFile({
          path: "cupon.png",
          data: base64Data,
          directory: Directory.Cache,
        });
        if (result) {
          const { uri } = await Filesystem.getUri({
            directory: Directory.Cache,
            path: "cupon.png",
          });
          await Share.share({
            title: "",
            text: `Te obsequio este cupón de Claro Club ${code} más detalle en https://clubclaro.com.${
              countries[user?.country.id - 1]?.code?.toLowerCase() || "gt"
            }/#/coupon/${id} o descarga la aplicación en https://app.clarocenam.club/`,
            files: [uri],
            dialogTitle: "Obsequia este cupón a tus amigos",
          });
        }
      } catch (error) {
        console.error("Error saving or sharing image: ", error);
      }
    } else {
      logEvent("Click_invit_compe", { name: coupon?.title });
      await Share.share({
        title: "",
        text: `Te invito a que formes parte de la familia Claro y conozcas su programa de lealtad con muchos beneficios y descuentos https://clubclaro.com.${
          countries[user?.country.id - 1]?.code?.toLowerCase() || "gt"
        }`,
        dialogTitle: "¡Compártelo con tus amigos!",
      });
    }
  };

  const onSend = async () => {
    try {
      const text =
      company === "claro"
          ? `Te obsequio este cupón de Claro Club ${code} más detalle en https://clubclaro.com.${
              countries[user?.country.id - 1]?.code?.toLowerCase() || "gt"
            }/#/coupon/${id} o descarga la aplicación en https://app.clarocenam.club/`
          : `Te invito a que formes parte de la familia Claro y conozcas su programa de lealtad con muchos beneficios y descuentos https://clubclaro.com.${
              countries[user?.country.id - 1]?.code?.toLowerCase() || "gt"
            }`;
      logEvent("Click_SMS", { name: coupon?.title });
      const result = await SmsManager.send({
        numbers: [
          `+${countries.find((c) => c.id === countryId)?.areaCode}${phone}`,
        ],
        text,
      });
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonModal
      mode="md"
      cssClass="gift_modal"
      isOpen={showModal}
      onDidDismiss={onClose}
      onWillPresent={(e) => {
        if (company !== "claro") {
          logEvent("Vista_notif_compe", { name: coupon?.title });
        }
      }}
    >
      <Header closeButton onClick={onClose} />

      <div className="content">
        <div className="ticket-content">
          <Ticket title={coupon?.partner?.title} description={coupon?.title}>
            <div className="ticket-info">
              {company === "claro" ? (
                <QRCode id="qr-coupon" value={coupon?.code} renderAs="canvas" />
              ) : (
                <IonIcon className="icon-gift" src="assets/icon/gift.svg" />
              )}
              {company === "claro" ? (
                <h3>
                  Código de canje {code}
                  {/* {code?.split("-")?.length ? code.split("-")[0] : code} */}
                </h3>
              ) : (
                <p>
                  Invita a {phone} a ser parte de la familia de Claro para que
                  que pueda disfrutar de los beneficios de este programa de
                  lealtad.
                </p>
              )}
            </div>
          </Ticket>
        </div>
        {company === "claro" ? (
          <div className="share">
            <p>
              Ahora comparte este QR o código de canje con el número{" "}
              {`(+${
                countries.find((c) => c.id === countryId)?.areaCode || "502"
              }) ${phone}`}
            </p>
            <div className="action">
              <div className="button">
                <button className="ion-activatable" onClick={onShare}>
                  <IonIcon icon={shareSocial} />
                </button>
                <p>Compartir</p>
              </div>
              {Capacitor?.getPlatform() !== "web" ? (
                <>
                  <div className="divider"></div>
                  <div className="button">
                    <button className="ion-activatable" onClick={onSend}>
                      <IonIcon icon={chatbubbleEllipsesOutline} />
                    </button>
                    <p>Enviar SMS</p>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <IonButton
            shape="round"
            className="share-button"
            mode="md"
            onClick={onShare}
          >
            Compartir ahora
          </IonButton>
        )}
      </div>
    </IonModal>
  );
};

export default GiftModal;
