import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const BranchesService = {
    getAllStates:(countryId = 1) => {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/states`);
      },
      getByState: (stateId) => {
        return apiCall.get(`${environment.API_PUBLIC}/states/${stateId}/stores`);
      }
}

export {
    BranchesService
}