import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import "./Map.scss";
import { mapTheme } from "./theme";
import { useGeolocation } from "../../../../hooks/useGeolocation";
import vector from "./me-marker.png";
import { useStores } from "../../../../hooks/useStores";
import { ViewStates } from "../../../../const/viewStates";
import Button from "../../../../components/common/button/Button";
import { useHistory } from "react-router";
import Loading from "../../../../components/common/loading/Loading";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  styles: mapTheme.standar,
  draggable: true,
};
const Map = () => {
  const [map, setMap] = React.useState(null);
  const { coordinates } = useGeolocation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyASbxXcph-LX3qEOR6ovzi-pwFVqWoFaI0",
  });

  const { state, setBounds, bounds } = useStores();
  const history = useHistory();

  const goToStoreScreen = (id) => {
    history.push(`/store/${id}`);
  };

  const goToNearmeCouponsScreen = () => {
    const { blLat, blLng, trLat, trLng } = bounds;
    history.push(
      `/nearme/coupons?blLat=${blLat}&blLng=${blLng}&trLat=${trLat}&trLng=${trLng}`
    );
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    map.setOptions({
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
    });
  }, []);

  const onBoundsChanged = () => {
    const ne = map.getBounds().getNorthEast().toJSON();
    const sw = map.getBounds().getSouthWest().toJSON();
    setBounds({
      blLat: sw.lat, // SW.LAT
      blLng: sw.lng, //SW.LNG
      trLat: ne.lat, // NE.LAT
      trLng: ne.lng, // NE.LNG
    });
  };

  const onDragEnd = (event) => {
    console.log(event);
  };

  return (
    <>
      {!isLoaded ? (
        <Loading />
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={16}
          options={options}
          onLoad={onLoad}
          onBoundsChanged={onBoundsChanged}
        >
          {state.viewState === ViewStates.CONTENT &&
            state.data.map((store, index) => (
              <Marker
                key={`${store?.id}-${index}`}
                position={{ lat: store?.lat, lng: store?.lng }}
                title={store?.name}
                onClick={() => goToStoreScreen(store?.id)}
                icon={{
                  url: store.picture,
                  size: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(40, 40),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                // draggable={true}
              />
            ))}
          {coordinates && (
            <Marker
              icon={{ url: vector }}
              position={coordinates}
              // draggable={true}
            />
          )}
          <footer className="map_footer">
            <Button
              onClick={goToNearmeCouponsScreen}
              label="Ver todos los cupones en esta zona"
            />
          </footer>
        </GoogleMap>
      )}
    </>
  );
};

export default React.memo(Map);
