import { IonContent, IonPage } from "@ionic/react";
import "./NotFoundScreen.scss";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import { useTheme } from "../../hooks/useTheme";
import ErrorLottie from "../../components/error/ErrorLottie";

export const NotFoundScreen = () => {
  const { state } = useTheme();
  return (
    <IonPage>
      <IonContent className="not-found-page">
        <ResponsiveContainer className="claro-club-content">
          <div className="card-message">
            <img
            className="logo-claro-club"
              src={
                state.dark
                  ? "assets/img/logo-claro-club-white.png"
                  : "assets/img/logo-claro-club.png"
              }
              alt="logo-claro-club"
            />
            <ErrorLottie play loop dark={state.dark} style={{ width: "150px", height: "150px" }} />
            <div className="text">
              <h1>Estimado usuario</h1>
              <p>
                Nuestros servicios no están disponibles por el momento, favor de
                intentar más tarde.
              </p>
              <p>¡Gracias por tu preferencia!</p>
            </div>
          </div>
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};
