import {
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTextarea,
} from "@ionic/react";
import "./RequestPartnerScreen.scss";
import { useForm } from "../../hooks/useForm";
import Button from "../../components/common/button/Button";
import ButtonSubmit from "../../components/common/button-submit/ButtonSubmit";
import { useState } from "react";
import ConfirmationRequestModal from "../../components/modals/confirmation-request-modal/ConfirmationRequestModal";
import { useHistory } from "react-router";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import { useAnalytics } from "../../hooks/useAnalytics";
import { AdvancedConfigStore } from "../../store/AdvancedConfigStore";
import { PartnerService } from "../../services/PartnerService";
import { useToast } from "../../hooks/useToast";
import AuthStore from "../../store/AuthStore";

const RequestPartnerScreen = () => {
  const user = AuthStore.useState((s) => s.user);
  const history = useHistory();
  const { config } = AdvancedConfigStore.useState((s) => s);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [values, handleInputChange, reset] = useForm({
    name: "",
    email: "",
    phone: "",
    partner: "",
    message: "",
  });
  const { name, email, phone, partner, message } = values;
  const { logEvent } = useAnalytics();
  const [error, setError] = useState(false);
  const { presentToast } = useToast();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setLoading(true);
      const resp = await PartnerService.contacts({
        name,
        email,
        phone,
        partner,
        countryId: user?.country?.id,
      });
      if (resp.status === 200) {
        reset();
        setError(false);
        setShowConfirmationModal(true);
        presentToast("¡Solicitud enviada exitosamente!", 4000, "success");
      } else {
        setError(true);
        setShowConfirmationModal(true);
        presentToast(
          "¡Ha ocurrido un error al enviar solicitud!",
          4000,
          "danger"
        );
      }
    } catch (error) {
      setError(true);
      setShowConfirmationModal(true);
      presentToast(
        "¡Ha ocurrido un error al enviar solicitud!",
        4000,
        "danger"
      );
    } finally {
      setLoading(false);
    }
    logEvent("Click_send_form_aliado");
    setShowConfirmationModal(true);
  };

  const onClose = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    logEvent("Click_cancel_form_aliado");
    history.replace("/featured");
  };
  return (
    <IonPage className="request-partner-screen">
      <IonContent>
        <ResponsiveContainer className="claro-club-content">
          <div className="content">
            <div className="header">
              <IonIcon src="assets/icon/storefront.svg" />
              <h3>Solicitud de aliado</h3>
              <p>Envíanos tus datos para que podamos contactarte.</p>
            </div>
            <form id="formRequest" onSubmit={handleSubmit}>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">
                  Nombre
                </IonLabel>
                <IonInput
                  name="name"
                  value={name}
                  type="text"
                  placeholder="Ingresa nombre completo"
                  onIonChange={handleInputChange}
                  autocomplete="off"
                  required
                />
              </IonItem>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">
                  Correo electrónico
                </IonLabel>
                <IonInput
                  name="email"
                  value={email}
                  type="email"
                  placeholder="example@mail.com"
                  onIonChange={handleInputChange}
                  autocomplete="off"
                  required
                />
              </IonItem>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">
                  Teléfono
                </IonLabel>
                <IonInput
                  name="phone"
                  value={phone}
                  type="tel"
                  placeholder="00000000"
                  onIonChange={handleInputChange}
                  autocomplete="off"
                  required
                />
              </IonItem>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">
                  Nombre de comercio
                </IonLabel>
                <IonInput
                  name="partner"
                  value={partner}
                  type="text"
                  placeholder="Ingresa nombre del comercio"
                  onIonChange={handleInputChange}
                  autocomplete="off"
                  required
                />
              </IonItem>
              <IonItem
                mode="md"
                lines="none"
                className="claro-club-input-outline"
              >
                <IonLabel mode="ios" position="stacked">
                  Mensaje
                </IonLabel>
                <IonTextarea
                  name="message"
                  value={message}
                  type="text"
                  placeholder="Escribinos un breve mensaje..."
                  onIonChange={handleInputChange}
                  autoGrow
                  rows={5}
                />
              </IonItem>

              <div className="actions-buttons">
                <Button onClick={onClose} label="Cancelar" color="cancel" />
                <ButtonSubmit
                  loading={loading}
                  label="Enviar"
                  form="formRequest"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </ResponsiveContainer>
      </IonContent>
      <ConfirmationRequestModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        config={config}
        error={error}
      />
    </IonPage>
  );
};

export default RequestPartnerScreen;
