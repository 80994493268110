import { IonItem, IonLabel } from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import StoresListModal from "../stores-list-modal/StoresListModal";
import "./CouponContent.scss";
import QRCode from "react-qr-code";
import { useCountry } from "../../hooks/useCountry";

const CouponContent = ({
  endDate,
  header,
  label,
  title,
  branches,
  code = null,
  countryId,
  restriction = "",
  location,
}) => {
  const [showModal, setShowModal] = useState(false);
  const formatLocation = useMemo(() => {
   try {
    return JSON.parse(location || {});
   } catch (error) {
    return {}
   }
  }, [location]);

  return (
    <div id="coupon_content">
      <IonItem
        detail={true}
        lines="none"
        button
        onClick={() => setShowModal(true)}
      >
        <IonLabel>
          <h3>¿En donde puedo canjearlo?</h3>
          <p>Verifica si el beneficio está disponible en tu localidad.</p>
        </IonLabel>
      </IonItem>
      <article className="ticket">
        <header className="ticket__wrapper">
          <div className="ticket__header">{title}</div>
        </header>
        <div className="ticket__divider">
          <div className="ticket__notch"></div>
          <div className="ticket__notch ticket__notch--right"></div>
        </div>
        <div className="ticket__body">
          <section className="ticket__section">
            <h3>Información adicional</h3>
            <p>{header}</p>
          </section>
          <section className="ticket__section">
            <h3>Vigencia</h3>
            <p>
              Válido hasta el{" "}
              {Intl.DateTimeFormat("es-GT").format(new Date(endDate))}
            </p>
          </section>
          <section className="ticket__section">
            <h3>Canjeo por SMS</h3>
            <p>
              Envía la palabra {JSON.parse(label)} al{" "}
              {countryId === 4 ? "500" : "707"}
            </p>
          </section>
          {code && (
            <section className="ticket__section ticket__section--center">
              <h3>Cupón</h3>
              <p>
                Código de cupón: <b>{code?.split("-")[0]}</b>
              </p>
              <p>
                Últimos 4 dígitos de tu servicio:<b>{code?.split("-")[1]}</b>{" "}
              </p>
            </section>
          )}
          <section className="ticket__section ticket__section--center">
            <h3>Restricción</h3>
            <p>{restriction}</p>
            {code && (
              <div className="qr">
                <QRCode value={`${code}`} />
              </div>
            )}
          </section>
          {formatLocation?.departaments?.length && (
            <section className="ticket__section ticket__section--center">
              <h3>Departamentos</h3>
              {formatLocation?.departaments.map((departament, index) => (
                <p key={"dep"+index}>{departament}</p>
              ))}
            </section>
          )}
          {formatLocation?.towns?.length && (
            <section className="ticket__section ticket__section--center">
              <h3>Municipios</h3>
              {formatLocation?.towns.map((town, index) => (
                <p key={"town"+index}>{town}</p>
              ))}
            </section>
          )}
        </div>
      </article>
      <StoresListModal
        showModal={showModal}
        setShowModal={setShowModal}
        content={branches}
      />
    </div>
  );
};

export default CouponContent;
