import { Store } from 'pullstate';
import { v4 as uuidv4 } from 'uuid';
import { CouponsService } from '../services/CouponsService';
import { AdvancedConfigStore } from './AdvancedConfigStore';
import { categories, categoriesByName } from '../const/benefits';
/** Categories by show CAV */
export const CategoriesStore = new Store({
  categories: [],
  isShow: false,
  redeemed: false,
});

const orderCategories = (arr, current, before, after) => {
  const categories = [];
  for (let i = current - before; i <= current + after; i++) {
    if (i >= 0 && i < arr.length) {
      categories.push(arr[i]);
    }
  }
  return categories;
}

export const getCategories = async (user, config) => {
  try {
    if (user?.role === 'guest') {
      return CategoriesStore.update((s) => {
        s.categories = [];
        s.isShow = false;
        s.redeemed = false;
      });
    };
    const resp = await CouponsService.cav();
    if (resp?.data?.code === 200) {
      const currentIndex = resp?.data?.result?.category?.findIndex(i => i.in);
      const current = currentIndex > -1 ? currentIndex : 0;
      const order = orderCategories(resp?.data?.result?.category, current, config?.cav_category_limit_before || 0, config?.cav_category_limit_after || resp?.data?.result?.category.length);
      CategoriesStore.update((s) => {
        s.categories = order.map(d => ({ ...d, ...categoriesByName[String(d?.name)?.toLocaleLowerCase()] ?? {}, id: uuidv4() }));
        s.isShow = true;
        s.redeemed = resp?.data?.result?.canjeado;
      });
      return resp;
    }
    if (resp.data.code !== 200) {
      CategoriesStore.update((s) => {
        s.categories = [];
        s.isShow = false;
        s.redeemed = false;
      });
      return resp;
    }
  } catch (error) {
    CategoriesStore.update((s) => {
      s.categories = [];
      s.isShow = false;
      s.redeemed = false;
    });
    throw error;
  }
}