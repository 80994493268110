import { IonContent, IonPage } from '@ionic/react';
import { contrastOutline, notificationsOutline } from 'ionicons/icons';
import { useState } from 'react';
import Header from '../../../../components/common/header/Header';
import ItemDetailIcon from '../../../../components/common/item-detail-icon/ItemDetailIcon';
import { NotificationModal } from './components/notification-modal/NotificationModal';
import ThemeModal from './components/theme-modal/ThemeModal';

const SettingsScreen = () => {
    const [showThemeModal, setShowThemeModal] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);

    return (
        <IonPage mode="ios">
            <Header backButton defaultHref="/profile" />
            <IonContent fullscreen>
            <ItemDetailIcon onClick={() => setShowThemeModal(true)} icon={contrastOutline} title="Tema" detail={true} /> 
            <ItemDetailIcon onClick={()=> setShowNotificationModal(true)} icon={notificationsOutline} title="Notificaciones" detail={true} /> 
            </IonContent>
            <ThemeModal showModal={showThemeModal} setShowModal={setShowThemeModal} />
            <NotificationModal showModal={showNotificationModal} setShowModal={setShowNotificationModal}/>
        </IonPage>
    )
}

export default SettingsScreen
