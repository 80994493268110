import { IonContent, IonItem, IonList, IonModal } from '@ionic/react';
import { useEffect } from 'react';
import Header from '../../../../../../components/common/header/Header';
import { useBranches } from '../../../../../../hooks/useBranches'

const StatesModal = ({showModal, setShowModal, setState}) => {

    const {state, loadStates} = useBranches();

    useEffect(() => {
        loadStates();
    }, [])

    const onClose = (city) => {
        setState(city);
        setShowModal(false);
    }

    return (
        <IonModal
            isOpen={showModal}
            onDidDismiss={() => setShowModal(false)}
        >
            <Header closeButton onClick={() => setShowModal(false)} />
            <IonContent>

                {
                    state.data && state.data.map(city => (
                        <IonItem key={city.id} onClick={() => onClose(city)}>
                            <span>{city.title}</span>
                        </IonItem>
                    ))
                }
            </IonContent>
        </IonModal>
    )
}

export default StatesModal
