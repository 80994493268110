export const validEmail = (val) => {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(val).toLowerCase());
}


export const formatMoney = (value) => {
  let val = parseFloat(`${value}`.replaceAll(',', ''));
  let amount = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(val));

  return amount;
};


export const randomDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear - 10; // Go back 10 years from the current year
  const randomMonth = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
  const randomDay = Math.floor(Math.random() * 31) + 1; // Random day between 1 and 31

  // Generate a random date within the specified range
  const randomDate = new Date(startYear, randomMonth - 1, randomDay);

  return randomDate;
}


export const randomDateTime = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear - 10; // Go back 10 years from the current year
  const randomMonth = Math.floor(Math.random() * 12) + 1; // Random month between 1 and 12
  const randomDay = Math.floor(Math.random() * 31) + 1; // Random day between 1 and 31
  const randomHour = Math.floor(Math.random() * 24); // Random hour between 0 and 23
  const randomMinute = Math.floor(Math.random() * 60); // Random minute between 0 and 59
  const randomSecond = Math.floor(Math.random() * 60); // Random second between 0 and 59

  // Generate a random date and time within the specified range
  const randomDateTime = new Date(startYear, randomMonth - 1, randomDay, randomHour, randomMinute, randomSecond);

  return randomDateTime;
}

export const parseDateLocal = (date) => {
  if (!date) return '---'
  date = new Date(date)
  const local = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  return local.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
}