import { IonContent, IonPage } from "@ionic/react";
import { useEffect } from "react";
import Header from "../../components/common/header/Header";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import SearchInput from "../../components/common/search-input/SearchInput";
import { useForm } from "../../hooks/useForm";
import CategoryList from "./components/category-list/CategoryList";
import "./CategoriesScreen.scss";
const CategoriesScreen = () => {
  const [values, handleInputChange, reset] = useForm({ search: "" });
  const { search } = values;
  return (
    <IonPage>
      <Header />
      <IonContent>
        <ResponsiveContainer>
          <SearchInput
            name="search"
            placeholder="Buscar categorías..."
            onIonChange={handleInputChange}
            searchText={search}
          />
          <CategoryList search={search} />
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};

export default CategoriesScreen;
