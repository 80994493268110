import { IonContent, IonPage } from '@ionic/react'
import Header from '../../components/common/header/Header'
import Map from './components/map/Map'

const NearMeScreen = () => {
    return (
        <IonPage>
              <Header />
            <IonContent>
                <Map />
            </IonContent>
        </IonPage>
    )
}

export default NearMeScreen
