import { Stages } from "../stages/Stages";
import "./BenefitCard.scss";
import { IonIcon } from "@ionic/react";

export const BenefitCard = ({
  name,
  description,
  icon,
  iconDisabled,
  active,
  stages,
  selectedStage,
  setSelectedStage,
  config,
}) => {
  return (
    <div className="benefit-card">
      <div className="type-content">
        <div className="section-title">
          <div className="icon">
            <IonIcon src={active ? icon : iconDisabled} />
          </div>
          <div className="text">
            {name && <h3>{name}</h3>}
            {description && <p>{description}</p>}
          </div>
        </div>
        <p className="stage">
          {config?.cav_category_stage_title} {Number(selectedStage?.index ?? 0)+1 ?? '-'}
        </p>
      </div>
      <Stages
        stages={stages}
        selectedStage={selectedStage}
        setSelectedStage={setSelectedStage}
      />
    </div>
  );
};
