import { useEffect, useState } from "react";
import ThemeStore from "../store/ThemeStore";

export const useTheme = () => {
    
    const theme = ThemeStore.useState(t => t.theme);
    const [state, setState] = useState(theme);

    useEffect(() => {
        setState(theme);
        toggleDarkTheme();    
    }, [theme.dark]);

    const toggleDarkTheme = () => {
        // Add or remove the "dark" class 
        document.body.classList.toggle('dark', theme.dark);
        localStorage.setItem('theme', JSON.stringify({dark: theme.dark}));        
    }

    return{
        state
    }
    
}