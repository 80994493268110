import { IonCol, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { useEffect } from "react";
import { ViewStates } from "../../../../const/viewStates";
import { useCategories } from "../../../../hooks/useCategories";
import AuthStore from "../../../../store/AuthStore";
import CategoryItem from "../category-item/CategoryItem";
import Placeholder from "../../../../components/common/placeholder/Placeholder";
import CategoryItemSkeleton from "../category-item-skeleton/CategoryItemSkeleton";
import { useSegments } from "../../../../hooks/useSegments";
import { searchOutline } from "ionicons/icons";
import { useLocation } from "react-router";

const CategoryList = ({ search = "" }) => {
  const { state, loadCategories, loading } = useCategories();
  const user = AuthStore.useState((s) => s.user);
  const { loadSegments } = useSegments();
  const fakeArray = Array.from(Array(16).keys());
  const location = useLocation();

  useIonViewWillEnter(() => {
      // getFetchs();
  }, [])

  useEffect(() => {
    getFetchs();
  }, [user]);

  const getFetchs = async () => {
    if (user.role === "guest") {
      loadCategories();
    } else {
      const data = await loadSegments();
      let segments = data?.segments ?? [];
      loadCategories(segments);
    }
  };

  function filterItems() {
    if (search === "" || !search || search === null) {
      return state.data.map((category) => (
        <IonCol key={category.id} size="12" sizeXs="12" size-md="6" size-xl="4">
          <CategoryItem key={category.id} {...category} />
        </IonCol>
      ));
    }

    const filter = state.data?.filter(({ title }) =>
      title.toLowerCase().includes(search.toLowerCase())
    );
    if (!filter.length) {
      return (
        <Placeholder
          description="Sin resultados de búsqueda"
          icon={searchOutline}
        />
      );
    }
    return filter.map((category) => (
      <IonCol key={category.id} size="12" sizeXs="12" size-md="6" size-xl="4">
        <CategoryItem key={category.id} {...category} />
      </IonCol>
    ));
  }
  return (
    <>
      {(state.viewState === ViewStates.EMPTY ||
        state.viewState === ViewStates.ERROR) && (
        <Placeholder state={state.viewState} />
      )}

      {state.viewState === ViewStates.LOADING &&
        fakeArray.map((_, index) => (
          <IonCol key={index} size="12" sizeXs="12" size-md="6" size-xl="4">
            <CategoryItemSkeleton key={index} />
          </IonCol>
        ))}

      {state.viewState === ViewStates.CONTENT && filterItems()}
    </>
  );
};

export default CategoryList;
