import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AuthStore from '../store/AuthStore';
import { logEvent as logEventFirebase } from "firebase/analytics";
import { analytics } from '../config/firebase';
import { Capacitor } from '@capacitor/core';

export const useAnalytics = () => {
  const authStore = AuthStore.useState(s => s);
  const setScreenName = async (screenName) => {
    await FirebaseAnalytics.setScreenName({ screenName });
  };

  const logEvent = async (name, params) => {
    if (Capacitor.getPlatform() === 'web') {
      await logEventFirebase(analytics, name, { ...params || {}, country: authStore?.user?.country?.name });
      return;
    }
    FirebaseAnalytics.logEvent({
      name,
      params: { ...params || {}, country: authStore?.user?.country?.name },
    });
  };

  return {
    setScreenName,
    logEvent,
  };
};
