import { IonButton, IonIcon } from '@ionic/react';
import { navigateOutline } from 'ionicons/icons';
import './BranchItem.scss';

const BranchItem = ({title, lat, lng}) => {

    const navigateTo = () => {
        window.open(`https://www.google.com/maps/?q=${lat},${lng}` , '_blank');
      }

    return (
        <div id="branch_item" >
                <h3>{title}</h3>
                <IonButton fill="clear" color="dark" size="small" onClick={() => navigateTo()}><IonIcon icon={navigateOutline} slot="start" />¿Como llegar?</IonButton>
        </div>
    )
}

export default BranchItem
