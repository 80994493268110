import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Store } from 'pullstate'

const AppStore = new Store({
    online: true,
    showLoginWithService: false,
    version: {
        android: {
            build: 0,
            required: false,
            title: "",
            message: "",
            logout: false
        },
        ios: {
            build: 0,
            required: false,
            title: "",
            message: "",
            logout: false
        }
    },
    appVersion: 0,
})

export const setApp = async ({ online, showLoginWithService, version, appVersion }) => {
    AppStore.update(s => {
        s.online = online;
        s.showLoginWithService = showLoginWithService;
        s.version = version
        s.appVersion = appVersion;
    })
}

export const  getVersion = async (version) => {
    if (Capacitor?.getPlatform() === "web") return null;
    const { version: _version } = await App.getInfo();
    if (_version) return _version;
  };

export default AppStore;