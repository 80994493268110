import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import Header from "../../components/common/header/Header";
import "./BenefitScreen.scss";
import { BenefitCard } from "./components/benefit-card/BenefitCard";
import { useParams } from "react-router";
import { Exchanges } from "./components/exchanges/Exchanges";
import { Record } from "./components/record/Record";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import { useEffect, useState } from "react";
import {
  AdvancedConfigStore,
  getAdvancedConfigByCountry,
} from "../../store/AdvancedConfigStore";
import { TermsConditions } from "./components/termsConditions/TermsConditions";
import { CategoriesStore, getCategories } from "../../store/CategoriesStores";
import { useRedemptions } from "../../hooks/useRedemptions";
import { App } from "@capacitor/app";
import AuthStore from "../../store/AuthStore";

export const BenefitScreen = () => {
  const { id } = useParams();
  const { loading, redemptions, error, getRedemptions } = useRedemptions();
  const { categories, redeemed } = CategoriesStore.useState((s) => s);
  const [category, setCategory] = useState(
    categories?.find((category) => category.name?.toLowerCase().includes(id))
  );
  const [selectedStage, setSelectedStage] = useState({
    ...(category?.stages?.find((s) => s?.in) ?? category?.stages[0]),
    index: category?.stages?.findIndex((s) => s?.in) > -1 ?  category?.stages?.findIndex((s) => s?.in)  : 0,
  });
  const { config } = AdvancedConfigStore.useState((s) => s);
  const user = AuthStore.useState((s) => s.user);
  useEffect(() => {
    const category = categories?.find((category) =>
      category.name?.toLowerCase().includes(id)
    );
    setCategory(category);
    const stage = {
      ...(category?.stages?.find((s) => s?.in) ?? category?.stages[0]),
      index: category?.stages?.findIndex((s) => s?.in) > -1 ?  category?.stages?.findIndex((s) => s?.in)  : 0,
    };
    setSelectedStage(stage);
  }, [categories]);

  const fetchs = async () => {
    const config = await getAdvancedConfigByCountry({
      countryId: user?.country?.id,
    });
    await getCategories(user, config);
  };
  useEffect(() => {
    App.addListener("resume", async () => {
      await fetchs();
    });
    return () => {
      App.removeAllListeners();
    };
  }, []);

  useIonViewWillEnter(() => {
    fetchs();
  }, []);

  return (
    <IonPage>
      <Header backButton={true} defaultHref="/featured" />
      <IonContent>
        <ResponsiveContainer className="claro-club-content">
          <div className="benefit-screen-content">
            <BenefitCard
              name={category?.name}
              description={category?.description}
              icon={category?.icon}
              iconDisabled={category?.iconDisabled}
              active={category?.in}
              stages={category?.stages}
              config={config}
              selectedStage={selectedStage}
              setSelectedStage={setSelectedStage}
            />
            <p className="description">
              {config?.cav_category_stage_description}
            </p>
            {selectedStage && (
              <Exchanges
                stage={selectedStage}
                config={config}
                category={category}
                redeemed={redeemed}
                getRedemptions={getRedemptions}
                user={user}
              />
            )}
            <Record loading={loading} redemptions={redemptions} error={error} />
            <TermsConditions config={config} />
          </div>
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};
