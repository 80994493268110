import React from "react";
import "./Ticket.scss";

export const Ticket = ({ title, description, children }) => {
  return (
    <div className="ticket">
      <header className="ticket__wrapper">
        <div className="ticket__header">
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
        </div>
      </header>
      <div className="ticket__divider">
        <div className="ticket__notch"></div>
        <div className="ticket__notch ticket__notch--right"></div>
      </div>
      <div className="ticket__body">{children}</div>
    </div>
  );
};
