import { IonImg } from "@ionic/react";
import "./CouponCoverImage.scss";

const CouponCoverImage = ({ image }) => {
  return (
    <IonImg id="coupon_cover_image" src={image} onIonError={e => {
      e.target.src = '../../assets/img/placeholder-claro-club.png';
    }} />
  );
};

export default CouponCoverImage;
