import { useState } from "react";
import "./FeaturedSegment.scss";
import Segments from "../../../../components/segments/Segments";

const FeaturedSegment = ({ onChange, initialValue }) => {
  const [value, setValue] = useState(initialValue);
  return (
    <Segments
      class="featured_segment"
      value={value}
      onChange={(e) => {
        console.log(e);
        setValue(e);
        onChange?.(e);
      }}
      items={[
        {
          label: "Destacados",
          value: "featured",
        },
        {
          label: "Me interesa",
          value: "interested",
        },
        {
          label: "Lo más canjeado",
          value: "exchanged",
        },
      ]}
    />
  );
};

export default FeaturedSegment;
