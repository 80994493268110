import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';

const AppUrlListener = () => {
    const history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event) => {
        // Example url: https://app.clarocenam.club/profile
        // slug = /profile
        const slug = event.url.split('.club').pop();
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });

      return () => {
        App.removeAllListeners();
      }
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;