import { useEffect, useState } from "react";
import { useParams } from "react-router";
import SearchInput from "../../../../components/common/search-input/SearchInput";
import { ViewStates } from "../../../../const/viewStates";
import { useCategories } from "../../../../hooks/useCategories";
import StoreItem from "../store-item/StoreItem";
import SubcategoriesChips from "../subcategories-chips/SubcategoriesChips";
import Placeholder from "../../../../components/common/placeholder/Placeholder";
import AuthStore from "../../../../store/AuthStore";
import StoreItemSkeleton from "../store-item-skeleton/StoreItemSkeleton";
import { IonCol } from "@ionic/react";
import { useSegments } from "../../../../hooks/useSegments";
import { searchOutline } from "ionicons/icons";
import { useForm } from "../../../../hooks/useForm";

const StoreItemList = () => {
  const { id } = useParams();
  const { state, loadStores, loadStoresBySubcategory } = useCategories();
  const [subcategory, setSubcategory] = useState("all");
  const user = AuthStore.useState((s) => s.user);
  const fakeArray = Array.from(Array(10).keys());
  const [values, handleInputChange, reset] = useForm({ search: "" });
  const { search } = values;
  const { loadSegments } = useSegments();

  const validateRole = async () => {
    if (user.role === "guest") {
      fetchs();
    } else {
      const { segments } = (await loadSegments()) ?? { segments: [] };
      fetchs(segments);
    }
  };
  useEffect(() => {
    validateRole();
  }, [subcategory]);

  const fetchs = async (segments = []) => {
    if (subcategory === "all") {
      loadStores(id, segments);
    } else {
      loadStoresBySubcategory(subcategory, segments);
    }
  };

  function filterItems() {
    if (!search || search === null || search === "") {
      return state.data.map((store) => <StoreItem key={store.id} {...store} />);
    }
    const filter = state.data?.filter(
      ({ title, tags }) =>
        title.toLowerCase().includes(search.toLowerCase()) ||
        tags?.toLowerCase()?.includes(search?.toLowerCase())
    );
    if (!filter.length) {
      return (
        <Placeholder
          description="Sin resultados de búsqueda"
          icon={searchOutline}
        />
      );
    }
    return filter.map((store) => <StoreItem key={store.id} {...store} />);
  }
  return (
    <>
      <SearchInput
        searchText={search}
        onIonChange={handleInputChange}
        name="search"
      />
      <SubcategoriesChips onValueChange={(value) => setSubcategory(value)} />
      {(state.viewState === ViewStates.EMPTY ||
        state.viewState === ViewStates.ERROR) && (
        <Placeholder state={state.viewState} />
      )}

      {state.viewState === ViewStates.LOADING &&
        fakeArray.map((_, index) => (
          <IonCol key={index} size="12" size-md="6" size-xl="4">
            <StoreItemSkeleton key={index} />
          </IonCol>
        ))}

      {state.viewState === ViewStates.CONTENT && filterItems()}
    </>
  );
};

export default StoreItemList;
