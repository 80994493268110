import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonItem,
  IonNote,
  useIonViewWillEnter,
} from "@ionic/react";
import { walletOutline } from "ionicons/icons";
import { useEffect } from "react";
import { formatMoney } from "../../../../helpers";
import { useClient } from "../../../../hooks/useClient";
import { useCountry } from "../../../../hooks/useCountry";
import "./ProfileHeader.scss";
import { CategoriesStore } from "../../../../store/CategoriesStores";
import { AdvancedConfigStore } from "../../../../store/AdvancedConfigStore";
const ProfileHeader = ({ countryId, fullName, phone }) => {
  const { country, setCountryById } = useCountry();
  const { state, getDiscount } = useClient();
  const { categories, isShow } = CategoriesStore.useState((s) => s);
  const { config } = AdvancedConfigStore.useState((s) => s);
  useIonViewWillEnter(() => {
    getDiscount();
  });

  useEffect(() => {
    getDiscount();
  }, []);

  useEffect(() => {
    setCountryById(countryId);
  }, [country]);

  return (
    <div id="profile_header">
      <IonItem lines="none" button detail="false">
        <IonAvatar slot="start">
          <IonImg src="assets/img/profile.png" />
        </IonAvatar>
        <ion-label>
          <h2>{fullName}</h2>
          <IonNote>
            (+{country?.areaCode}) {phone}
          </IonNote>
        </ion-label>
      </IonItem>

      <div className="profile_header_card">
        {isShow && (
          <div className="item">
            <div className="icon-title">
              <div className="icon-medal">
                <IonIcon src={categories?.find((c) => c?.in)?.icon} />
              </div>
              <p>
                Eres categoría{" "}
                <strong>{categories?.find((c) => c?.in)?.name}</strong>
              </p>
            </div>
            <span className="stage">
              {config?.cav_category_stage_title}{" "}
              {Number(
                categories
                  ?.find((c) => c?.in)
                  ?.stages.findIndex((s) => s?.in) ?? 0
              ) + 1}
            </span>
          </div>
        )}
        <div className="item">
          <div className="icon-title">
            <div className="icon">
              <IonIcon icon={walletOutline} />
            </div>
            <p>Ahorrado con Claro Club</p>
          </div>
          <p className="total">
            {country?.currency}
            {formatMoney(state?.data?.discount || 0)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
