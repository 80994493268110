import { useIonToast } from "@ionic/react";

export const useToast = () => {

    const [present, dismiss] = useIonToast();

    const presentToast = (message, duration=4000, color="dark", cssClass, position="bottom") => {
        present({
            message, 
            duration, 
            position,
            cssClass, 
            color,
            // buttons: [{ text: 'hide', handler: () => dismiss() }],
            onDidDismiss: () => console.log('dismissed'),
            onWillDismiss: () => console.log('will dismiss'),
          })
    }

    return {
        presentToast
    }
}