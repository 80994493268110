import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import { ViewStates } from "../const/viewStates";
import { useSections } from "../hooks/useSections";
import { useTheme } from "../hooks/useTheme";
import "./Menu.scss";
import { menu } from "./menuAppScreens";
import { storefrontOutline } from "ionicons/icons";
import { useAnalytics } from "../hooks/useAnalytics";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useCallback, useMemo, useState } from "react";
import AppStore from "../store/AppStore";

const Menu = () => {
  const location = useLocation();
  const { state: themeState } = useTheme();
  const { state } = useSections();
  const { logEvent } = useAnalytics();
  const appVersion = AppStore.useState((s) => s.appVersion);
  const [version, setVersion] = useState("");

  return (
    <IonMenu className="claro_club_sidemenu" contentId="main" type="overlay">
      <IonHeader mode="ios">
        <IonToolbar className="claro_club_sidemenu_logo" mode="md">
          <img
            src={
              themeState.dark
                ? "assets/img/logo-claro-club-white.png"
                : "assets/img/logo-claro-club.png"
            }
            alt="logo-claro-club"
            width="200px"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="content">
          <IonList id="inbox-list">
            <IonMenuToggle
              autoHide={false}
              onClick={(e) => {
                logEvent("Click_unite_aliado");
              }}
            >
              <IonItem
                className={
                  location.pathname === "request-partner" ? "selected" : ""
                }
                routerLink={`/request-partner`}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={storefrontOutline} />
                <IonLabel>Aliados</IonLabel>
              </IonItem>
            </IonMenuToggle>
            {state.viewState === ViewStates.CONTENT &&
              state.data.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.path ? "selected" : ""
                      }
                      routerLink={`/section/${appPage.id}`}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      {/* <IonIcon slot="start" icon={appPage.icon} /> */}
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}
          </IonList>

          {Capacitor.getPlatform() !== "web" && (
            <span className="version">App version v{appVersion}</span>
          )}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
