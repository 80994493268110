import React from "react";
import CouponsList from "../coupons-list/CouponList";
import Placeholder from "../../../../components/common/placeholder/Placeholder";

export const ExchangedTab = ({ user, loading, coupons, error, search }) => {
  return !loading && !error && !coupons?.length ? (
    <Placeholder
      style={{ height: "50vh" }}
      src="../assets/icon/coupon.svg"
      description="Por el momento, no hay cupones disponibles."
    />
  ) : !loading && error ? (
    <Placeholder state="ERROR" style={{ height: "50vh" }} />
  ) : (
    <CouponsList
      user={user}
      loading={loading}
      coupons={coupons}
      error={error}
      search={search}
    />
  );
};

export default ExchangedTab;
