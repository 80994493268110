import { Store } from 'pullstate';

const getNotification = () => {
    let toggle = JSON.parse(localStorage.getItem('notification'));
    if(!toggle) localStorage.setItem('notification', JSON.stringify({isActive: true}))
    return toggle?.isActive || true;
}

const NotificationStore = new Store({
    notification: {
        isActive: getNotification()
    }
});

export const updateNotification = (isActive) => {
    NotificationStore.update(s => {
        s.notification = {isActive};
        localStorage.setItem('notification', JSON.stringify({isActive}))
    })
}
export default NotificationStore;