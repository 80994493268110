import { IonCol, IonContent, IonPage, useIonViewDidEnter } from "@ionic/react";
import { useLocation, useParams } from "react-router";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import { ViewStates } from "../../const/viewStates";
import { useCoupons } from "../../hooks/useCoupons";
import { Share } from "@capacitor/share";
import AuthStore from "../../store/AuthStore";
import Spacer from "../../components/common/spacer/Spacer";
import queryString from "query-string";
import { useAnalytics } from "../../hooks/useAnalytics";
import { countries } from "../../const/countries";
import CouponHeader from "../../components/coupon-header/CouponHeader";
import CouponCoverImage from "../../components/coupon-cover-image/CouponCoverImage";
import CouponHeaderInfo from "../../components/copoun-header-info/CouponHeaderInfo";
import CouponContent from "../../components/coupon-content/CouponContent";
import CouponFooter from "../../components/coupon-footer/CouponFooter";
import CouponScreenSkeleton from "../../components/coupon-screen-skeleton/CouponScreenSkeleton";

const CouponScreen = () => {
  const { id } = useParams();
  const location = useLocation();
  const value = queryString.parse(location.search);
  const { state, loadCoupon } = useCoupons();
  const { logEvent, setScreenName } = useAnalytics();
  const user = AuthStore.useState((s) => s.user);

  useIonViewDidEnter(() => {
    loadCoupon(id);
    logEvent("view_item", { item_id: id });
    setScreenName("benefit");
  }, [state.viewState]);

  const share = async () => {
    try {
      await Share.share({
        title: "Increíble beneficios solo en Claro Club",
        text: `¡Increíble beneficios solo en Claro Club! ${state.data?.title}`,
        url: `${countries[user?.country.id - 1]?.url}/#/coupon/${
          state.data?.id
        }`,
        dialogTitle: "¡Compártelo con tus amigos!",
      });
    } catch (error) {
      console.error("Error sharing coupon: ", error);
    }
  };

  return (
    <IonPage mode="ios">
      <CouponHeader title={state.data?.partner?.title || ""} share={share} />
      <IonContent fullscreen>
        <ResponsiveContainer>
          {state.viewState === ViewStates.EMPTY && <h3>EMPTY</h3>}

          {state.viewState === ViewStates.ERROR && <h3>Network error</h3>}

          {state.viewState === ViewStates.LOADING && <CouponScreenSkeleton />}

          {state.viewState === ViewStates.CONTENT && (
            <>
              <IonCol size="12" size-xl="6">
                <CouponCoverImage image={state.data.picture} />
              </IonCol>
              <IonCol size="12" size-xl="6">
                <CouponHeaderInfo
                  {...state.data.partner}
                  id={id}
                  logEvent={logEvent}
                />
                <CouponContent {...state.data} code={value?.code || null} />
              </IonCol>
            </>
          )}
        </ResponsiveContainer>
        <Spacer height={32} />
      </IonContent>
      {!value.code && <CouponFooter id={id} />}
    </IonPage>
  );
};

export default CouponScreen;
