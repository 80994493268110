import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import { useEffect, useState } from "react";
import Header from "../../components/common/header/Header";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import SearchInput from "../../components/common/search-input/SearchInput";
import FeaturedSegment from "./components/featured-segment/FeaturedSegment";
import { useForm } from "../../hooks/useForm";
// import BenefitModal from "../../components/modals/benefit-modal/BenefitModal";
import { Banner } from "../../components/banner/Banner";
import AuthStore from "../../store/AuthStore";
import { useSegments } from "../../hooks/useSegments";
import { Capacitor } from "@capacitor/core";
import { usePushNotifications } from "../../hooks/usePushNotifications";
import { CouponsService } from "../../services/CouponsService";
import { FavoritesTab, FeaturedTab, ExchangedTab } from "./components/tabs";

const FeaturedScreen = () => {
  const [segment, setSegment] = useState("featured");
  const [values, handleInputChange] = useForm({ search: "" });
  const { search } = values;
  const [showBenefitModal, setShowBenefitModal] = useState(false);
  const user = AuthStore.useState((s) => s.user);
  const { loadSegments } = useSegments();
  const { pushNotificationsInit } = usePushNotifications();
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
 
  const initMethods = async () => {
    if (user.role === "guest") {
      fetchs();
    } else {
      if (Capacitor.getPlatform() !== "web") {
        await pushNotificationsInit(user);
      }
      let data = (await loadSegments()) ?? { segments: [] };
      let segments = data?.segments ?? [];
      fetchs(segments);
    }
  };

  const fetchs = async (segments) => {
    try {
      setLoading(true);
      setError(false);
      const { data } = await CouponsService.getAll(
        1,
        user.country.id,
        segments,
        user?.id
      );
      setCoupons(data);
    } catch (error) {
      setError(true);
      setLoading(false);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  useIonViewWillEnter(() => {
    return () => {
      setShowBenefitModal(false);
    };
  }, []);

  useEffect(() => {
    initMethods();
    // getPromotions();
  }, [user]);

  // const getPromotions = async () => {
  //   try {
  //     if (user.role === "guest") return;
  //     setShowBenefitModal(false);
  //     const promises = await Promise.allSettled([
  //       CouponsService.promotions({
  //         countryId: user.country.id,
  //         token: user?.token,
  //       }),
  //       getCategories(user.country.id, config),
  //     ]);
  //     const [promotions, categories] = promises;
  //     if (
  //       categories.status === "rejected" ||
  //       promotions?.status === "rejected"
  //     ) {
  //       setPromotions([]);
  //       return;
  //     }
  //     if (!promotions.value?.data?.length) {
  //       setPromotions([]);
  //       return;
  //     }
  //     if (
  //       categories.value?.data?.code !== "200" &&
  //       promotions.value?.data?.length
  //     ) {
  //       console.log(isShow)
  //       setPromotions(
  //         categories.value?.data?.result?.CAV
  //           ? [...promotions?.value?.data]
  //           : promotions?.value?.data?.filter(
  //               (p) => !p.isCAV || p.isCAV === null
  //             )
  //       );
  //       setShowBenefitModal(true);
  //     } else {
  //       setPromotions(promotions?.value?.data);
  //       setShowBenefitModal(true);
  //     }
  //   } catch (error) {
  //     setPromotions([]);
  //     setShowBenefitModal(false);
  //   }
  // };

  return (
    <IonPage>
      <Header />
      <IonContent>
        <Banner />
        <ResponsiveContainer className="claro-club-content">
          <FeaturedSegment
            initialValue="featured"
            onChange={(s) => {
              setSegment(s);
            }}
          />
          <SearchInput
            name="search"
            placeholder="Buscar beneficios, eventos, tiendas"
            searchText={search}
            onIonChange={handleInputChange}
          />

          {segment === "featured" && (
            <FeaturedTab
              search={search}
              user={user}
              loading={loading}
              error={error}
              coupons={[...(coupons ?? [])]?.sort(
                (a, b) => a?.partner?.position - b?.partner?.position
              )}
            />
          )}
          {segment === "interested" && (
            <FavoritesTab
              search={search}
              user={user}
              loading={loading}
              error={error}
              coupons={[...(coupons ?? [])]?.filter((c) => c.isFavorite)}
            />
          )}
          {segment === "exchanged" && (
            <ExchangedTab
              search={search}
              user={user}
              loading={loading}
              error={error}
              coupons={[...(coupons ?? [])]?.sort(
                (a, b) => b.exchangedCount - a.exchangedCount
              )}
            />
          )}
        </ResponsiveContainer>
      </IonContent>
     
    </IonPage>
  );
};

export default FeaturedScreen;
