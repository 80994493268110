import { IonCol, useIonViewDidEnter } from "@ionic/react";
import { useEffect } from "react";
import Coupon from "../coupon/Coupon";
import { ViewStates } from "../../const/viewStates";
import { useCoupons } from "../../hooks/useCoupons";
import AuthStore from "../../store/AuthStore";
import Placeholder from "../../components/common/placeholder/Placeholder";
import CouponSkeleton from "../../components/coupon-skeleton/CouponSkeleton";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useSegments } from "../../hooks/useSegments";
import { usePushNotifications } from "../../hooks/usePushNotifications";
import { Capacitor } from "@capacitor/core";
import { searchOutline } from "ionicons/icons";

const CouponsList = ({ type = "coupons", search = "" }) => {
  const {
    state,
    loadCoupons,
    loadEvents,
    loadCouponsByClient,
    loadCouponsByBounds,
  } = useCoupons();
  const user = AuthStore.useState((s) => s.user);
  const location = useLocation();
  const { blLat, blLng, trLat, trLng } = queryString.parse(location.search);
  const { loadSegments } = useSegments();
  const { pushNotificationsInit } = usePushNotifications();
  // Fake items for the skeleton items
  const fakeArray = Array.from(Array(16).keys());
  const placeholders = {
    events: {
      src: "../assets/icon/events.svg",
      description: "Por el momento no hay promociones de eventos.",
    },
    nearme: {
      src: "../assets/icon/map.svg",
      description: "Por el momento no hay promociones cerca de ti.",
    },
    redeemed: {
      src: "../assets/icon/redeemed.svg",
      description: "Por el momento no has canjeado promociones.",
    },
    requested: {
      src: "../assets/icon/coupon.svg",
      description: "Por el momento no has solicitado promociones.",
    },
  };

  // useIonViewDidEnter(async () => {
  //   getData();
  // });

  useEffect(() => {
    getData();
  }, [user, type]);

  const getData = async () => {
    if (user.role === "guest") {
      fetchs();
    } else {
      if (Capacitor.getPlatform() !== "web") {
        await pushNotificationsInit(user);
      }
      let data = (await loadSegments()) ?? { segments: [] };
      let segments = data?.segments ?? [];
      fetchs(segments);
    }
  };

  const fetchs = async (segments = []) => {
    switch (type) {
      case "coupons":
        loadCoupons(segments);
        break;
      case "events":
        loadEvents(segments);
        break;
      case "requested":
        loadCouponsByClient(true, segments);
        break;
      case "redeemed":
        loadCouponsByClient(false, segments);
        break;
      case "nearme":
        loadCouponsByBounds({ blLat, blLng, trLat, trLng, segments });
        break;
      default:
        loadCoupons(segments);
        break;
    }
  };

  const filterItems = () => {
    if (!search || search === "" || search === null) {
      return state.data.map((coupon, index) => (
        <IonCol key={index + "" + coupon.id} size="6" size-md="6" size-xl="4">
          <Coupon
            country={user?.country}
            key={index + "" + coupon.id}
            {...coupon}
          />
        </IonCol>
      ));
    }
    const filter = state?.data?.filter(({ title }) =>
      title.toLowerCase().includes(search.toLowerCase())
    );
    if (!filter.length) {
      return (
        <Placeholder
          description="Sin resultados de búsqueda"
          icon={searchOutline}
        />
      );
    }
    return filter.map((coupon, index) => (
      <IonCol key={index + "" + coupon.id} size="6" size-md="6" size-xl="4">
        <Coupon
          country={user?.country}
          key={index + "" + coupon.id}
          {...coupon}
        />
      </IonCol>
    ));
  };

  return (
    <>
      {state.viewState === ViewStates.EMPTY ? (
        <Placeholder
          src={placeholders[type]?.src}
          description={placeholders[type]?.description}
        />
      ) : state.viewState === ViewStates.ERROR ? (
        <Placeholder state={state.viewState} />
      ) : null}

      {state.viewState === ViewStates.LOADING &&
        fakeArray.map((_, index) => (
          <IonCol key={index} size="6" size-md="6" size-xl="4">
            <CouponSkeleton key={index} />
          </IonCol>
        ))}

      {state.viewState === ViewStates.CONTENT && filterItems()}
    </>
  );
};

export default CouponsList;
