import { IonContent, IonPage } from '@ionic/react'
import Header from '../../components/common/header/Header'
import ResponsiveContainer from '../../components/common/responsive-container/ResponsiveContainer'
import StoreItemList from './components/store-item-list/StoreItemList'

const StoresScreen = () => {
    return (
        <IonPage>
                <Header backButton={true} defaultHref="/categories" />
            <IonContent>
                <ResponsiveContainer>
                <StoreItemList />
                </ResponsiveContainer>
            </IonContent>
        </IonPage>
    )
}

export default StoresScreen
