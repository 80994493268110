import { useState } from "react";
import { ViewStates } from "../const/viewStates";
import { CategoriesService } from "../services/CategoriesService";
import AuthStore from "../store/AuthStore";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useCategories = () => {

    const [state, setState] = useState(initialState);
    const user = AuthStore.useState(s => s.user);
    const [loading, setLoading] = useState(true);

    const loadCategories = async (segments) => {
        try {
            setState({
                data: [],
                viewState: ViewStates.LOADING,
            });
            const { data } = await CategoriesService.getAll(user.country.id, segments);
            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setLoading(false);
            setState({
                viewState: ViewStates.ERROR,
            });
        } finally {
        }
    }

    const loadStores = async (categoryId, segments) => {
        try {
            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CategoriesService.getAllByPartner({ categoryId, segments });

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadStoresBySubcategory = async (subcategoryId, segments) => {
        try {
            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CategoriesService.getPartnerBySubcategories({ categoryId: subcategoryId, segments });

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        loading,
        state,
        loadCategories,
        loadStores,
        loadStoresBySubcategory
    }
}