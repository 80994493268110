import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react";
import Header from "../../components/common/header/Header";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import "./BenefitsScreen.scss";
import { Categories } from "./components/categories/Categories";
import AuthStore from "../../store/AuthStore";
import {
  AdvancedConfigStore,
  getAdvancedConfigByCountry,
} from "../../store/AdvancedConfigStore";
import { useEffect } from "react";
import { App } from "@capacitor/app";
import { getCategories } from "../../store/CategoriesStores";

const BenefitsScreen = () => {
  const user = AuthStore.useState((s) => s.user);
  const { config } = AdvancedConfigStore.useState((s) => s);

  const fetchs = async () => {
    const config = await getAdvancedConfigByCountry({
      countryId: user?.country?.id,
      });
    await getCategories(user, config);
  };
  useEffect(() => {
    App.addListener("resume", async () => {
      await fetchs();
    });
    return () => {
      App.removeAllListeners();
    };
  }, []);

  useIonViewWillEnter(() => {
    fetchs();
  }, []);

  return (
    <IonPage>
      <Header backButton={true} defaultHref="/featured" />
      <IonContent>
        <ResponsiveContainer className="claro-club-content">
          <div className="benefits-screen-content">
            <div className="header">
              <h2>{config.cav_title}</h2>
              <p>{config.cav_description}</p>
            </div>
            <Categories user={user} />
          </div>
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};

export default BenefitsScreen;
