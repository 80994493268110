import { useCallback, useEffect, useState } from "react";
import AppStore, { getVersion, setApp } from "../store/AppStore";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { logout } from "../store/AuthStore";
import semver from "semver";
export const useApp = () => {
    const [showVersionModal, setShowVersionModal] = useState(false);
    const { version, online, showLoginWithService } = AppStore.useState(s => s);
    const [redirectLogin, setRedirectLogin] = useState(false);

    const getApi = useCallback(async () => {
        const resp = await fetch("https://online-api-claroclub.vercel.app/", {
            headers: {
                "Content-Type": "application/json",
            },
        });
        const { data } = await resp.json();
        checkVersion(data.version);
        const version = await getVersion(data.version);
        setApp({ online: data.online, showLoginWithService: data.showLoginWithService, version: data.version, appVersion: version });
    }, [])

    const checkVersion = async (version) => {
        const { android, ios } = version;
        const platform =Capacitor.getPlatform();
        if (platform !== "web") {
            const {version} = await App.getInfo();
            const { build, logout: _logout } = platform === "android" ? android : ios;
            if (!semver.satisfies(version, `>=${build}`)) {
                if (_logout) { 
                    setRedirectLogin(true);
                    logout();
                }
                setShowVersionModal(true);
            }
        }
    }
    useEffect(() => {
        getApi();
    }, [getApi])

    return {
        showVersionModal,
        version,
        online,
        showLoginWithService,
        setShowVersionModal,
        redirectLogin
    }
}

export default useApp;