import { IonButton, IonInput, IonItem, IonModal } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Button from "../../../../components/common/button-submit/ButtonSubmit";
import Header from "../../../../components/common/header/Header";
import ResponsiveContainer from "../../../../components/common/responsive-container/ResponsiveContainer";
import Spacer from "../../../../components/common/spacer/Spacer";
import { useAuth } from "../../../../hooks/useAuth";
import { useToast } from "../../../../hooks/useToast";
import { handleLogin, updateUser } from "../../../../store/AuthStore";
import "./CodeModal.scss";
import queryString from "query-string";

const CodeModal = ({ showModal, setShowModal, phone: service, country }) => {
  const [token, setToken] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const { presentToast } = useToast();
  const location = useLocation();

  const login = async (e) => {
    try {
      e.preventDefault();
     
      setLoading(true);
      let response = await handleLogin({
        type: 2,
        countryId: country.id,
        service,
        token,
      });
      if (response.status == 200) {
        const redirect = queryString.parse(location.search)?.redirectTo;
        history.replace(redirect ? `/${redirect}` : "/featured");
      }
      setLoading(false);
    } catch (error) {
      presentToast(
        "Oops! ha ocurrido un error, verifique sus datos e intentalo de nuevo",
        5000,
        "danger"
      );
      setLoading(false);
      return error;
    }
  };

  return (
    <div className="code_modal claro-club-full-modal">
      <form id="code-phone-form" onSubmit={login}>
        <ResponsiveContainer>
          <div className="code_modal_content">
            <h3>Código de registro</h3>

            <p>
              {" "}
              Te hemos enviado un SMS al{" "}
              <b>
                (+{country.areaCode}) {service}
              </b>{" "}
              con un código de 4 dígitos para completar tu registro.{" "}
            </p>
            <IonItem mode="md" lines="none" className="claro-club-input-outline">
              <IonInput
                autocomplete="one-time-code"
                type="tel"
                minlength="4"
                value={token}
                onIonInput={(e) => setToken(e.target.value)}
                placeholder="0000"
              />
            </IonItem>
            <Spacer height={32} />
            <Button
              type="form"
              form="code-phone-form"
              label="Ingresar"
              disabled={token === ""}
              loading={loading}
            />
          </div>
        </ResponsiveContainer>
      </form>
    </div>
  );
};

export default CodeModal;
