import { useEffect, useState } from "react";
import { Device } from '@capacitor/device';

export const useDeviceId = () => {
    const [id, setId] = useState(null);
    
    useEffect(() => {
        getId();
    }, [])

    const getId = async  () => {
        const deviceId = await Device.getId();
        setId(deviceId.uuid)
    } 

    return {
        id
    }
}