export const countries = [{
    id: 1,
    icon: 'assets/icon/guatemala.svg',
    name: 'Guatemala',
    code: 'GT',
    areaCode: '502',
    topic: 'guatemala',
    segments: [1],
    phone: '147100',
    coords: { lat: 14.6083754, lng: -90.7131131 },
    currency: 'Q',
    url: 'https://clubclaro.com.gt',
  },
  {
    id: 2,
    icon: 'assets/icon/el-salvador.svg',
    name: 'El Salvador',
    code: 'SV',
    areaCode: '503',
    topic: 'el-salvador',
    segments: [4],
    phone: '22505555 o *85',
    coords: { lat: 13.6915591, lng: -89.2502714 },
    currency: '$',
    url: 'https://clubclaro.com.sv',
  },
  {
    id: 3,
    icon: 'assets/icon/honduras.svg',
    name: 'Honduras',
    code: 'HN',
    areaCode: '504',
    topic: 'honduras',
    segments: [7],
    phone: '*11',
    coords: { lat: 14.0840719, lng: -87.2750134 },
    currency: 'L',
    url: 'https://clubclaro.hn',
  },
  {
    id: 4,
    icon: 'assets/icon/nicaragua.svg',
    name: 'Nicaragua',
    code: 'NI',
    areaCode: '505',
    topic: 'nicaragua',
    segments: [10],
    phone: '121',
    coords: { lat: 12.0978181, lng: -86.3285013 },
    currency: 'C$',
    url: 'https://clubclaro.com.ni',
  },
  {
    id: 5,
    icon: 'assets/icon/costa-rica.svg',
    name: 'Costa Rica',
    code: 'CR',
    areaCode: '506',
    topic: 'costa-rica',
    segments: [13],
    phone: '70027002',
    coords: { lat: 9.9356284, lng: -84.1483647 },
    currency: '₡',
    url: 'https://clubclaro.cr',
  },
  // {
  //   id: 6,
  //   icon: 'assets/icon/panama.svg',
  //   name: 'Panamá',
  //   code: 'PA',
  //   areaCode: '507',
  //   topic: 'panama',
  //   segments: [16],
  //   phone: '8009100 ó *611',
  //   coords: { lat: 9.0817276, lng: -79.5932247 },
  //   currency: 'B/.'
  // },
  ];