import { useState } from "react";
import { ViewStates } from "../const/viewStates";
import { CategoriesService } from "../services/CategoriesService";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useSubcategories = () => {
    const [state, setState] = useState(initialState);


    const loadSubcategories = async (categoryId, segments = []) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CategoriesService.getAllSubcategories({ categoryId, segments });

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state,
        loadSubcategories
    }
}
