import { bookmarksOutline, chatbubblesOutline, checkmarkCircleOutline, locationOutline, settingsOutline } from "ionicons/icons";

export const profileOptions = [
    {
        title: 'Cupones solicitados',
        url: '/profile/my-coupons?type=requested',
        icon: bookmarksOutline
    },
    {
        title: 'Cupones canjeados',
        url: '/profile/my-coupons?type=redeemed',
        icon: checkmarkCircleOutline
    },
    {
        title: 'Agencias Claro',
        url: '/profile/branches',
        icon: locationOutline
    },
    {
        title: 'Configuración',
        url: '/profile/settings',
        icon: settingsOutline
    },
    {
        title: 'Contáctanos',
        url: '/profile/contact-us',
        icon: chatbubblesOutline
    }
]