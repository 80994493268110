import { IonButton, IonIcon } from '@ionic/react'

const Button = ({type="button", disabled=false, onClick=()=>{}, label="", icon=null, loading, color="primary", fill="solid"}) => {
    return (
        <IonButton type={type} fill={fill} disabled={loading || disabled} color={color} onClick={onClick} className="claro-club-button" mode="md" expand="full" shape="round">
            { loading ? <IonIcon src="assets/icon/loading.svg" slot="icon-only" size="small" />: label}
        </IonButton>
    )
}

export default Button
