import { useEffect, useState } from "react";
import AuthStore from "../store/AuthStore";
import { CouponsService } from "../services/CouponsService";
import { getCategories } from "../store/CategoriesStores";
import { getAdvancedConfigByCountry } from "../store/AdvancedConfigStore";

export const usePromotions = () => {
    const user = AuthStore.useState((s) => s.user);
    const [promotions, setPromotions] = useState([]);
    const [showBenefitModal, setShowBenefitModal] = useState(false);

    const getPromotionViewsStorage = () => {
        return JSON.parse(localStorage.getItem("promotions-views")) || [];
    }

    const setPromotionViewsStorage = (promotions) => {
        localStorage.setItem("promotions-views", JSON.stringify(promotions));
    }

    const updatePromotionsViews = (promotions) => {
        try {
            let promotionsStorage = getPromotionViewsStorage();
            const promotionWithLimits = promotions?.filter(f => f?.limit !== null && Number(f?.limit) > 0)
            if (!promotionsStorage.length) {
                setPromotionViewsStorage(promotionWithLimits.map(p => ({ id: p?.id, limit: p?.limit, view: 1 })));
                return promotions;
            }
            for (const p of promotionWithLimits) {
                const index = promotionsStorage.findIndex(f => f.id === p.id);
                if (index > -1) {
                    let promotion = promotionsStorage[index];
                    promotion.view = p?.limit === promotion?.limit && promotion?.view <= p?.limit ? promotion?.view + 1 : 1;
                    promotionsStorage[index] = promotion;
                    p.view = promotion.view;
                } else {
                    promotionsStorage.push({ id: p?.id, limit: p?.limit, view: 1 });
                    p.view = 1;
                }
            }
            setPromotionViewsStorage(promotionsStorage)
            return [...promotionWithLimits?.filter(f => f.view <= f.limit) || [], ...promotions?.filter(f => f.limit === null) || []]
        } catch (error) {
            throw error;
        }
    }

    const getPromotions = async () => {
        try {
            if (user.role === "guest") {
                setPromotions([]);
                setShowBenefitModal(false);
            }
            const config = await getAdvancedConfigByCountry({
                countryId: user?.country?.id,
            });
            setShowBenefitModal(false);
            const promises = await Promise.allSettled([
                CouponsService.promotions({
                    countryId: user.country.id,
                    token: user?.token,
                }),
                getCategories(user.country.id, config),
            ]);
            const [promotions, categories] = promises;
            if (
                categories.status === "rejected" ||
                promotions?.status === "rejected"
            ) {
                setPromotions([]);
                return;
            }
            if (!promotions.value?.data?.length) {
                setPromotions([]);
                return;
            }
            if (
                categories.value?.data?.code !== "200" &&
                promotions.value?.data?.length
            ) {
                setTimeout(() => {
                    const mapper = categories.value?.data?.result?.CAV ? promotions.value?.data : promotions.value?.data.filter(
                        (p) => !p.isCAV || p.isCAV === null
                    )
                    setPromotions(mapper);
                    if (mapper.length) setShowBenefitModal(true);
                }, 700);
            } else {
                setPromotions(promotions?.value?.data);
                setShowBenefitModal(true);
            }
        } catch (error) {
            setPromotions([]);
            setShowBenefitModal(false);
        }
    };

    useEffect(() => {
        getPromotions();
    }, [user])

    return {
        promotions,
        showBenefitModal,
        getPromotions,
        setShowBenefitModal
    }
}