import { IonContent, IonModal } from '@ionic/react'
import Header from '../common/header/Header';
import QRCode from 'react-qr-code';
import Button from '../common/button/Button';
import './CouponRedeemModal.scss';

const CouponRedeemModal = ({ showModal, setShowModal, code }) => {

    return (
        <IonModal cssClass="coupon_redeem_modal" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <Header closeButton onClick={() => setShowModal(false)} />
            <IonContent className="claro-club-content">
                <div className="coupon_redeem_modal_wrapper">
                    <QRCode value={`${code}`} />
                    <div className="coupon-card">
                        <h2>Cupón: {code?.split('-')?.length ? code.split('-')[0] : code}</h2>
                        {/* <span>Código de canje</span> */}

                        <h3>¡Disfruta de tu beneficio!</h3>
                        <p>
                            <b>Tus cupones se guardarán en tu perfíl.</b>
                        </p>
                        <Button label="Entendido" onClick={() => setShowModal(false)} />
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default CouponRedeemModal
