import { IonRoute, IonRouterOutlet, useIonViewWillEnter } from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import LoginScreen from "../screens/login/LoginScreen";
import Menu from "./Menu";
import NavigationWithTabs from "./NavigationWithTabs";
import { Route, Redirect, Switch } from "react-router-dom";
import AuthenticationScreen from "../screens/authentication/AuthenticationScreen";
import CountryScreen from "../screens/country/CountryScreen";
import AuthStore from "../store/AuthStore";
import AppUrlListener from "./AppUrlListener";
import {
  AdvancedConfigStore,
  getAdvancedConfigByCountry,
} from "../store/AdvancedConfigStore";
import { getCategories } from "../store/CategoriesStores";
import { NotFoundScreen } from "../screens/404/NotFoundScreen";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { useCallback, useEffect } from "react";
import VersionModal from "../components/modals/version-modal/VersionModal";
import VerifiedOnline from "./VerifiedOnline";
import useApp from "../hooks/useApp";
import BenefitModal from "../components/modals/benefit-modal/BenefitModal";
import { usePromotions } from "../hooks/usePromotions";

const ClaroClubApp = () => {
  const user = AuthStore.useState((s) => s.user);
  // const config = AdvancedConfigStore.useState((s) => s.config);
  // const fetchs = async () => {
  //   const config = await getAdvancedConfigByCountry({
  //     countryId: user?.country?.id,
  //   });
  //   await getCategories(user, config);
  // };
  const {
    online,
    showVersionModal,
    setShowVersionModal,
    version,
    redirectLogin,
  } = useApp();

  const {setShowBenefitModal, showBenefitModal, promotions} = usePromotions();

  const initFacebook = useCallback(async () => {
    await FacebookLogin.initialize({ appId: "406476046950940" });
  }, []);

  useEffect(() => {
    initFacebook();
  }, [initFacebook]);

  // useEffect(() => {
  //   fetchs();
  // }, [user]);

  return (
    <IonReactHashRouter>
      <AppUrlListener />
      <Menu />
      <VerifiedOnline online={online} />
      {/* {redirectLogin && <Redirect to="/login" />} */}
      <IonRouterOutlet id="main">
        <Route path="" exact component={NavigationWithTabs} />
        <Route exact path="/login/country">
          <CountryScreen />
        </Route>
        <Route exact path="/login/auth">
          <AuthenticationScreen />
        </Route>

        <Route exact path="/login">
          {user.role === "guest" ? (
            <LoginScreen />
          ) : (
            <Redirect to="/featured" />
          )}
        </Route>
        <Route exact path="/404">
          <NotFoundScreen />
        </Route>
        <Route exact path="/">
          {user.role === "guest" || redirectLogin ? (
            <Redirect to="/login" />
          ) : (
            <Redirect to="/featured" />
          )}
        </Route>
      </IonRouterOutlet>
      <VersionModal
        version={version}
        showModal={showVersionModal}
        setShowModal={setShowVersionModal}
      />
       <BenefitModal
        promotions={promotions}
        showModal={showBenefitModal}
        setShowModal={setShowBenefitModal}
      />
    </IonReactHashRouter>
  );
};

export default ClaroClubApp;
