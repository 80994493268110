import { IonButton, IonIcon } from '@ionic/react'

const Button = ({form="", type="button", disabled=false, onClick=()=>{}, label="", icon=null, loading, color="primary", fill="solid"}) => {
    return (
        <button form={form} type={type} fill={fill} disabled={loading || disabled} color={color} onClick={onClick} className="claro-club-button form-button primary" mode="md" expand="full" shape="round">
            { loading ? <IonIcon src="assets/icon/loading.svg" slot="icon-only" size="small" />: label}
        </button>
    )
}

export default Button
