import { IonButton, IonIcon, IonModal } from "@ionic/react";
import "./ProfitModal.scss";
import { useAnalytics } from "../../../hooks/useAnalytics";
const ProfitModal = ({ benefit, stage, showModal, setShowModal, config, user }) => {
  const { logEvent } = useAnalytics();
  const onClose = () => {
    setShowModal(false);
  };

  const providers = {
    GT: "https://paquetes.miclaro.com.gt",
    SV: "https://paquetes.miclaro.com.sv",
    HN: "https://paquetes.miclaro.com.hn",
    NI: "https://paquetes.miclaro.com.ni",
    CR: "https://paquetes.miclaro.cr",
}

  return (
    <IonModal
      mode="md"
      cssClass="profit-modal"
      isOpen={showModal}
      onDidDismiss={onClose}
    >
      <div className="content">
        <div className="header">
          <IonIcon src="assets/icon/ticket.svg" />
          <h3>¿Cómo obtener este beneficio?</h3>
          <p className="title">{benefit?.beneficio}</p>
          <p className="description">
            {config?.cav_bennefit_how_to} {stage?.range}
          </p>
        </div>
        <div className="actions">
          <IonButton shape="round" className="agreed-button" onClick={onClose}>
            Entendido
          </IonButton>
          <a
            href={providers[user?.country?.code]}
            target="_blank"
            onClick={(e) => {
              logEvent("Click_Boton_MCE");
            }}
            rel="noreferrer"
          >
            <span>Ir Mi Claro express</span>
          </a>
        </div>
      </div>
    </IonModal>
  );
};

export default ProfitModal;
