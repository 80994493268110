import { IonContent, IonIcon, IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import Header from '../../../../components/common/header/Header'
import BranchList from './components/branch-list/BranchList'
import StateSelector from './components/state-selector/StateSelector'
import StatesModal from './components/states-modal/StatesModal'

const BranchesScreen = () => {

    const [state, setState] = useState(null);
    const [showModal, setShowModal] = useState(false);



    return (
        <IonPage mode="ios">
            <Header backButton defaultHref="/profile" />
            <IonContent fullscreen>
                <StateSelector onClick={() => setShowModal(true)} title={state?.title || 'Seleccione un departamento'} />
                <BranchList stateId={state?.id || null} />
            </IonContent>
            <StatesModal showModal={showModal} setShowModal={setShowModal} setState={setState} />
        </IonPage>
    )
}

export default BranchesScreen
