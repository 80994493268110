import { IonCol } from "@ionic/react";
import Coupon from "../../../../components/coupon/Coupon";
import Placeholder from "../../../../components/common/placeholder/Placeholder";
import CouponSkeleton from "../../../../components/coupon-skeleton/CouponSkeleton";
import { searchOutline } from "ionicons/icons";

const CouponsList = ({
  coupons,
  search = "",
  loading,
  user,
  segment,
  error,
  setCoupons = () => {},
}) => {
  // Fake items for the skeleton items
  const fakeArray = Array.from(Array(16).keys());
  const filterItems = () => {
    if (!search || search === "" || search === null) {
      return coupons?.map((coupon, index) => (
        <IonCol key={index + "" + coupon.id} size="6" size-md="6" size-xl="4">
          <Coupon
            onChange={(e) => {
              const mapper = coupons.map((c) => {
                if (c.id === e.id) {
                  c.isFavorite = e.isFavorite;
                }
                return c;
              });
              setCoupons(mapper);
            }}
            country={user?.country}
            key={index + "" + coupon.id}
            {...coupon}
          />
        </IonCol>
      ));
    }
    const filter = coupons?.filter(
      ({ title, tags }) =>
        title.toLowerCase().includes(search.toLowerCase()) ||
        tags
          ?.map((t) => t.title)
          ?.some((tag) => tag.toLowerCase().includes(search.toLowerCase()))
    );
    if (!filter.length) {
      return (
        <Placeholder
          description="Sin resultados de búsqueda"
          icon={searchOutline}
        />
      );
    }
    return filter.map((coupon, index) => (
      <IonCol key={index + "" + coupon.id} size="6" size-md="6" size-xl="4">
        <Coupon
          country={user?.country}
          key={index + "" + coupon.id}
          onChange={(e) => {
            const mapper = coupons.map((c) => {
              if (c.id === e.id) {
                c.isFavorite = e.isFavorite;
              }
              return c;
            });
            setCoupons(mapper);
          }}
          {...coupon}
        />
      </IonCol>
    ));
  };

  return (
    <>
      {loading
        ? fakeArray.map((_, index) => (
            <IonCol key={index} size="6" size-md="6" size-xl="4">
              <CouponSkeleton key={index} />
            </IonCol>
          ))
        : !loading && !error
        ? filterItems()
        : null}
    </>
  );
};

export default CouponsList;
