import { IonIcon, IonInput, IonItem, IonNote } from "@ionic/react"
import { phonePortraitOutline } from "ionicons/icons"
import { useEffect, useState } from "react";
import Spacer from "../../../../components/common/spacer/Spacer";
import { useAuth } from "../../../../hooks/useAuth";
import { useDeviceId } from "../../../../hooks/useDeviceId";
import AuthStore, { handleRegister } from "../../../../store/AuthStore";
import CodeModal from "../code-modal/CodeModal";
import './PhoneMethod.scss';
import { isPlatform } from '@ionic/react';
import Button from "../../../../components/common/button/Button";
import ButtonSubmit from "../../../../components/common/button-submit/ButtonSubmit";
import CountryModal from "../../../../components/modals/country-modal/CountryModal";
import { updateNavigate } from "../../../../store/NavigationStore";
import { useToast } from "../../../../hooks/useToast";
import HelpCenterModal from "../../../../components/modals/help-center-modal/HelpCenterModal";
import Facebook from "../facebook/Facebook";
import { FacebookLogin } from "@capacitor-community/facebook-login";


const PhoneMethod = ({phone, setPhone}) => {
    const [showModalHelpCenter, setShowModalHelpCenter] = useState(false)

    const user = AuthStore.useState(s => s.user);
    const [country, setCountry] = useState(user.country);
    const [showModal, setShowModal] = useState(false);
    const [showCountryModal, setShowCountryModal] = useState(false);
    const { id } = useDeviceId();
    const [loading, setLoading] = useState(false)
    const { presentToast } = useToast();
    const [error, setError] = useState({})

    const register = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            let response = await handleRegister(
                {
                    countryId: user.country.id,
                    fullName: phone,
                    phone,
                    uuid: id,
                    platform: isPlatform('ios') ? 'IOS' : isPlatform('android') ? 'Android' : 'Web'
                });

            if (response.status == 200) {
                updateNavigate({isClosed: true, onClick: () => {
                    setShowModal(false);
                    updateNavigate({isClosed: false, onClick: () => {}})
                }})
                setShowModal(true);
                setLoading(false);
            }
        } catch (err) {
            presentToast('Oops! ha ocurrido un error, verifique sus datos e intentalo de nuevo', 5000, 'danger')
            setLoading(false);
            if(err?.data?.case) {
                setError({...err?.data})
                setShowModalHelpCenter(true)
            }
            return err
        }
    }

    useEffect(() => {
        setCountry(user.country);
    }, [user])

    function openCloseModal() {
        updateNavigate({isClosed: true, onClick: () => {
            setShowModal(false);
            updateNavigate({isClosed: false, onClick: () => {}})
        }})
        setShowModal(true);
    }
    
    return (
        <div className="phone_method">

            {!showModal && <>
                <IonIcon className="phone-icon" icon={phonePortraitOutline} color="primary" />
                <p>Ingresa tu número Claro móvil para obtener tu código de acceso</p>

                <div className="phone_method_country" onClick={() => setShowCountryModal(true)}>
                    <IonIcon icon={country.icon} />
                    <p>{country.name}</p>
                </div>

                <form id="phone-form" onSubmit={register}>
                    <IonItem mode="md" lines="none" className="claro-club-input-outline">
                        <IonInput type="tel" value={phone} onIonInput={(e) => setPhone(e.target.value)} placeholder="5555-5555" minlength="8" required>
                            <IonNote style={{ marginLeft: 8 }} slot="start">(+{country.areaCode})</IonNote>
                        </IonInput>
                    </IonItem>
                    <Spacer height={16} />
                </form>

                <ButtonSubmit type="form" form="phone-form" disabled={phone === ""} label="Continuar" loading={loading} />
                <Button onClick={openCloseModal} label="Ya tengo código de acceso" color="dark" fill="clear" />
                <Spacer height={16} />
                </>}

            {showModal && <CodeModal showModal={showModal} setShowModal={setShowModal} phone={phone} country={country} />}
            <CountryModal showModal={showCountryModal} setShowModal={setShowCountryModal} selectedCountry={country.id} />
            <HelpCenterModal showModal={showModalHelpCenter} setShowModal={setShowModalHelpCenter} numberCase={error?.case} country={country}  />

        </div>
    )
}

export default PhoneMethod
