import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const SegmentsServices = {
    getAll: async ({ phone = '' }) => {
        return apiCall({
            method: 'GET',
            url: `${environment.API_URL}/users/${phone}/segments`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {}
        })
    }
}

export {
    SegmentsServices
}