import React from 'react'
import { IonContent, IonModal } from '@ionic/react'
import Header from '../../../../../../components/common/header/Header'
import './NotificationModal.scss'
import { ToggleNotification } from './toggle-notifiaction/ToggleNotification'

export const NotificationModal = ({showModal, setShowModal}) => {
    return (
        <IonModal
            isOpen={showModal}
            cssClass="claro-club-full-modal theme_modal"
            onDidDismiss={() => setShowModal(false)}
        >
            <Header closeButton onClick={() => setShowModal(false)} />
            <IonContent className="claro-club-content">
                <h3>Seleccione una opción</h3>
                <ToggleNotification />
            </IonContent>

        </IonModal>
    )
}
