export const couponsInterested = [
    {
        "id": 19722,
        "title": "Kitchen Aid - 15% de descuento en tienda en línea",
        "startDate": "2023-05-16T06:00:00.000+0000",
        "endDate": "2024-01-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 9402,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/0ce63513-cec2-531b-b484-0b9044ccb434.png",
        "partner": {
            "id": 9402,
            "title": "Kitchen Aid",
            "description": "Kitchen Aid"
        }
    },
    {
        "id": 19723,
        "title": "Whirlpool - 15% de descuento en tienda en línea",
        "startDate": "2023-05-16T06:00:00.000+0000",
        "endDate": "2024-01-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 9403,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/f3823741-7bb8-52b3-8d52-0d9d84569a11.png",
        "partner": {
            "id": 9403,
            "title": "Whirlpool",
            "description": "Whirlpool"
        }
    },
    {
        "id": 11183,
        "title": "POPS - Bola de helado extra gratis en Vaca Negra de vainilla y Coca Cola",
        "startDate": "2022-09-05T06:00:00.000+0000",
        "endDate": "2024-01-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 1388,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/315e8f00-3558-59e0-990e-0097ed854a26.png",
        "partner": {
            "id": 1388,
            "title": "POPS",
            "description": "Pops"
        }
    },
    {
        "id": 12342,
        "title": "Futeca Bowling - 20% de descuento en todos los servicios",
        "startDate": "2022-05-24T06:00:00.000+0000",
        "endDate": "2024-01-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 6562,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/adb3d8f3-6c0a-5a2c-af84-32e0a197c501.png",
        "partner": {
            "id": 6562,
            "title": "Futeca Bowling",
            "description": "Futeca Bowling"
        }
    },
    {
        "id": 481,
        "title": "Carl's Jr - Combo Dúo California, con 2 papas, 2 gaseosas regulares y 2 strudel a Q109",
        "startDate": "2020-12-31T06:00:00.000+0000",
        "endDate": "2023-11-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 1403,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/9da477ab-ae3d-5eb4-9714-acb00105ba65.png",
        "partner": {
            "id": 1403,
            "title": "Carl's Jr",
            "description": "Carls Jr"
        }
    },
    {
        "id": 493,
        "title": "Krispy Kreme - Docena de Glaseada Original por Q79.",
        "startDate": "2020-08-19T06:00:00.000+0000",
        "endDate": "2024-01-01T05:59:00.000+0000",
        "startDateEvent": null,
        "endDateEvent": null,
        "partnerId": 1387,
        "discount": 0,
        "picture": "https://clubclaro.com.gt:7506/server/public/v1/files/14165e8b-8335-53a4-bf84-958c14b915dc.png",
        "partner": {
            "id": 1387,
            "title": "Krispy Kreme",
            "description": "Krispy Kreme"
        }
    }
]