import { IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react"
import { close } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useTheme } from "../../../hooks/useTheme";
import AuthStore from "../../../store/AuthStore";
import ThemeStore from "../../../store/ThemeStore";
import CountryModal from "../../modals/country-modal/CountryModal";
import './Header.scss';

const Header = ({backButton = false, defaultHref="/", closeButton=false, onClick=() => {}, logo=true, className=""}) => {

    const [showModal, setShowModal] = useState(false);
    const user = AuthStore.useState(s => s.user);
    const {state} = useTheme();
    const [country, setCountry] = useState(user.country);

    useEffect(() => {
        setCountry(user.country);
    }, [user])

    return (
        <>
            <IonHeader mode="ios" id="claro_club_header" className={className}>
                <IonToolbar mode="md">
                    <IonButtons slot="start">
                        {(!backButton && !closeButton) && <IonMenuButton />}
                        {backButton && <IonBackButton  defaultHref={defaultHref} />} 
                        {closeButton && <IonButton className="close-button" color="light" fill="solid" shape="round" onClick={onClick}> <IonIcon slot="icon-only" color="dark" icon={close} />  </IonButton>}                     
                    </IonButtons>
                    <IonButtons slot="end">
                        {logo &&  <img src={state.dark ? 'assets/img/logo-claro-club-white.png' : 'assets/img/logo-claro-club.png'} width="80px" />}
                        {(!backButton && !closeButton) && <IonButton onClick={() => setShowModal(true)} size="small" className="claro_club_header_country" fill="outline" color="dark">
                            {country.code}
                        </IonButton>}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <CountryModal showModal={showModal} setShowModal={setShowModal} selectedCountry={country.id}/>
        </>
    )
}

export default Header
