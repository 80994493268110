import { IonButton, IonIcon } from "@ionic/react";
import { phonePortraitOutline } from "ionicons/icons";
import "./Exchanges.scss";
import RedeemedBenefitModal from "../../../../components/modals/redeemed-benefit-modal/RedeemedBenefitModal";
import { useState } from "react";
import ProfitModal from "../../../../components/modals/profit-modal/ProfitModal";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import { CouponsService } from "../../../../services/CouponsService";

export const Exchanges = ({
  category,
  stage,
  redeemed,
  config,
  getRedemptions,
  user
}) => {
  const [showRedeemedModal, setShowRedeemedModal] = useState(false);
  const [showProfitModal, setShowProfitModal] = useState(false);
  const [loading, setLoading] = useState({ id: null, loading: false });
  const { logEvent } = useAnalytics();
  const [error, setError] = useState(null);
  const [benefit, setBenefit] = useState(null);
  const onClick = async (benefit, type) => {
    setError(false);
    setBenefit(benefit);
    if (type) {
      logEvent(`Click_Canjear_${category?.name}_${stage?.name}`, {
        nameBenefit: benefit?.beneficio,
      });
      setLoading({ id: benefit?.idBeneficio, loading: true });
      try {
        setLoading({ id: benefit?.idBeneficio, loading: true });
        const resp = await CouponsService.redemptions({
          category: category?.name,
          stage: stage?.name,
          benefit: benefit?.beneficio,
          benefitId: benefit?.idBeneficio,
        });
        if (resp.status === 200) {
          setError(null);
          setLoading({ id: benefit?.idBeneficio, loading: false });
          setShowRedeemedModal(true);
        }
        if (resp.status !== 200) {
          setError(resp?.message);
          setShowRedeemedModal(true);
          setLoading({ id: benefit?.idBeneficio, loading: false });
        }
      } catch (error) {
        if (error) {
          setError(error);
          setShowRedeemedModal(true);
          setLoading({ id: benefit?.idBeneficio, loading: false });
        }
      } finally {
        setLoading({ id: benefit?.idBeneficio, loading: false });
      }
      return;
    }
    logEvent(`Vista_Benef_Bloqueado`, {
      category: category?.name,
      stage: stage?.name,
    });
    setShowProfitModal(true);
    return;
  };
  return (
    <section className="exchanges-section">
      <div className="info">
        <h2>
          Beneficios{" "}
          {String(config?.cav_category_stage_title ?? "")?.toLowerCase()}{" "}
          {Number(stage?.index ?? 0) + 1}
        </h2>
        {stage?.in && category?.in && redeemed ? (
          <p className="redeemed">¡Ya has realizado el canje en este periodo!</p>
        ) : null}
      </div>
      <ul className="exchanges">
        {stage?.benefits?.map((benefit) => (
          <li key={benefit?.idBeneficio}>
            <div className="title">
              <IonIcon icon={phonePortraitOutline} />
              <p>{benefit?.beneficio}</p>
            </div>
            <IonButton
              className="redeem-button"
              shape="round"
              fill="outline"
              color="primary"
              disabled={
                loading?.loading || (stage?.in && category?.in && redeemed)
              }
              onClick={(e) => {
                onClick(benefit, category?.in && stage?.in);
              }}
            >
              {loading?.id === benefit?.idBeneficio && loading?.loading ? (
                <IonIcon
                  src="assets/icon/loadingCustom.svg"
                  slot="icon-only"
                  size="small"
                />
              ) : category?.in && stage?.in ? (
                "Canjear ahora"
              ) : (
                "¿Cómo obtenerlo?"
              )}
            </IonButton>
          </li>
        ))}
      </ul>
      <RedeemedBenefitModal
        config={config}
        showModal={showRedeemedModal}
        setShowModal={setShowRedeemedModal}
        error={error}
        benefit={benefit}
        getRedemptions={getRedemptions}
      />
      <ProfitModal
        benefit={benefit}
        stage={stage}
        config={config}
        showModal={showProfitModal}
        setShowModal={setShowProfitModal}
        user={user}
      />
    </section>
  );
};
