import { IonCol, IonSkeletonText } from "@ionic/react";
import './CouponScreenSkeleton.scss';

const CouponScreenSkeleton = () => {
    return (
        <div id="coupon_screen_skeleton">
            <IonSkeletonText animated className="coupon_screen_skeleton_image" />
            <div className="coupon_header_screen_skeleton">
                <IonSkeletonText animated className="coupon_header_screen_title" />
                <IonSkeletonText animated className="coupon_header_screen_description" />
                <IonSkeletonText animated className="coupon_content_screen_discount" />
                <IonSkeletonText animated className="coupon_content_screen_list" />
            </div>
        </div>
    );
}

export default CouponScreenSkeleton;