import { Store } from 'pullstate';
import { PropertiesServices } from '../services/PropertiesServices';

export const AdvancedConfigStore = new Store({
    config: {
        home_cav_banner_title: "Ahora puedes obtener más beneficios pre-pago",
        home_cav_banner_button_title: "Conocer beneficios",
        cav_title: "Claro premia tu fidelidad",
        cav_description: "Tus recargas y tu tiempo de antiguedad te permite obtener los mejores beneficios para tu servicio prepago.",
        cav_option_limit: 5,
        cav_category_limit_before: 2,
        cav_category_limit_after: 1,
        cav_category_stage_title: "Etapa",
        cav_category_stage_description: `Recuerda que puedes cambiar cada uno de tus beneficios a partir del 2 de cada mes y obtener acceso a los siguientes beneficios al aumentar el valor de tus recargas.`,
        cav_category_stage_notification_success: ` Recuerda que con Claro tus recargas y tu tiempo de antiguedad te dan los mejores beneficios.`,
        cav_category_stage_notification_error: "Ocurrió un error al canjear tu beneficio, por favor intenta nuevamente.",
        cav_terms_conditions: "Terminos y condiciones",
        cav_bennefit_how_to: "Para canjear este beneficio las recargas de los próximos 3 meses deberán ser de",
        coupon_gift_title: "¡Regala este cupón a uno de tus familiares o amigos!",
        coupon_gift_description: ` Indícanos el número de teléfono de la persona a la que le vas a regalar este cupón.`,
        aliados_request_notification_success: "Estás a un paso de convertirte en uno de nuestros establecimientos aliados, nos pondremos en contacto contigo lo más pronto posible.",
        aliados_request_notification_error: "Ocurrió un error al enviar tu solicitud, por favor intenta nuevamente.",
        // cav_show_amount: 1
    }
});

export const updateAdvancedConfig = ({ config }) => {
    AdvancedConfigStore.update((s) => {
        s.config = { ...s.config, ...config };
    });
}

export const getAdvancedConfigByCountry = async ({countryId}) => {
    try {
        const resp = await PropertiesServices.getAllByCountry({ countryId });
        const _config = resp?.data?.reduce((acc, {key, value}) => ({...acc, [key]: value}), {}) ?? {}
        const config = { 
            ..._config,
        }
        AdvancedConfigStore.update((s) => {
            s.config = {
                ...s.config,
                ...config
            };
        })
        return config;
    } catch (error) {
        
    }
}