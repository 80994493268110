import { IonButton } from "@ionic/react";
import "./Facebook.scss";
import { handleLogin, getProfileFacebook } from "../../../../store/AuthStore";
import ErrorModal from "../error-modal/ErrorModal";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

const Facebook = ({}) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const signInFacebook = async () => {
    try {
      const profile = await getProfileFacebook();
      let response = await handleLogin({
        type: 1,
        facebookId: profile.id,
      });
      if (response.status == 200) {
        const redirect = queryString.parse(location.search)?.redirectTo;
        history.replace(redirect ? `/${redirect}` : "/featured");
      }
    } catch (error) {
      if (error?.status == 401) {
        localStorage.removeItem("FACEBOOK_TOKEN");
        setShowModal(true);
      }
    }
  };

  return (
    <div className="section-facebook">
      <div className="facebook-card">
        <p>¿Ya ingresaste antes y asociaste tu Facebook a tu número Claro?</p>
        <p className="enter-here">Ingresa aquí</p>
        <IonButton
          shape="round"
          color="tertiary"
          mode="md"
          onClick={signInFacebook}
        >
          f
        </IonButton>
      </div>
      <ErrorModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Facebook;
