import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import { createRef, useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import "./InputSelect.scss";

const InputSelect = ({
  label,
  name,
  id = (Math.random() + 1).toString(36).substring(7),
  value,
  onChange,
  items,
  loading = false,
  width = "100%",
  required = false,
  readonly = true,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [selected, setSelected] = useState(value);
  const ref = createRef();
  useOutsideClick(ref, () => {
    setIsFocus(false);
  });

  const onClick = async (e) => {
    e.stopPropagation();
    setIsFocus(!isFocus);
  };

  const handleChange = (e, item) => {
    e.stopPropagation();
    setIsFocus(!isFocus);
    setSelected(item);
    onChange(item, name);
  };

  return (
    <div ref={ref} className="input-select-container">
      <IonItem
        mode="md"
        className={`input-select ${isFocus ? "active" : ""}`}
        onClick={onClick}
        lines="none"
      >
        <IonLabel position="stacked">{label}</IonLabel>
        <IonInput
          {...{ value: value?.label, type: "text", name, readonly, required }}
        />
        <span>
          <IonIcon icon={isFocus ? chevronUpOutline : chevronDownOutline} />
        </span>
      </IonItem>
      {isFocus && (
        <div className="input-select-items">
          {items.map((item, index) => (
            <div
              className={`item ${item.id === selected?.id ? "selected" : ""}`}
              key={index}
              onClick={(e) => handleChange(e, item)}
            >
              <p className="title">{item?.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default InputSelect;
