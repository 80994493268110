import { randomDateTime } from "../helpers";
import { v4 as uuidv4 } from 'uuid';


export const steps = [{
    id: 1,
    description: "de Q. 0.7 a Q. 7.50",
}, {
    id: 2,
    description: "de Q. 8 a Q. 24",
}, {
    id: 3,
    description: "de Q. 25 a Q. 90",
}, {
    id: 4,
    description: "más de Q. 90",
}, {
    id: 5,
    description: "más de Q. 90",
},]


export const banners = [{
    id: uuidv4(),
    image: "https://clubclaro.com.gt:7506/server/public/v1/files/0ce63513-cec2-531b-b484-0b9044ccb434.png",
    title: "Ahora puedes obtener más beneficios prepago",
    description: ` Canjea beneficios prepago para obtener promociones para clientes de alto valor como tú`,
    action: {
        type: 'button',
        label: 'Ver cupón',
        link: null,
    }
}, {
    id: uuidv4(),
    image: "https://clubclaro.com.gt:7506/server/public/v1/files/9790e845-04af-59e8-991d-a14a5a8af1b0.png",
    title: "Ahora puedes obtener más beneficios prepago",
    description: `Canjea beneficios prepago para obtener promociones para clientes de alto valor como tú`,
    action: {
        type: 'link',
        label: 'Ir a Claro express',
        link: 'https://paquetes.miclaro.com.gt/',

    }
}, {
    id: uuidv4(),
    image: "https://clubclaro.com.gt:7506/server/public/v1/files/b1c4ede6-9111-56a8-b314-ad49959293f6.png",
    title: "Ahora puedes obtener más beneficios prepago",
    description: ` Canjea beneficios prepago para obtener promociones para clientes de alto valor como tú`,
    action: {
        type: 'button',
        label: 'Ver beneficios',
        link: null,
    }
}]

export const categoriesByName = {
    clasico:
    {
        icon: 'assets/icon/classic-medal.svg',
        name: 'Clásico',
        description: 'Antiguedad de 3 a 5 meses.',
        iconDisabled: 'assets/icon/disabled-classic-medal.svg'
    },
    bronce:
    {
        icon: 'assets/icon/bronze-medal.svg',
        name: 'Bronce',
        description: 'Antiguedad de 6 a 11 meses.',
        iconDisabled: 'assets/icon/disabled-medal.svg'
    },
    plata:
    {
        icon: 'assets/icon/silver-medal.svg',
        name: 'Plata',
        description: 'Antiguedad de 12 a 23 meses.',
        iconDisabled: 'assets/icon/disabled-medal.svg'
    },
    oro:
    {
        icon: 'assets/icon/gold-medal.svg',
        name: 'Oro',
        description: 'Antiguedad de 24 a 35 meses.',
        iconDisabled: 'assets/icon/disabled-medal.svg'
    },
    platino:
    {
        icon: 'assets/icon/platinum-medal.svg',
        name: 'Platino',
        description: 'Antiguedad de 36 a 71 meses.',
        iconDisabled: 'assets/icon/disabled-platinum-medal.svg'
    },
    diamante:
    {
        icon: 'assets/icon/diamond-medal.svg',
        name: 'Diamante',
        description: 'Antiguedad mayor a 72 meses.',
        iconDisabled: 'assets/icon/disabled-diamond-medal.svg'
    }
}

export const categories = {
    "result": {
        "canjeado": false, // no permitir mas canjes desactivar boton canjear ahora
        "category": [
            {
                "in": false,
                "name": "CLASICO",
                "stages": [
                    {
                        "benefits": [
                            {
                                "beneficio": "30 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "250 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "ULV",
                        "description": "ULTRA LOW VALUE",
                        "range": "Desde GTQ 0.00 hasta GTQ 8.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "40 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "250 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "LV",
                        "description": "LOW VALUE",
                        "range": "Desde GTQ 8.00 hasta GTQ 23.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "60 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "300 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "MV",
                        "description": "MEDIUM VALUE",
                        "range": "Desde GTQ 23.00 hasta GTQ 94.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "80 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "600 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "HV",
                        "description": "HIGH VALUE",
                        "range": "Desde GTQ 94.00 hasta GTQ 7822.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "80 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "600 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "UHV",
                        "description": "ULTRA HIGH VALUE",
                        "range": "Desde GTQ 7822.00 hasta GTQ 0.00"
                    }
                ]
            },
            {
                "in": true,
                "name": "BRONCE",
                "stages": [
                    {
                        "benefits": [
                            {
                                "beneficio": "40 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "300 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "ULV",
                        "description": "ULTRA LOW VALUE",
                        "range": "Desde GTQ 0.00 hasta GTQ 8.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "50 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "300 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": true,
                        "name": "LV",
                        "description": "LOW VALUE",
                        "range": "Desde GTQ 8.00 hasta GTQ 23.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "75 minutos para hablar a Claro por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "500 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "MV",
                        "description": "MEDIUM VALUE",
                        "range": "Desde GTQ 23.00 hasta GTQ 94.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "100 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1 GB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "HV",
                        "description": "HIGH VALUE",
                        "range": "Desde GTQ 94.00 hasta GTQ 7822.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "100 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1 GB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "UHV",
                        "description": "ULTRA HIGH VALUE",
                        "range": "Desde GTQ 7822.00 hasta GTQ 0.00"
                    }
                ]
            },
            {
                "in": false,
                "name": "PLATA",
                "stages": [
                    {
                        "benefits": [
                            {
                                "beneficio": "50 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "400 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "ULV", // Titulo de los beneficios 
                        "description": "ULTRA LOW VALUE",
                        "range": "Desde GTQ 0.00 hasta GTQ 8.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "60 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "400 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "LV",
                        "description": "LOW VALUE",
                        "range": "Desde GTQ 8.00 hasta GTQ 23.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "75 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "600 MB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "MV",
                        "description": "MEDIUM VALUE",
                        "range": "Desde GTQ 23.00 hasta GTQ 94.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "100 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1.2 GB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "HV",
                        "description": "HIGH VALUE",
                        "range": "Desde GTQ 94.00 hasta GTQ 7822.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "100 minutos para hablar a Guate por 1 dia.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1.2 GB de internet  por 1 dia.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "UHV",
                        "description": "ULTRA HIGH VALUE",
                        "range": "Desde GTQ 7822.00 hasta GTQ 0.00"
                    }
                ]
            },
            {
                "in": false,
                "name": "ORO",
                "stages": [
                    {
                        "benefits": [
                            {
                                "beneficio": "60 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "500 MB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "ULV",
                        "description": "ULTRA LOW VALUE",
                        "range": "Desde GTQ 0.00 hasta GTQ 8.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "75 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "500 MB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "LV",
                        "description": "LOW VALUE",
                        "range": "Desde GTQ 8.00 hasta GTQ 23.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "100 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1 GB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "MV",
                        "description": "MEDIUM VALUE",
                        "range": "Desde GTQ 23.00 hasta GTQ 94.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "120 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1.5 GB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "HV",
                        "description": "HIGH VALUE",
                        "range": "Desde GTQ 94.00 hasta GTQ 7822.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "120 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1.5 GB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "UHV",
                        "description": "ULTRA HIGH VALUE",
                        "range": "Desde GTQ 7822.00 hasta GTQ 0.00"
                    }
                ]
            },
            {
                "in": false,
                "name": "PLATINO",
                "stages": [
                    {
                        "benefits": [
                            {
                                "beneficio": "70 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "600 MB de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "ULV",
                        "description": "ULTRA LOW VALUE",
                        "range": "Desde GTQ 0.00 hasta GTQ 8.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "80 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1GB  de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "LV",
                        "description": "LOW VALUE",
                        "range": "Desde GTQ 8.00 hasta GTQ 23.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "120 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "1.5 GB  de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "MV",
                        "description": "MEDIUM VALUE",
                        "range": "Desde GTQ 23.00 hasta GTQ 94.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "150 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "2 GB  de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "HV",
                        "description": "HIGH VALUE",
                        "range": "Desde GTQ 94.00 hasta GTQ 7822.00"
                    },
                    {
                        "benefits": [
                            {
                                "beneficio": "150 minutos para hablar a Guate por 2 dias.",
                                "idBeneficio": 1
                            },
                            {
                                "beneficio": "2 GB  de internet  por 2 dias.",
                                "idBeneficio": 2
                            }
                        ],
                        "in": false,
                        "name": "UHV",
                        "description": "ULTRA HIGH VALUE",
                        "range": "Desde GTQ 7822.00 hasta GTQ 0.00"
                    }
                ]
            }
        ]
    },
    "code": 200 // 200 - mostrar cav, 400 - usuario tipo postgrado no mostrar cav , 500 - error solicitud
}