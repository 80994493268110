import { IonButton, IonContent, IonHeader, IonIcon, IonModal } from "@ionic/react";
import { planetOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import ResponsiveContainer from "../../../../components/common/responsive-container/ResponsiveContainer";
import ModalHeader from "../../../../components/modals/modal-header/ModalHeader";
import './ErrorModal.scss';

const ErrorModal = ({ showModal, setShowModal = () => { } }) => {
    const history = useHistory();
    const goToCountryScreen = (method) => {
        setShowModal(false)
        history.push(`/login/auth?method=${method}`)
    }
    return (
        <IonModal id="error_social_modal" mode="ios" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <ModalHeader title="" onClick={setShowModal} />
            <IonContent>
                <ResponsiveContainer>
                    <div className="container-error-social">
                        <div className="section-error">
                            <IonIcon icon={planetOutline} color="primary" />
                            <p>¡Lo sentimos! No hemos encontrado ninguna red social asociada a tu número.</p>

                        </div>
                        <div className="section-action">
                            <p>Ingresa con tu número Claro móvil o residencial.</p>
                            <div className="buttons">
                                <IonButton onClick={() => goToCountryScreen('phone')} shape="round" color="primary" mode="md">
                                    Ingresar con número móvil
                                </IonButton>
                                <IonButton onClick={() => goToCountryScreen('other')} shape="round" color="primary" fill="outline" mode="md">
                                    Ingresar con mi servicio residencial
                                </IonButton>
                            </div>

                        </div>
                    </div>

                </ResponsiveContainer>
            </IonContent>
        </IonModal>
    );
}

export default ErrorModal;