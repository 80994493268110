export const AUTH_METHODS = {
  1: [{ id: 2, name: 'serviceNumber', label: 'Número de línea fija' }],
  2: [
    { id: 2, name: 'serviceNumber', label: 'Número de línea fija' },
    { id: 3, name: 'contractNumber', label: 'Número de contrato' },
  ],
  3: [
    { id: 2, name: 'serviceNumber', label: 'Número de línea fija' },
    { id: 3, name: 'contractNumber', label: 'Número de contrato' },
  ],
  4: [{ id: 3, name: 'contractNumber', label: 'Número de contrato' }],
  5: [{ id: 3, name: 'contractNumber', label: 'Número de contrato' }],
  6: [{ id: 4, name: 'customerId', label: 'Número de identificación' }],
};
