import { IonContent, IonList, IonModal } from '@ionic/react'
import { searchOutline } from 'ionicons/icons'
import { useEffect } from 'react'
import Header from '../common/header/Header'
import Placeholder from '../common/placeholder/Placeholder'
import SearchInput from '../common/search-input/SearchInput'
import Spacer from '../common/spacer/Spacer'
import { useForm } from '../../hooks/useForm'
import StoreListItem from './components/store-list-item/StoreListItem'
const StoresListModal = ({showModal, setShowModal, content=[]}) => {
    const [values, handleInputChange, reset] = useForm({search: '' });
    const { search } = values;

    function filterItems() {
        if(!search || search === null || search === '') {
            return content.map(store => (
                <StoreListItem key={store.id} {...store} />
            )) 
        }
        const filter = content?.filter(v => `${v?.title} ${v?.addrress} ${v?.phone}`.toLowerCase().includes(search.toLowerCase()));
        if(!filter.length) {
            return <Placeholder description="Sin resultados de búsqueda" icon={searchOutline}/>
        }
        return filter.map(store => (
            <StoreListItem key={store.id} {...store} />
        ))
    }
    return (
        <IonContent>
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <Header closeButton onClick={() => setShowModal(false)} />
                <IonContent style={{ height: '100vh'}}>
                    <SearchInput name="search" onIonChange={handleInputChange} searchText={search}/>
                    {content.length && filterItems()}
                    <Spacer />
                </IonContent>
            </IonModal>

        </IonContent>
    )
}

export default StoresListModal
