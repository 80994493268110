import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const PartnerService = {
    contacts(partner) {
        return apiCall.post(`${environment.API_PUBLIC}/partners/contacts`, partner);
    }
}

export {
    PartnerService
}