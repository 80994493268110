import { IonFooter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../common/button/Button";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useCoupon } from "../../hooks/useCoupon";
import AuthStore from "../../store/AuthStore";
import CouponRedeemModal from "../coupon-redeem-modal/CouponRedeemModal";
import "./CouponFooter.scss";
import useAlert from "../../hooks/useAlert";

const CouponFooter = ({ id }) => {
  const user = AuthStore.useState((s) => s.user);
  const { state, redeem } = useCoupon();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { logEvent } = useAnalytics();
  const { showAlert } = useAlert();

  const goToLoginScreen = () => {
    history.push(`/login?redirectTo=coupon/${id}`);
  };

  const handleRedeem = () => {
    if (user?.role !== "guest") {
      redeem(id);
      logEvent("get_benefit", { countryId: user.country.id, benefitId: id });
    } else {
      goToLoginScreen();
    }
  };

  const validateRedeem = () => {
    if (state.viewState === "SUCCESS") {
      return setShowModal(true);
    }
    if (
      state.viewState === "ERROR" &&
      (state.status === 423 || state.status === 404)
    ) {
      return showAlert({
        header: "Claro Club",
        subHeader: "Información del beneficio",
        message:
          state.status === 423
            ? "Este beneficio ha llegado a su límite de canjes, espera pronto para más promociones."
            : state.status === 404
            ? "El beneficio no existe."
            : "",
        buttons: ["Entendido"],
      });
    }
  };

  useEffect(() => {
    validateRedeem();
  }, [state.viewState]);

  return (
    <>
      <IonFooter id="coupon_footer" onClick={handleRedeem}>
        <Button label="Obtener cupón" loading={state.viewState === "LOADING"} />
      </IonFooter>
      <CouponRedeemModal
        showModal={showModal}
        setShowModal={setShowModal}
        code={state.data?.code}
      />
    </>
  );
};

export default CouponFooter;
