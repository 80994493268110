import axios from "axios";
import { environment } from "../const/environment";


// exclude urls
const excludedUrl = [
  `/login`,
  `/register`,
  `/auth`,
  `${environment.API_PUBLIC}`];
  
  export const apiCall = axios.create({
    baseURL: "https://clubclaro.com.gt",
    // baseURL: "https://demo.clarocenam.club",
});

apiCall.interceptors.request.use(
  request => {
    // request.headers['ngrok-skip-browser-warning'] = true;
    request.headers['Content-Type'] = 'application/json'
    
    if (!excludedUrl.some(url => request.url.indexOf(url) > -1 ? true : false)) {
      try {
        const { token } = JSON.parse(localStorage.getItem('session_mobile')) || { token: '' };
        request.headers['Authorization'] = `Bearer ${token}`;
      } catch (error) {

      }
    }
    return request
  }
)