import {
  IonButton,
  IonHeader,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./CouponHeaderInfo.scss";
import { useHistory } from "react-router";
import { useAnalytics } from "../../hooks/useAnalytics";

const CouponHeaderInfo = ({ id, title, logEvent }) => {
  const history = useHistory();

  return (
    <div className="coupon_header_info">
      <h3 size="large">{title}</h3>
      <IonButton
        fill="outline"
        color="primary"
        shape="round"
        mode="md"
        onClick={(e) => {
          logEvent("Click_regalar_cupon", { name: title });
          history.push(`/coupon/${id}/gift`);
        }}
      >
        Regalar cupón
      </IonButton>
    </div>
  );
};

export default CouponHeaderInfo;
