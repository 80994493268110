import { useState } from "react";
import { ViewStates } from "../const/viewStates";
import { ClientService } from "../services/ClientService";


const initialState = {
    viewState: ViewStates.INITIAL,
}

export const useClient = () => {

    const [state, setState] = useState(initialState);


    const handleUpdate = async (fcmId, id) => {

        try {
            setState({
                viewState: ViewStates.LOADING,
            }); 
            
            const response = await ClientService.update({
                fcmId,
                id
              });
            setState({
                viewState: ViewStates.SUCCESS
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const getDiscount = async() => {
        try {
            setState({
                viewState: ViewStates.LOADING,
            }); 
            
            const {data} = await ClientService.getDiscount();
            setState({
                data,
                viewState: ViewStates.SUCCESS
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }



    return{
        handleUpdate,
        getDiscount,
        state
    }

}