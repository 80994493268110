import { home, homeOutline, map, mapOutline, personCircle, personCircleOutline, ticket, ticketOutline, today, todayOutline } from "ionicons/icons";
import CategoriesScreen from "../screens/categories/CategoriesScreen";
import EventsScreen from "../screens/events/EventsScreen";
import FeaturedScreen from "../screens/featured/FeaturedScreen";
import NearMeScreen from "../screens/near-me/NearMeScreen";
import ProfileScreen from "../screens/profile/ProfileScreen";

export const tabs = [
    {
        label: 'Inicio',
        path: '/featured',
        tab: 'featured',
        icon: home,
        iconOutline: homeOutline,
        component: FeaturedScreen,
    },
    {
        label: 'Cupones',
        path: '/categories',
        tab: 'coupons',
        icon: ticket,
        iconOutline: ticketOutline,
        component: CategoriesScreen
    },
    {
        label: 'Eventos',
        path: '/events',
        tab: 'events',
        icon: today,
        iconOutline: todayOutline,
        component: EventsScreen
    },
    {
        label: 'Cerca de mí',
        path: '/nearme',
        tab: 'nearme',
        icon: map,
        iconOutline: mapOutline,
        component: NearMeScreen
    },
    {
        label: 'Perfíl',
        path: '/profile',
        tab: 'profile',
        icon: personCircle,
        iconOutline: personCircleOutline,
        component: ProfileScreen
    }]