import "./TermsConditions.scss";
export const TermsConditions = ({ category, config }) => {
 
 
  return (
    <section className="terms-conditions-section">
      <h2>Términos y condiciones</h2>
     <p>{config?.cav_terms_conditions}</p>
    </section>
  );
};
