import { IonSkeletonText } from '@ionic/react'
import './CouponSkeleton.scss';

const CouponSkeleton = () => {
    return (
        <div id="coupon_skeleton">
            <IonSkeletonText animated className="coupon_skeleton_image"/>
            <div className="coupon_skeleton_content">
            <IonSkeletonText animated className="coupon_skeleton_title"/>
            <IonSkeletonText animated className="coupon_skeleton_title"/>
            <IonSkeletonText animated className="coupon_skeleton_text"/>
            </div>
        </div>
    )
}

export default CouponSkeleton
