export const configAnalytics = {
    apiKey: "AIzaSyB912hOLn9MYwElNmjHEmZvIV-IgxZJZcI",
    authDomain: "xenon-tuner-282619.firebaseapp.com",
    databaseURL: "https://xenon-tuner-282619.firebaseio.com",
    projectId: "xenon-tuner-282619",
    storageBucket: "xenon-tuner-282619.appspot.com",
    messagingSenderId: "532054950070",
    appId: "1:532054950070:web:e71eced79fe7c7114ee617",
    measurementId: "G-7M6CJBM5LP"
};
