import './StateSelector.scss';

const StateSelector = ({onClick, title}) => {
    return (
        <div id="state_selector" onClick={onClick}>
            <p>{title}</p>
        </div>
    )
}

export default StateSelector
