import { IonIcon, IonToggle } from '@ionic/react';
import { moonOutline, sunnyOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useTheme } from '../../../../../../../../hooks/useTheme';
import { toggleTheme } from '../../../../../../../../store/ThemeStore';
import './ThemeSelector.scss';

const ThemeSelector = () => {
    
    const {state} = useTheme();

    const handleChange = ({target}) => {
        toggleTheme(target.checked);
    }

    return (
        <div id="theme_selector">
            <section>
                <IonIcon icon={sunnyOutline} />
                <span>Claro</span>
            </section>

            <IonToggle mode="ios" checked={state.dark}  onClick={e => handleChange(e)} />

            <section>
                <IonIcon icon={moonOutline} />
                <span>Oscuro</span>
            </section>
            
        </div>
    )
}

export default ThemeSelector
