import { useEffect, useState } from "react";
import { CouponsService } from "../services/CouponsService";

export const useRedemptions = () => {
    const [redemptions, setRedemptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getRedemptions();
    }, [])

    const getRedemptions = async () => {
        try {
            setLoading(true);
            const resp = await CouponsService.getRedemptions();
            if (resp?.status === 200) {
                setRedemptions(resp?.data);
                return resp?.data;
            }
            if (resp?.status !== 200) {
                setError(true);
                return [];
            }
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        redemptions,
        error,
        getRedemptions
    }

}