import { IonContent, IonPage } from '@ionic/react'
import Header from '../../components/common/header/Header'
import ResponsiveContainer from '../../components/common/responsive-container/ResponsiveContainer'
import CouponsList from '../../components/coupons-list/CouponList'

const NearMeCouponsScreen = () => {
    return (
        <IonPage>
              <Header backButton defaultHref="/nearme"/>
            <IonContent>
            <ResponsiveContainer className="claro-club-content">
                <CouponsList type="nearme" />
            </ResponsiveContainer>
            </IonContent>
        </IonPage>
    )
}

export default NearMeCouponsScreen
