import { useStoreState } from "pullstate";
import { useState } from "react";
import Coupon from "../components/coupon/Coupon";
import { ViewStates } from "../const/viewStates";
import { CouponsService } from "../services/CouponsService";
import AuthStore from "../store/AuthStore";
import { couponsInterested } from "../const/coupons";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useCoupons = () => {

    const [state, setState] = useState(initialState);
    const user = AuthStore.useState(s => s.user);
    // // Fake items for the skeleton items
    // const fakeArray = Array.from(Array(16).keys());


    const loadCoupons = async (segments, isFavorite) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });


            const { data } = await CouponsService.getAll(1, user.country.id, segments, user?.id);
            setState({
                data: isFavorite !== undefined ?  data.filter(coupon => isFavorite ? coupon.isFavorite : !coupon.isFavorite) : data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadEvents = async (segments) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CouponsService.getAllEvents(1, user.country.id, segments, user?.id);

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadCoupon = async (id) => {
        try {
            if (state.viewState !== ViewStates.CONTENT) {
                setState({
                    viewState: ViewStates.LOADING,
                });
            }
            const { data } = await CouponsService.getOne(id);
            setState({
                data,
                viewState: data ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }



    const loadCouponsByPartner = async (partnerId, segments, clientId) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CouponsService.getAllByStore(partnerId, segments, clientId);

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadCouponsByClient = async (active, segments) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CouponsService.getAllByClient(user.id, active, segments);
            let formatData = data.length ? data.map(coupon => {
                let c = {
                    ...coupon,
                    ...coupon.benefit,
                }
                delete c.benefit;
                return c
            }) : [];

            setState({
                data: formatData,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadCouponsByBounds = async ({...input}) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await CouponsService.getAllByBounds({ countryId: user.country.id, ...input, clientId: user?.id});

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadInterestedCoupons = () => {
        setState({viewState: ViewStates.LOADING})
        setTimeout(() => {
            setState({
                data: couponsInterested,
                viewState: couponsInterested.length ? ViewStates.CONTENT : ViewStates.EMPTY
            })
        }, 800);
    }



    return {
        state,
        loadCoupon,
        loadCoupons,
        loadCouponsByPartner,
        loadEvents,
        loadCouponsByClient,
        loadCouponsByBounds,
        loadInterestedCoupons
    }
}
