import { IonIcon, IonImg, IonLabel } from '@ionic/react'
import { chevronForwardOutline, ticketOutline } from 'ionicons/icons'
import { useHistory } from 'react-router';
import './StoreItem.scss';

const StoreItem = ({title, amountBenefits, picture, id}) => {
    const history = useHistory();
  
    const goToStoreScreen = () => {
        history.push(`/store/${id}`);
    }
    return (
        <div id="store_item" lines="none" onClick={goToStoreScreen}>
            <IonImg src={picture} onIonError={e => {
                e.target.src = 'https://clubclaro.com.gt/content/images/default.jpeg'
            }}  />
            <IonLabel className="store_item_title">
                <h2>{title}</h2>
                <div className="store_item_subtitle">
                    <IonIcon icon={ticketOutline} slot="start" />
                    <p>{amountBenefits} Cup{amountBenefits > 1 ? 'ones' : 'ón'}</p>
                </div>
            </IonLabel>
            <IonIcon slot="end" className="store_item_detail" icon={chevronForwardOutline} />
        </div>
    )
}

export default StoreItem
