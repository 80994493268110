import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { shareSocial } from "ionicons/icons";
import "./CouponHeader.scss";

const CouponHeader = ({ title = "Tienda", share }) => {
  return (
    <IonHeader id="coupon_header" mode="ios">
      <IonToolbar mode="ios">
        <IonButtons slot="start" mode="md">
          <IonBackButton text="" defaultHref="/" />
        </IonButtons>
        {share && (
          <IonButtons slot="end" mode="md">
            <IonButton onClick={share} shape="round" color="light" fill="solid">
              <IonIcon slot="icon-only" icon={shareSocial} />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};

export default CouponHeader;
