import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { ViewStates } from '../const/viewStates';
import { CategoriesService } from '../services/CategoriesService';
import { SegmentsServices } from '../services/SegmentsService';
import AuthStore from '../store/AuthStore';
import NotificationStore from '../store/NotificationStore';
import { usePushNotifications } from './usePushNotifications';
import { useSmartNotification } from './useSmartNotifications';

const initialState = {
  segments: [],
  topics: [],
  viewState: ViewStates.INITIAL,
};

export const useSegments = () => {
  const notification = NotificationStore.useState((s) => s.notification);
  const user = AuthStore.useState((s) => s.user);
  const { saveTopics } = usePushNotifications();
  const { loadSmartNotifications } = useSmartNotification();
  const history = useHistory();

  const getNotifications = async (segments) => {
    const { coords } = await Geolocation.getCurrentPosition();
    const { latitude, longitude } = coords;
    loadSmartNotifications({
      lat: latitude,
      lng: longitude,
      history,
      segments,
    });
  };

  const loadSegments = async () => {
    try {
      if(!user?.phone) return []
      const { data } = await SegmentsServices.getAll({
        phone: `${user?.country?.areaCode}${user?.phone}`,
      });
      if(Capacitor.getPlatform() !== 'web') {
        saveTopics({ topics: data.topics })
      }
      if(Capacitor.getPlatform() !== 'web' && notification.isActive) {
        getNotifications(data.segments);
      }
      return data;
    } catch (error) {
      Promise.reject(error);
    }
  };

  return {
    loadSegments,
  };
};
