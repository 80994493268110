import { IonSkeletonText } from '@ionic/react';
import './CategoryItemSkeleton.scss';

const CategoryItemSkeleton = () => {
    return (
        <div id="category_item_skeleton">
            <div className="category_item_skeleton_text">
                <IonSkeletonText animated className="category_item_skeleton_title"/>
                <IonSkeletonText animated className="category_item_skeleton_subtitle"/> 
            </div>
            <IonSkeletonText animated className="category_item_skeleton_image"/> 
        </div>
    )
}

export default CategoryItemSkeleton
