import { useState, useEffect, useRef, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";
import './Segments.scss';
export const Segments = ({ value, onChange, items, class: className }) => {
  const toggleRef = useRef(null);
  const pRef = useRef(null);
  const [percent, setPercent] = useState(0);
  const { width } = useResizeDetector({ targetRef: toggleRef });
  const {
    width: widthItem,
    height,
    ref,
  } = useResizeDetector({
    targetRef: pRef,
  });
  const [index, setIndex] = useState(
    value !== "" ? items?.findIndex?.((v) => v.value === value) ?? 0 : 0
  );

  useEffect(() => {
    setPercent((widthItem || 0) * index);
  }, [index, widthItem]);

  useEffect(() => {
    if (value === "") return;
    setIndex(items?.findIndex?.((v) => v.value === value) ?? 0);
    setPercent((widthItem || 0) * index);
  }, [value]);

  function translateItem(i, item) {
    if (i === index) return;
    setPercent((widthItem || 0) * index);
    setIndex(i);
    onChange?.(item?.value);
  }

  return (
    <div ref={toggleRef} className={`claro-segments ${className}`}>
      <div className="container">
        <div
          style={{
            transform: `translateX(${percent}px)`,
            width: `${widthItem}px`
          }}
          className="element"
        ></div>
        <div className="segment">
          {items?.map((item, i) => (
            <p
              key={item.label}
              id={String(i)}
              ref={pRef}
              className={`segment-button ${
                index === i ? "active" : ""
              } font-bold text-sm h-full flex justify-center rounded-full items-center font-raleway w-full text-center tab`}
              aria-label="tab"
              onClick={(e) => translateItem(i, item)}
            >
              {item?.label}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Segments;
