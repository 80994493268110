import { useEffect, useState } from "react";
import { ViewStates } from "../const/viewStates";
import { SectionService } from "../services/SectionService";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useSection = () => {
    const [state, setState] = useState(initialState);
    
    const loadSection = async(id) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });            
            const {data} = await SectionService.getOne(id);

            setState({
                data,
                viewState: data ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state,
        loadSection
    }
}
