import { useState } from 'react';
import { countries } from '../const/countries';
import { ViewStates } from '../const/viewStates';
import { AuthService } from '../services/authService';
import { logout, updateUser } from '../store/AuthStore';

const initialState = {
  viewState: ViewStates.INITIAL,
};

export const useAuth = () => {
  const [state, setState] = useState(initialState);
  const handleLogin = async (credentials) => {
    let response;
    try {
      setState({
        viewState: ViewStates.LOADING,
      });

      response = await AuthService.login(credentials);
      if (response.status === 200) {
        const session = {
          ...response.data.user,
          token: response.data.token,
          country: countries[response.data.user.country.id - 1],
        };
        updateUser(session);
        localStorage.setItem('session_mobile', JSON.stringify(session));
      }
      setState({
        viewState: ViewStates.SUCCESS,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleRegister = async (params) => {
    try {
      setState({
        viewState: ViewStates.LOADING,
      });

      const response = await AuthService.register(params);
      setState({
        viewState: ViewStates.SUCCESS,
      });
    } catch (error) {
      setState({
        viewState: ViewStates.ERROR,
      });
    }
  };

  const handleLogout = () => {
    logout();
    localStorage.clear();
  };

  return {
    handleLogin,
    handleRegister,
    handleLogout,
    state,
  };
};
