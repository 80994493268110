import Lottie from "react-lottie-player";
import animation from "./error.json";
import animationDark from "./error-white.json";

export default function ErrorLottie(props) {
  const { dark, ...rest } = props;

  return (
    <Lottie animationData={!dark ? animation : animationDark} {...rest} />
  );
}
