import { IonIcon } from "@ionic/react";
import { cloudOfflineOutline, fileTrayOutline } from "ionicons/icons";
import { ViewStates } from "../../../const/viewStates";
import "./Placeholder.scss";

const Placeholder = ({ state, icon, title, description, src, style}) => {
  return (
    <div id="placeholder" style={style}>
      {state === ViewStates.ERROR && !icon && (
        <img src="../assets/icon/error.png" alt="" />
      )}
      {state === ViewStates.EMPTY && !icon && (
        <IonIcon color="primary" icon={fileTrayOutline} />
      )}
      {icon && <IonIcon color="primary" icon={icon} />}
      {src && <IonIcon src={src} />}
      {title && <h3>{title}</h3>}
      <p>
        {state === ViewStates.ERROR &&
          !description &&
          "Oops! ha ocurrido un error, por favor intenta más tarde"}
        {state === ViewStates.EMPTY &&
          !description &&
          "No se ha encontrado información"}
        {description && description}
      </p>
    </div>
  );
};

export default Placeholder;
