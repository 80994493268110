
import { useEffect, useState } from "react";
import { ViewStates } from "../const/viewStates"
import { SmartNotificationsServices } from "../services/SmartNotificationsService"
import notifications from '../classes/localNotifications'

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL
}
export const useSmartNotification = () => {
    const [notification, setNotification] = useState({})
    const [state, setState] = useState(initialState)

    const loadSmartNotifications = async ({ history, ...input }) => {
        try {
            setState({ viewState: ViewStates.LOADING })
            const { data } = await SmartNotificationsServices.getSmartNotifications({ ...input })
            data.length && notifications.schedule(history, data[0])
            setState({ data, viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY })
        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            })
        }
    }

    return {
        notification,
        loadSmartNotifications
    }
}