import { IonContent, IonPage, useIonViewWillEnter } from "@ionic/react"
import { exit, logoFacebook } from "ionicons/icons"
import { useState } from "react"
import { useHistory } from "react-router"
import Header from "../../components/common/header/Header"
import ItemDetailIcon from "../../components/common/item-detail-icon/ItemDetailIcon"
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer"
import Spacer from "../../components/common/spacer/Spacer"
import { useAuth } from "../../hooks/useAuth"
import { useToast } from "../../hooks/useToast"
import { ClientService } from "../../services/ClientService"
import AuthStore, { getProfileFacebook, updateUser } from "../../store/AuthStore"
import ProfileHeader from "./components/profile-header/ProfileHeader"
import ProfileItem from "./components/profile-item/ProfileItem"
import { profileOptions } from "./profileOptions"
import { Capacitor } from "@capacitor/core"
import { FacebookLogin } from "@capacitor-community/facebook-login"

const ProfileScreen = () => {
  const { presentToast } = useToast();

  const user = AuthStore.useState((s) => s.user);
  const [disabledFacebookButton, setDisabledFacebookButton] = useState(
    localStorage.getItem("FACEBOOK_TOKEN") ? true : false
  );
  const { handleLogout } = useAuth();
  const history = useHistory();

  useIonViewWillEnter(() => {
    user.role === "guest" && history.replace("/login");
  });

  const logout = () => {
    handleLogout();
    history.replace("/login");
    window.location.reload();
  };

  const signInFacebook = async () => {
    try {
      const profile = await getProfileFacebook();
      if (profile && profile.id) {
        let response = await ClientService.update({
          id: user.id,
          facebookId: profile.id,
        });
        if (response.status == 200) {
          let session = JSON.parse(JSON.stringify(user));
          session.facebookId = true;
          updateUser(session);
          localStorage.setItem("session_mobile", JSON.stringify(session));
          presentToast(
            "Se ha vinculado tu cuenta de facebook!",
            5000,
            "success"
          );
          setDisabledFacebookButton(true);
        }
      }
    } catch (error) {
      if (error?.status == 401) {
        localStorage.removeItem("FACEBOOK_TOKEN");
        setDisabledFacebookButton(false);
      }
    }
  };

  return (
    <IonPage>
      <Header />
      <IonContent>
        <ResponsiveContainer>
          <ProfileHeader {...user} />
          {profileOptions.map((opt) => (
            <ProfileItem key={opt.url} {...opt} />
          ))}
          {Capacitor.getPlatform() === "android" && (
            <ItemDetailIcon
              onClick={signInFacebook}
              title="Vincular con Facebook"
              icon={logoFacebook}
              color="facebook"
              disabled={disabledFacebookButton}
            />
          )}
          <ItemDetailIcon
            onClick={logout}
            title="Cerrar sesión"
            icon={exit}
            color="danger"
          />
          <Spacer height={56} />
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};

export default ProfileScreen;
