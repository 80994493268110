import { useEffect, useState } from "react";
import { ViewStates } from "../const/viewStates";
import { StoresService } from "../services/StoresService";
import AuthStore from "../store/AuthStore";
import { useSegments } from "./useSegments";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useStores = () => {
    const user = AuthStore.useState(s => s.user);
    const [state, setState] = useState(initialState);
    const [bounds, setBounds] = useState(null)
    const { loadSegments } = useSegments()

    const loadStores = async () => {
        if (user.role === 'guest') {
            bounds && loadStoresByLocation({ countryId: user.country.id, ...bounds });
        } else {
            const { segments } = await loadSegments() ?? {segments: []}
           bounds && loadStoresByLocation({ countryId: user.country.id, segments, ...bounds });
        }
    }
    useEffect( () => {
        loadStores()
    }, [bounds])


    const loadStoresByLocation = async (input) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });

            const { data } = await StoresService.getAllByLocation(input);

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return {
        state,
        setBounds,
        bounds
    }
}
