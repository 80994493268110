import { IonContent, IonPage } from "@ionic/react"
import Header from "../../components/common/header/Header"
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer"
import SearchInput from "../../components/common/search-input/SearchInput"
import CouponsList from "../../components/coupons-list/CouponList"

const EventsScreen = () => {

    return (
        <IonPage>
            <Header />
        <IonContent>
            <ResponsiveContainer>
                <SearchInput />
                <CouponsList type="events"/>
            </ResponsiveContainer>
        </IonContent>
    </IonPage>
    )
}

export default EventsScreen
