import { useState } from "react"
import { ViewStates } from "../const/viewStates"
import { CouponsService } from "../services/CouponsService";
import AuthStore from "../store/AuthStore";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
    status: null
}

export const useCoupon = () => {
    const [state, setState] = useState(initialState);
    const user = AuthStore.useState(s => s.user);

    const redeem = async (benefitId) => {
        try {
            setState({
                viewState: ViewStates.LOADING
            });

            const response = await CouponsService.redeem({ countryId: user.country.id, benefitId, recipientPhone: null });
            if (response.status === 200) {
                setState({
                    data: response.data,
                    viewState: ViewStates.SUCCESS,
                    status: response.status
                });
                return {
                    data: response.data,
                    status: response.status,
                    error: null
                }
            }

            if (response.status === 423 || response.status === 404) {
                setState({
                    data: response.data,
                    viewState: ViewStates.ERROR,
                    status: response.status
                });
                return {
                    data: response.data,
                    status: response.status,
                    error: null,
                }
            }

        } catch (error) {
            setState({
                data: error,
                viewState: ViewStates.ERROR,
                status: error?.response?.status
            });
            return {
                data: error,
                status: error?.response?.status,
                error,
            }
        }
    }



    return {
        state,
        redeem
    }
}