import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import './ProfileItem.scss';

const ProfileItem = ({icon, title, url}) => {
    return (
    <IonItem id="profile_item" button detail={true} routerLink={url}>
        <IonIcon icon={icon} slot="start" color="dark"/>
        <IonLabel>{title}</IonLabel>
    </IonItem>
    )
}

export default ProfileItem
