import { apiCall } from "../config/apiConfig"
import { environment } from "../const/environment"


export const ClientService = {
    update: ({id, ...rest}) => {
        return apiCall.put(`${environment.API_URL}/clients/${id}`, {...rest});
    },
    getDiscount: () => {
        return apiCall.get(`${environment.API_URL}/clients/discounts`, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: null
        });
    }
}

