import { IonButton, IonContent, IonPage } from "@ionic/react"
import { useHistory } from "react-router";
import Header from "../../components/common/header/Header"
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import Spacer from "../../components/common/spacer/Spacer";
import { countries } from "../../const/countries"
import { useCountry } from "../../hooks/useCountry";
import { useTheme } from "../../hooks/useTheme";
import { updateUser } from "../../store/AuthStore";
import CountryItemCheckbox from "./components/country-item-checkbox/CountryItemCheckbox"
import './CountryScreen.scss';

const CountryScreen = () => {
    
    const history = useHistory();
    const {state} = useTheme();

    const {country, setCountryByCode} = useCountry();
    
    const goToAuthScreen = () => {
        updateUser({country});
        history.push(`/login/auth${history.location.search}`);
    }

    return (
        <IonPage id="country_screen">
            <Header backButton defaultHref="/login" logo={false} className="transparent"/>
            <IonContent className="claro-club-content">
                <ResponsiveContainer>
            <div className="country_screen_header">
                <img src={state.dark ? 'assets/img/logo-claro-club-white.png' : 'assets/img/logo-claro-club.png'} width="150px" />
                <p>País en el que te encuentras</p>
            </div>

                {countries.map(c => (
                    <CountryItemCheckbox key={c.id} {...c} ipCode={country?.code} onChange={setCountryByCode}/>
                ))}

                <Spacer height={28} />
                <IonButton disabled={!country} onClick={goToAuthScreen} shape="round" expand="full" className="claro-club-button" mode="md" color="primary">
                    Confirmar
                </IonButton>
                </ResponsiveContainer>
                <Spacer height={56} />
                
            </IonContent>
            
        </IonPage>
    )
}

export default CountryScreen
