import { useState } from "react";
import AuthStore from "../store/AuthStore";
import {BranchesService} from '../services/BranchesService';
import { ViewStates } from "../const/viewStates";

const initialState = {
    data: [],
    viewState: ViewStates.INITIAL,
}

export const useBranches = () => {
    const [state, setState] = useState(initialState);
    const user = AuthStore.useState(s => s.user);

    const loadStates = async() => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });            

            const {data} = await BranchesService.getAllStates(user.country.id);

            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    const loadBranchesByState = async(stateId) => {
        try {

            setState({
                viewState: ViewStates.LOADING,
            });            

            const {data} = await BranchesService.getByState(stateId);
            
            setState({
                data,
                viewState: data.length ? ViewStates.CONTENT : ViewStates.EMPTY
            });

        } catch (error) {
            setState({
                viewState: ViewStates.ERROR,
            });
        }
    }

    return{
        state,
        loadStates,
        loadBranchesByState
    }
}