import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import { checkmarkCircle, ellipseOutline } from 'ionicons/icons';
import './CountryItemCheckbox.scss';

const CountryItemCheckbox = ({icon, name, areaCode, code, onChange, ipCode}) => {
  

    return (
        <IonItem button id="country_item_checkbox" lines="none" detail={false} className={ipCode === code && 'selected'} onClick={e => onChange(code)} >
            <IonIcon src={icon} slot="start"/>
            <IonLabel><h2><b>(+{areaCode})</b> {name}</h2> </IonLabel>
            {
                ipCode === code 
                ? <IonIcon slot="end" icon={checkmarkCircle} color="primary"/>
                : <IonIcon slot="end" icon={ellipseOutline}/>
            }
            
        </IonItem>
    )
}

export default CountryItemCheckbox
