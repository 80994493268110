import { IonContent, IonModal } from "@ionic/react";
import "./VersionModal.scss";
import Lottie from "../../lottie/Lottie";
import RocketLottie from "../../../assets/lottie/rocket-space.json";
import Button from "../../common/button/Button";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

const VersionModal = ({ showModal, setShowModal, version }) => {
  const onClose = () => {
    setShowModal(false);
  };

  const platform = Capacitor.getPlatform();
  const { required, message, title } = version?.[platform] || {};

  return (
    <IonModal
      mode="md"
      cssClass="version_modal"
      isOpen={showModal}
      canDismiss={required ? false : true}
      onDidDismiss={() => {
        if (!required) onClose();
      }}
    >
      <IonContent>
        <div className="container">
          <Lottie
            animationData={RocketLottie}
            play
            loop
            style={{ width: "150px", height: "150px" }}
          />
          <h1>{title ? title : "Nueva actualización disponible"}</h1>
          <p>{message}</p>
          <Button
            label="Actualizar ahora"
            onClick={() => {
              return Browser.open({
                url:
                  platform === "android"
                    ? "https://play.google.com/store/apps/details?id=claro.club&hl=es_GT&gl=US"
                    : "https://apps.apple.com/gt/app/claro-club-centroam%C3%A9rica/id899727991",
              });
            }}
          />
          {!required && (
            <Button
              fill="outline"
              label="Actualizar más tarde"
              onClick={onClose}
            />
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default VersionModal;
