import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const CouponsService = {
    getAll: (page = 1, countryId = 1, segments = [], clientId) => {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/benefits?page=${page}${segments.length ? '&segment=' + segments.join() : ''}${clientId ? '&clientId=' + clientId : ''}`);
    },
    getOne: (id) => {
        return apiCall.get(`${environment.API_PUBLIC}/benefits/${id}`);
    },
    getAllByStore: (partnerId, segments = [], clientId) => {
        // ${this.segments}
        return apiCall.get(`${environment.API_PUBLIC}/partners/${partnerId}/benefits${segments.length ? '?segment=' + segments.join() : ''}${segments.length ? '&' : '?'}${clientId ? '&clientId=' + clientId : ''}`);
    },
    getAllEvents: (page = 1, countryId = 1, segments = [], clientId) => {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/benefits?type=2&page=${page}${segments.length ? '&segment=' + segments.join() : ''}${clientId ? '&clientId=' + clientId : ''}`);
    },
    getAllByClient: (clientId, active, segments = []) => {
        return apiCall.get(`${environment.API_URL}/clients/${clientId}/coupons?active=${active}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: null
        });
    },
    redeem: (input) => {
        return apiCall.post(`${environment.API_URL}/coupons`, input);
    },
    getAllByBounds: ({ countryId, blLat, blLng, trLat, trLng, segments = [], clientId }) => {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/benefits?type=1,3,4&blLat=${blLat}&blLng=${blLng}&trLat=${trLat}&trLng=${trLng}&sort=partnerId,desc${segments.length ? '&segment=' + segments.join() : ''}${clientId ? '&clientId=' + clientId : ''}`);
    },
    save({ benefitId, clientId, isFavorite }) {
        return apiCall.post(`${environment.API_URL}/client-saved-benefits`, { benefitId, clientId, isFavorite });
    },
    cav() {
        return apiCall.get(`${environment.API_URL}/benefits/cav`, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: null
        });
    },
    redemptions({ category, stage, benefit, benefitId }) {
        return apiCall.post(`${environment.API_URL}/redemptions`, { category, stage, benefit, benefitId });
    },
    getRedemptions() {
        return apiCall.get(`${environment.API_URL}/redemptions`, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: null

        });
    },
    gift({ countryId, benefitId, recipientPhone }) {
        return apiCall.post(`${environment.API_URL}/coupons/gift`, { countryId, benefitId, recipientPhone });
    },
    promotions({ countryId, token = "" }) {
        const  session = JSON.parse(localStorage.getItem('session_mobile')) || { token };

        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/promotions`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session?.token}`
            },
            data: null
        });
    }
}

export {
    CouponsService
}