import { IonCol, IonGrid, IonRow } from '@ionic/react';
const ResponsiveContainer = ({children, className=""}) => {
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="12" size-md="8" offset-md="2" size-lg="8" offset-lg="2" size-xl="8" offset-xl="2">
                    <IonRow className={className}>
                        {children}
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default ResponsiveContainer
