import { IonButton } from "@ionic/react";
import Button from "../common/button/Button";
import "./Banner.scss";
import { useHistory } from "react-router";
import { useAnalytics } from "../../hooks/useAnalytics";
import { AdvancedConfigStore } from "../../store/AdvancedConfigStore";
import { CategoriesStore } from "../../store/CategoriesStores";
import AuthStore from "../../store/AuthStore";

export const Banner = () => {
  const history = useHistory();
  const { config } = AdvancedConfigStore.useState((s) => s);
  const { isShow } = CategoriesStore.useState((s) => s);
  const user = AuthStore.useState((s) => s.user);
  const style = {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1613290907219-ee677464bd45?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)",
  };
  const { logEvent } = useAnalytics();

  const goToBenefits = () => {
    logEvent("CTA_Banner_CAV");
    history.push(user?.role === "guest" ? "/login" : "/benefits");
  };
  return user?.id && isShow ? (
    <div className="banner" style={style}>
      <h3>{config?.home_cav_banner_title}</h3>
      <IonButton
        onClick={goToBenefits}
        shape="round"
        className="know-benefits-button"
      >
        {config?.home_cav_banner_button_title}
      </IonButton>
    </div>
  ) : null;
};
