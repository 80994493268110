import { useEffect } from "react";
import { ViewStates } from "../../../../../../const/viewStates";
import { useBranches } from "../../../../../../hooks/useBranches"
import Placeholder from '../../../../../../components/common/placeholder/Placeholder';
import BranchItem from "../branch-item/BranchItem";
import Loading from "../../../../../../components/common/loading/Loading";
import './BranchList.scss';
const BranchList = ({stateId}) => {

    const {state, loadBranchesByState} = useBranches();

    useEffect(() => {
        if(stateId) loadBranchesByState(stateId);
    }, [stateId])

    return (
        <>
        {
             (state.viewState === ViewStates.INITIAL) &&
             <Placeholder state={ViewStates.EMPTY}/>

        }
        {
            (state.viewState === ViewStates.EMPTY || state.viewState === ViewStates.ERROR) &&
            <Placeholder state={state.viewState}/>
        }
    
        {
            state.viewState === ViewStates.LOADING &&
            <div className="branch-loading">
                <Loading />
            </div>
        }
    
        {
            state.viewState === ViewStates.CONTENT &&
            state.data.map(branch => (
               <BranchItem key={branch.id} {...branch}  />
               ))
        }     
        </>
    )
}

export default BranchList
