import { IonSearchbar } from '@ionic/react';
import { useState } from 'react';
import './SearchInput.scss';

const SearchInput = ({name="" ,placeholder = "Buscar...", onIonChange = null, onIonFocus = null, searchText = ""}) => {
    return (
        <IonSearchbar name={name} id="search_input" placeholder={placeholder} mode="ios" value={searchText} onIonFocus={onIonFocus} onIonInput={onIonChange}></IonSearchbar>
    )
}

export default SearchInput;
