import {
  IonCol,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import { useHistory, useParams } from "react-router";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import { ViewStates } from "../../const/viewStates";
import { useCoupons } from "../../hooks/useCoupons";
import AuthStore from "../../store/AuthStore";
import Spacer from "../../components/common/spacer/Spacer";
import { useAnalytics } from "../../hooks/useAnalytics";
import CouponCoverImage from "../../components/coupon-cover-image/CouponCoverImage";
import CouponScreenSkeleton from "../../components/coupon-screen-skeleton/CouponScreenSkeleton";
import "./GiftScreen.scss";
import { useState } from "react";
import Button from "../../components/common/button/Button";
import GiftModal from "../../components/modals/gift-modal/GiftModal";
import { CouponsService } from "../../services/CouponsService";
import { useToast } from "../../hooks/useToast";
import useAlert from "../../hooks/useAlert";

const GiftScreen = () => {
  const history = useHistory();
  const { id } = useParams();
  const [phone, setPhone] = useState(null);
  const [showGiftModal, setShowGiftModal] = useState(false);
  const { state, loadCoupon } = useCoupons();
  const user = AuthStore.useState((s) => s.user);
  const { logEvent } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { presentToast } = useToast();
  const { showAlert } = useAlert();

  useIonViewDidEnter(() => {
    setPhone(null);
    loadCoupon(id);
    logEvent("view_item", { item_id: id });
  }, []);

  const handleGenerateCode = async () => {
    try {
      setLoading(true);
      setShowGiftModal(false);
      if (phone && phone?.length < 8)
        return presentToast(
          "El número de teléfono debe tener 8 dígitos",
          4000,
          "warning"
        );
      const resp = await CouponsService.gift({
        benefitId: id,
        countryId: user.country.id,
        recipientPhone: phone,
      });
      if (resp.status === 423 || resp.status === 404) {
        return showAlert({
          header: "Claro Club",
          subHeader: "Información del beneficio",
          message:
            resp.status === 423
              ? "Este beneficio ha llegado a su limite de canjes, espera pronto para más promociones."
              : resp.status === 404
              ? "El beneficio no existe."
              : "",
          buttons: ["Entendido"],
        });
      }
      if (resp.status !== 200) {
        setResult(null);
        return presentToast(
          "Oops! ha ocurrido un error, intentalo nuevamente",
          4000,
          "danger"
        );
      }
      logEvent("Click_generar_cupon", { name: state.data?.title });
      setResult(resp.data);
      setShowGiftModal(true);
      setLoading(false);
    } catch (error) {
      setResult(null);
      if (error?.response?.status === 423 || error?.response?.status === 404) {
        return showAlert({
          header: "Claro Club",
          subHeader: "Información del beneficio",
          message:
            error?.response?.status === 423
              ? "Este beneficio ha llegado a su límite de canjes, espera pronto para más promociones."
              : error?.response?.status === 404
              ? "El beneficio no existe."
              : "",
          buttons: ["Entendido"],
        });
      }else {
        return presentToast(
          "Oops! ha ocurrido un error, intentalo nuevamente",
          4000,
          "danger"
        );
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <IonPage className="gift-screen" mode="ios">
      <IonContent fullscreen>
        <ResponsiveContainer>
          {state.viewState === ViewStates.EMPTY && <h3>EMPTY</h3>}

          {state.viewState === ViewStates.ERROR && <h3>Network error</h3>}

          {state.viewState === ViewStates.LOADING && <CouponScreenSkeleton />}

          {state.viewState === ViewStates.CONTENT && (
            <>
              <IonCol size="12" size-xl="6">
                <CouponCoverImage image={state.data.picture} />
              </IonCol>
              <IonCol size="12" size-xl="6">
                <div className="gift-content">
                  <h3>¡Regala este cupón a uno de tus familiares o amigos!</h3>
                  <p>
                    Indícanos el número de teléfono de la persona a la que le
                    vas a regalar este cupón.
                  </p>

                  <IonItem
                    mode="md"
                    lines="none"
                    className="claro-club-input-outline"
                  >
                    <IonLabel mode="ios" position="stacked">
                      Ingresa número móvil
                    </IonLabel>
                    <IonInput
                      name="phone"
                      value={phone}
                      type="tel"
                      maxlength={8}
                      placeholder="Ingresa número móvil de 8 dígitos"
                      onIonInput={(e) => {
                        setPhone(e?.target?.value);
                      }}
                      autocomplete="off"
                      required
                    />
                  </IonItem>
                </div>
              </IonCol>
            </>
          )}
        </ResponsiveContainer>
        <Spacer height={32} />
      </IonContent>
      <IonFooter className="gift-footer">
        <Button
          label="Cancelar"
          color="cancel"
          onClick={(e) => {
            setPhone(null);
            history.goBack();
          }}
        />
        <Button
          label="Generar cupón"
          disabled={!phone}
          loading={loading}
          onClick={handleGenerateCode}
        />
      </IonFooter>
      <GiftModal
        coupon={state?.data}
        user={user}
        showModal={showGiftModal}
        setShowModal={setShowGiftModal}
        company={result?.company?.toLowerCase() || ""}
        code={result?.code}
        phone={result?.recipientPhone}
        countryId={result?.countryId}
        logEvent={logEvent}
        id={id}
      />
    </IonPage>
  );
};

export default GiftScreen;
