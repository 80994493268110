import React from 'react'
import { IonIcon, IonToggle } from '@ionic/react'
import { moonOutline, notificationsOffOutline, notificationsOutline, sunnyOutline } from 'ionicons/icons'
import './ToggleNotification.scss'
import NotificationStore, { updateNotification } from '../../../../../../../store/NotificationStore'

export const ToggleNotification = () => {
    const notification = NotificationStore.useState(s => s.notification.isActive);

    return (
        <div id="toggle_notification">
            <section>
                <IonIcon icon={notificationsOffOutline} />
                <span>Deshabilitar</span>
            </section>

            <IonToggle mode="ios" checked={notification} onClick={e => updateNotification(e?.target?.checked)} />

            <section>
                <IonIcon icon={notificationsOutline} />
                <span>Habilitar</span>
            </section>

        </div>
    )
}
