import { IonButton, IonContent, IonIcon, IonModal } from "@ionic/react";
import { close, headset } from "ionicons/icons";
import "./BenefitModal.scss";
import Button from "../../common/button/Button";
import { useHistory } from "react-router";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Mousewheel,
} from "swiper/modules";
import { useAnalytics } from "../../../hooks/useAnalytics";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Lottie from "../../lottie/Lottie";
import GiftLottie from "../../../assets/lottie/wired-lineal-412-gift.json";

const BenefitModal = ({ promotions, showModal, setShowModal }) => {
  const history = useHistory();
  const [spaceBetween, setSpaceBetween] = useState(0);
  const [showSlides, setShowSlides] = useState(false);
  const { logEvent } = useAnalytics();
  const swiperProperties = {
    slidesPerView: 1,
    pagination: { clickable: true },
  };
  const onClose = () => {
    setShowModal(false);
  };
  const handleAction = (promotion) => {
    logEvent("CTA_POP", { name: promotion?.name, id: promotion?.benefitId });
    history.push(`/coupon/${promotion?.benefitId}`);
    setShowModal(false);
  };

  return (
    <IonModal
      mode="md"
      cssClass="benefit_modal"
      isOpen={showModal}
      onDidDismiss={onClose}
      onWillPresent={(e) => {}}
    >
      <IonContent fullscreen>
        <IonButton
          className="close-button"
          color="cancel"
          shape="round"
          onClick={onClose}
          mode="md"
          size="small"
        >
          <IonIcon slot="icon-only" icon={close} />
        </IonButton>
        <div
          className={`benefit-content ${
            promotions?.length > 1? "has-pagination" : ""
          }`}
        >
          <Swiper
            modules={[Pagination]}
            spaceBetween={spaceBetween}
            {...swiperProperties}
            className="slides-benefits"
            pagination={{ clickable: true, el: ".swiper-pagination" }}
          >
            {promotions?.map((promotion) => (
              <SwiperSlide key={promotion.id}>
                <div className="benefit-card">
                  <img
                    src={
                      promotion?.picture ||
                      "assets/img/placeholder-claro-club.png"
                    }
                    alt="benefit"
                  />
                  <div className="content">
                    <Lottie
                      animationData={GiftLottie}
                      loop
                      play
                      style={{
                        width: "60px",
                        height: "60px",
                      }}
                    />
                    <h3>{promotion?.title}</h3>
                    <p>{promotion?.description}</p>
                    {promotion.type === 1 ? (
                      <Button
                        label={promotion.button}
                        onClick={(e) => {
                          handleAction(promotion);
                        }}
                      />
                    ) : promotion?.url?.indexOf("http") > -1 ? (
                      <a
                        onClick={(e) => {
                          setShowModal(false);
                        }}
                        href={encodeURI(promotion?.url)}
                        target="_blank"
                      >
                        <span>{promotion?.button}</span>
                      </a>
                    ) : (
                      <Button
                        label={promotion.button}
                        onClick={(e) => {
                          console.log("promotion", promotion.url);
                          setShowModal(false);
                          history.push(promotion?.url);
                        }}
                      />
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {promotions?.length > 1 && <div className="swiper-pagination"></div>}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default BenefitModal;
