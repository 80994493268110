import "./Stages.scss";

export const Stages = ({ stages, selectedStage, setSelectedStage, config }) => {
  const handleSelect = (step) => {
    setSelectedStage(step);
  };
  return (
    <ul className="steps-tabs">
      {stages?.map((stage, index) => (
        <li
          key={stage?.name}
          className={`${stage.in ? "active" : ""}  ${
            selectedStage?.name === stage?.name ? "selected" : ""
          }`}
          onClick={(e) => {
            handleSelect({ ...stage, index });
          }}
        >
          <span> {stage?.range}</span>
        </li>
      ))}
    </ul>
  );
};
