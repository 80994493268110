import { IonContent, IonPage } from '@ionic/react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import Header from '../../components/common/header/Header'
import ResponsiveContainer from '../../components/common/responsive-container/ResponsiveContainer'
import { ViewStates } from '../../const/viewStates';
import { useSection } from '../../hooks/useSection';

const SectionScreen = () => {

    const { id } = useParams();
    const {state, loadSection} = useSection();
    
    useEffect(() => {
        id !== undefined && loadSection(id);
    }, [id])
    return (
        <IonPage>
            <Header backButton={true} defaultHref="/featured"/>
          <IonContent>
              <ResponsiveContainer className="claro-club-content">
                  {
                      state.viewState === ViewStates.CONTENT && 
                      <>
                        <h3>{state.data.title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: state.data.text }} />
                      </>
                  }
              </ResponsiveContainer>
        </IonContent>  
        </IonPage>
    )
}

export default SectionScreen
