import { apiCall } from "../config/apiConfig"
import { environment } from "../const/environment"

export const SectionService = {
    getAll: (countryId) => {
    return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/sections`);
    },
    getOne: (id) => {
        return apiCall.get(`${environment.API_PUBLIC}/sections/${id}`);
    }
}