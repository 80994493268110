import { useEffect, useState } from "react"
import { countries } from "../const/countries";

export const useCountry = () => {

    const [country, setCountry] = useState(null);

    const setCountryByCode = (code) => {
        let country = countries.find(c => c.code === code);
        setCountry(country);
    }

    const setCountryById = (id) => {
        let country = countries.find(c => c.id === id);
        setCountry(country);
    }

    const setCountryByHost = () => {
        let host = window.location.hostname;
        let code = host.substr(host.lastIndexOf(".")+1).toUpperCase();
        const country = countries.find(c => c.code === code);
        country ?  setCountry(country) :  setCountry(countries[0]);
    }

    const getCountry = async () => {
        try {
            fetch('https://ip-info-api.vercel.app/api')
            .then(response => response.json())
            .then(response => {
                let country = countries.find(c => c.name === response.country);
                setCountry(country);
            }).catch(error => {
                setCountry(countries[0]);
            });
        } catch (error) {
            setCountry(countries[0]);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])


    return {
        country,
        setCountryByCode,
        setCountryById
    }
}