import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const CategoriesService = {
    getAll: (countryId = 1, segments = []) => {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/categories`);
    },

    getAllByPartner: ({ categoryId, page = 1, segments = [] }) => {
        return apiCall.get(`${environment.API_PUBLIC}/categories/${categoryId}/partners?page=${page}${segments.length ? '&segment=' + segments.join() : ''}`);
    },

    getAllSubcategories: ({ categoryId, page = 1, segments = [] }) => {
        return apiCall.get(`${environment.API_PUBLIC}/categories/${categoryId}/subcategories?page=${page}${segments.length ? '&segment=' + segments.join() : ''}`);
    },

    getPartnerBySubcategories: ({ categoryId, page = 1, segments = [] }) => {
        return apiCall.get(`${environment.API_PUBLIC}/subcategories/${categoryId}/partners?page=${page}${segments.length ? '&segment=' + segments.join() : ''}`);
    }
}

export {
    CategoriesService
}