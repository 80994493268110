import { IonContent, IonPage } from "@ionic/react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Header from "../../components/common/header/Header";
import ResponsiveContainer from "../../components/common/responsive-container/ResponsiveContainer";
import NavigationStore, { updateNavigate } from "../../store/NavigationStore";
import Facebook from "./components/facebook/Facebook";
import OtherMethod from "./components/other-method/OtherMethod";
import PhoneMethod from "./components/phone-method/PhoneMethod";
import { Capacitor } from "@capacitor/core";

const AuthenticationScreen = () => {
  const location = useLocation();
  const isClosed = NavigationStore.useState((s) => s.isClosed);
  const onClick = NavigationStore.useState((s) => s.onClick);
  const [phone, setPhone] = useState("");
  const value = queryString.parse(location.search);
  const content = {
    phone: <PhoneMethod phone={phone} setPhone={setPhone} />,
    other: <OtherMethod />,
  };

  useEffect(() => {
    updateNavigate({ isClosed: false, onClick: () => {} });
  }, []);

  return (
    <IonPage>
      <Header
        backButton={!isClosed}
        closeButton={isClosed}
        onClick={() => {
          setPhone("");
          onClick();
        }}
        defaultHref="/login"
      />
      <IonContent className="claro-club-content">
        <ResponsiveContainer>
          {content[value.method || "phone"]}
          {phone == "" && Capacitor.getPlatform() === "android" ? (
            <Facebook />
          ) : null}
        </ResponsiveContainer>
      </IonContent>
    </IonPage>
  );
};

export default AuthenticationScreen;
