import { IonApp } from "@ionic/react";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { configAnalytics } from "./config/analytics";
import "./theme/global.scss";
import ClaroClubApp from "./navigation/ClaroClubApp";
import { SplashScreen as SplashInstance } from "@capacitor/splash-screen";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import SplashScreen from "./navigation/splash/SplashScreen";


const App = () => {
  useEffect(() => {
    if(Capacitor.getPlatform() === "web") {
      FirebaseAnalytics.initializeFirebase(configAnalytics);
    }
    SplashInstance.hide({
      fadeOutDuration: 0,
    });
  }, []);

  return (
    <IonApp>
      <ClaroClubApp />
    </IonApp>
  );
};

export default App;
