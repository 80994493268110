import { IonButton, IonIcon } from '@ionic/react';
import { callOutline, navigateOutline } from 'ionicons/icons';
import './StoreListItem.scss';

const StoreListItem = ({title, address, lat, lng, phone}) => {

    const navigateTo = () => {
      window.open(`https://www.google.com/maps/?q=${lat},${lng}` , '_blank');
    }

    return (
        <div id="store_list_item">
            <h3>{title}</h3>
            <p>{address}</p>
            <div className="store_list_item_buttons">
                <IonButton fill="clear" href={`tel:${phone}`}><IonIcon icon={callOutline} slot="start" />Llamar</IonButton>
                <IonButton fill="clear" onClick={() => navigateTo()}><IonIcon icon={navigateOutline} slot="start" />¿Como llegar?</IonButton>
            </div>
        </div>
    )
}

export default StoreListItem
