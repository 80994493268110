import { useIonAlert } from "@ionic/react";
export function useAlert() {
    const [presentAlert] = useIonAlert();
    const showAlert = async ({
        header,
        subHeader,
        message,
        class: cssClass = "claro-alert",
        buttons,
        mode,
    }) => {
        await presentAlert({
            header,
            subHeader,
            message,
            cssClass,
            buttons,
            mode,
        });
    };
    return { showAlert };
}

export default useAlert;
