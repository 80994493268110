import { IonContent, IonPage } from '@ionic/react'
import React, { useEffect } from 'react'
import Header from '../../components/common/header/Header'
import ResponsiveContainer from '../../components/common/responsive-container/ResponsiveContainer'
import { useAnalytics } from '../../hooks/useAnalytics'
import StoreCouponsList from './components/store-coupons-list/StoreCouponsList'

const StoreScreen = () => {
    const {setScreenName} = useAnalytics();
    useEffect(() => {
        setScreenName('establishment');
    }, [])
    return (
        <IonPage>
            <Header backButton={true} defaultHref="/categories"/>
          <IonContent>
              <ResponsiveContainer>
                    <StoreCouponsList />
              </ResponsiveContainer>
        </IonContent>  
        </IonPage>
    )
}

export default StoreScreen
