import { Redirect } from "react-router";

export const VerifiedOnline = ({ online, children }) => {
  
  if(!online) return <Redirect to="/404" />

  return null;
};

export default VerifiedOnline;
