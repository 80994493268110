import { Redirect, Route, useHistory } from "react-router-dom";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import "./NavigationWithTabs.scss";

/* Screens */
import CouponScreen from "../screens/coupon/CouponScreen";
import { tabs } from "./tabsAppScreens";
import StoresScreen from "../screens/stores/StoresScreen";
import StoreScreen from "../screens/store/StoreScreen";
import AuthStore from "../store/AuthStore";
import MyCouponsScreen from "../screens/profile/components/my-coupons/MyCouponsScreen";
import BranchesScreen from "../screens/profile/components/branches/BranchesScreen";
import SettingsScreen from "../screens/profile/components/settings/SettingsScreen";
import ContactUsScreen from "../screens/profile/components/contact-us/ContactUsScreen";
import SectionScreen from "../screens/section/SectionScreen";
import NearMeCouponsScreen from "../screens/near-me/NearMeCouponsScreen";
import RequestPartnerScreen from "../screens/request-partner/RequestPartnerScreen";
import BenefitsScreen from "../screens/benefits/BenefitsScreen";
import { BenefitScreen } from "../screens/benefit/BenefitScreen";
import GiftScreen from "../screens/gift/GiftScreen";
import { CategoriesStore } from "../store/CategoriesStores";
import { NotFoundScreen } from "../screens/404/NotFoundScreen";

const NavigationWithTabs = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(null);
  const [hideTabBar, setHideTabBar] = useState(false);
  const tabsRef = useRef(null);
  const user = AuthStore.useState((s) => s.user);
  const { isShow } = CategoriesStore.useState((s) => s);
  const setInitialSelectedTab = () => {
    let { activeTab } = tabsRef.current.ionTabContextState;
    setSelectedTab(activeTab || "featured");
  };

  useEffect(() => {
    setHideTabBar(!tabs.some((tab) => tab.path === history.location.pathname));
  }, [history.location.pathname]);

  useEffect(() => {
    setInitialSelectedTab();
  }, [tabsRef]);

  return (
    <IonTabs ref={tabsRef}>
      <IonRouterOutlet>
        {tabs.map((tab) => (
          <Route
            key={tab.tab}
            exact
            path={tab.path}
            component={tab.component}
          />
        ))}
        <Route exact path="/coupon/:id">
          <CouponScreen />
        </Route>
        <Route exact path="/coupon/:id/gift">
          {user.role === "guest" ? <Redirect to="/login" /> : <GiftScreen />}
        </Route>
        <Route exact path="/categories/:id">
          <StoresScreen />
        </Route>
        <Route exact path="/store/:id">
          <StoreScreen />
        </Route>
        <Route exact path="/profile/my-coupons">
          <MyCouponsScreen />
        </Route>
        <Route exact path="/profile/branches">
          <BranchesScreen />
        </Route>
        <Route exact path="/profile/settings">
          <SettingsScreen />
        </Route>
        <Route exact path="/profile/contact-us">
          <ContactUsScreen />
        </Route>
        <Route exact path="/section/:id">
          <SectionScreen />
        </Route>
        <Route exact path="/request-partner">
          <RequestPartnerScreen />
        </Route>
        <Route exact path="/benefits">
          {isShow ? <BenefitsScreen /> : <Redirect to="/featured" />}
        </Route>
        <Route exact path="/benefits/:id">
          {isShow ? <BenefitScreen /> : <Redirect to="/featured" />}
        </Route>
        <Route exact path="/nearme/coupons">
          <NearMeCouponsScreen />
        </Route>
        <Route exact path="/" render={() => <Redirect to="/featured" />} />
      </IonRouterOutlet>

      <IonTabBar
        className={hideTabBar && "hide"}
        slot="bottom"
        onIonTabsDidChange={(e) => setSelectedTab(e.detail.tab)}
      >
        {tabs.map((tab) => (
          <IonTabButton key={tab.tab} tab={tab.tab} href={tab.path}>
            <IonIcon
              icon={selectedTab === tab.tab ? tab.icon : tab.iconOutline}
            />
            <IonLabel>{tab.label}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default NavigationWithTabs;
