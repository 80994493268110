import { Store } from 'pullstate';


const NavigationStore = new Store({
    isClosed: false,
    onClick: () => {},
    page: '',
});

export const updateNavigate = (data) => {
    Object.entries(data).map(([key, value]) => {
        NavigationStore.update(s => {
            s[key] = value
        })
    })
}

export default NavigationStore;