import { ChangeEvent, useState } from 'react';
import { set } from 'lodash';

export const useForm = (initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const reset = (newFormState) => {
        setValues({ ...newFormState });
    };

    const handleInputBlur = (e, key) => {
        if (['tel', 'number'].includes(e.target.type)) {
            e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(2);
        }
        if (e && e.target) {
            const { target } = e;
            setValues({
                ...set(values, target.name, target.value),
            });
        } else {
            setValues({
                ...set(values, key, e),
            });
        }
    };

    const handleInputChange = (e, key) => {
        if (e && e.target) {
            const { target } = e;
            setValues({
                ...set(values, target.name, target.value),
            });
        } else {
            setValues({
                ...set(values, key, e),
            });
        }
        return e;
    };

    const resetProps = (formState) => {
        Object.keys(formState).map((key) => {
            if (key in formState) {
                handleInputChange(formState[key], key);
            }
        });
    };

    return [values, handleInputChange, reset, resetProps, handleInputBlur];
};
