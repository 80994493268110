import { Store } from 'pullstate';

const getThemeFromStorage = () => {
    let theme = JSON.parse(localStorage.getItem('theme'));
    return theme?.dark || false;
}

const ThemeStore = new Store({
    theme: {
        dark: getThemeFromStorage()
    }
});

export const toggleTheme = (dark) => {
    ThemeStore.update(s => {
        s.theme = {dark};
    })
}
export default ThemeStore;