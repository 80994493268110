import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react"
import { close, closeCircleOutline, closeOutline } from "ionicons/icons"
import './ModalHeader.scss';


const ModalHeader = ({ title, icon, onClick }) => {
    return (
        <IonHeader mode="ios">
            <IonToolbar mode="md">
                <IonButtons slot="start">
                    <IonButton shape="round" onClick={onClick}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonButtons>
                <IonTitle size="large">{title}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}

export default ModalHeader
