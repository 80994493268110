import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  isPlatform,
} from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import { useTheme } from "../../hooks/useTheme";
import "./LoginScreen.scss";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import queryString from "query-string";
import { useAnalytics } from "../../hooks/useAnalytics";

/*TODO:
1. If user is in view coupon click get coupon redirect login but add params the login redirect the coupon
*/
import AppStore from "../../store/AppStore";
import { Capacitor } from "@capacitor/core";
import { Link } from "react-router-dom";

const LoginScreen = () => {
  const [imageMobile, setImageMobile] = useState({
    backgroundImage: "url('assets/img/background-mobile.png')",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  });
  const history = useHistory();
  const location = useLocation();
  const { state } = useTheme();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const { logEvent } = useAnalytics();

  const { showLoginWithService, appVersion } = AppStore.useState((s) => s);
  const goToHome = () => {
    history.push("/featured");
  };

  const goToCountryScreen = (method) => {
    const redirect = queryString.parse(location?.search)?.redirectTo;
    const params = redirect
      ? `?redirectTo=${redirect}&method=${method}`
      : `?method=${method}`;
    history.push(`/login/country${params}`);
  };

  return (
    <IonPage id="login_screen">
      <IonContent fullscreen className="ion-no-border">
        <header className="header-login ion-no-border" mode="md">
          <img src="assets/img/background-mobile.png" height="100%" alt="" />
        </header>
        <div className="login_screen_background">
          <div className="login_screen_box">
            <div className="login_screen_content">
              <div className="login_screen_action">
                <img
                  className="logo"
                  src={
                    state.dark
                      ? "assets/img/logo-claro-club-white.png"
                      : "assets/img/logo-claro-club.png"
                  }
                  width="200px"
                  alt=""
                />
                <p>¡Bienvenido!</p>
                <span>Regístrate para obtener beneficios</span>
                <IonButton
                  onClick={() => goToCountryScreen("phone")}
                  shape="round"
                  color="primary"
                  mode="md"
                >
                  Ingresar con número móvil
                </IonButton>
                {showLoginWithService && (
                  <IonButton
                    onClick={() => goToCountryScreen("other")}
                    shape="round"
                    color="primary"
                    fill="outline"
                    mode="md"
                  >
                    Ingresar con mi servicio residencial
                  </IonButton>
                )}

                <IonButton
                  shape="round"
                  fill="clear"
                  mode="md"
                  onClick={goToHome}
                >
                  Ingresar como invitado
                </IonButton>
                <hr />

                <div className="link">
                  <IonButton
                    shape="round"
                    fill="clear"
                    mode="md"
                    href="https://clubclaro.com.gt/aliados"
                    target="_blank"
                  >
                    ¿Eres un aliado? Ingresa aquí
                  </IonButton>

                  <Link to="/request-partner">
                    <IonButton
                      className="ion-button"
                      shape="round"
                      fill="clear"
                      mode="md"
                    >
                      ¿Quieres unirte como aliado?
                    </IonButton>
                  </Link>
                </div>

                <span className="login_screen_privacy">
                  Al continuar, acepta nuestros Términos y condiciones y nuestra
                  Política de privacidad. Usamos sus datos para ofrecerle una
                  experiencia personalizada y para comprender y mejorar nuestros
                  servicios.
                </span>
                {Capacitor.getPlatform() !== "web" && (
                  <span className="version">App version v{appVersion}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginScreen;
