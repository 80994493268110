import { IonContent, IonModal } from '@ionic/react';
import Header from '../../../../../../components/common/header/Header';
import ThemeSelector from './components/theme-selector/ThemeSelector';
import './ThemeModal.scss';

const ThemeModal = ({showModal, setShowModal}) => {
    return (
        <IonModal
            isOpen={showModal}
            cssClass="claro-club-full-modal theme_modal"
            onDidDismiss={() => setShowModal(false)}
        >
            <Header closeButton onClick={() => setShowModal(false)} />
            <IonContent className="claro-club-content">
                <h3>Seleccione un tema</h3>
                <ThemeSelector />
            </IonContent>
            
        </IonModal>
    )
}

export default ThemeModal
