import AuthStore, { handleLogin } from "../../../../store/AuthStore";
import { callOutline } from "ionicons/icons";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import {
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  isPlatform,
} from "@ionic/react";
import { useDeviceId } from "../../../../hooks/useDeviceId";
import Spacer from "../../../../components/common/spacer/Spacer";
import Button from "../../../../components/common/button-submit/ButtonSubmit";
import CountryModal from "../../../../components/modals/country-modal/CountryModal";
import "./OtherMethod.scss";
import { AUTH_METHODS, } from "../../../../const/authMethods";
import { useToast } from "../../../../hooks/useToast";
import { useForm } from "../../../../hooks/useForm";
import InputSelect from "../../../../components/common/input-select/InputSelect";
import { validEmail } from "../../../../helpers";
import HelpCenterModal from "../../../../components/modals/help-center-modal/HelpCenterModal";
import queryString from "query-string";

const OtherMethod = () => {
  const user = AuthStore.useState((s) => s.user);
  const [country, setCountry] = useState(user.country);
  const [showModalHelpCenter, setShowModalHelpCenter] = useState(false);
  const [values, handleInputChange, reset] = useForm({
    phone: "",
    email: "",
    service: "",
    serviceTypeId: AUTH_METHODS[user.country.id][0],
  });
  const { email, phone, service, serviceTypeId } = values;
  const [method, setMethod] = useState(AUTH_METHODS[user.country.id][0]);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const { id } = useDeviceId();
  const history = useHistory();
  const { presentToast } = useToast();
  const [disableRegisterButton, setDisableRegisterButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const location = useLocation();

  const register = async (e) => {
    try {
      e.preventDefault();
      if (validForm(values)) {
        setLoading(true);
        let response = await handleLogin({
          countryId: user.country.id,
          type: 3,
          ...values,
          serviceTypeId: ![2, 3].includes(user.country.id)
            ? method?.id
            : serviceTypeId.id,
          // uuid: id,
          platform: isPlatform("ios")
            ? "IOS"
            : isPlatform("android")
            ? "Android"
            : "Web",
        });
        console.log(response);
        if (response.status == 200) {
          const redirect = queryString.parse(location.search)?.redirectTo;
          history.replace(redirect ? redirect : "/featured");
        } else {
          presentToast(
            "Oops! ha ocurrido un error, verifique sus datos e intentalo de nuevo",
            5000,
            "danger"
          );
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      presentToast(
        "Oops! ha ocurrido un error, verifique sus datos e intentalo de nuevo",
        5000,
        "danger"
      );
      console.log(err);
      if (err?.data?.case) {
        setError({ ...err?.data });
        setShowModalHelpCenter(true);
      }
    }
  };

  useEffect(() => {
    setCountry(user.country);
    setMethod(AUTH_METHODS[user.country.id][0]);
  }, [user]);

  useEffect(() => {
    if (user.country.id !== 3) {
      if (service !== "" && phone !== "" && email !== "") {
        setDisableRegisterButton(false);
      } else {
        setDisableRegisterButton(true);
      }
    } else {
      if (
        service !== "" &&
        phone !== "" &&
        email !== "" &&
        serviceTypeId !== null &&
        Object.keys(serviceTypeId).length
      ) {
        setDisableRegisterButton(false);
      } else {
        setDisableRegisterButton(true);
      }
    }
  }, [values]);

  function validForm({ email, phone }) {
    return Object.entries({ email, phone }).map(([key, value]) => {
      if (key == "email" && (value === "" || !validEmail(value))) {
        console.log(key, value, validEmail(value));
        presentToast(
          "El campo correo electrónico tiene un formato incorrecto!",
          5000,
          "warning"
        );
        return false;
      }
      if (key === "phone" && value === "" && value.length < 8) {
        presentToast(
          "El campo teléfono debe contener un mínimo de 8 dígitos!",
          5000,
          "warning"
        );
        return false;
      }
      return true;
    });
  }

  return (
    <div className="other_method">
      <div className="call-icon">
      <IonIcon icon={callOutline} color="primary"/>
      </div>
      <p>Ingresa con {method?.label.toLowerCase()}</p>

      <div
        className="other_method_country"
        onClick={() => setShowCountryModal(true)}
      >
        <IonIcon icon={country.icon} />
        <p>{country.name}</p>
      </div>
      <form id="other-login-form" onSubmit={register}>
        {[2, 3].includes(user.country.id) && (
          <InputSelect
            label="Tipo de servicio"
            name="serviceTypeId"
            value={serviceTypeId}
            onChange={handleInputChange}
            items={AUTH_METHODS[user.country.id]}
          />
        )}
        <IonItem mode="md" lines="none" className="claro-club-input-outline">
          <IonLabel mode="ios" position="stacked">Servicio</IonLabel>
          <IonInput
            type="text"
            value={service}
            name="service"
            onIonInput={handleInputChange}
            placeholder="00000000"
            required
          />
        </IonItem>
        <IonItem mode="md" lines="none" className="claro-club-input-outline">
          <IonLabel mode="ios" position="stacked">Correo electrónico</IonLabel>
          <IonInput
            name="email"
            value={email}
            onIonInput={handleInputChange}
            type="email"
            placeholder="Ingresa correo electrónico de contacto"
            required
          />
        </IonItem>
        <IonItem mode="md" lines="none" className="claro-club-input-outline">
          <IonLabel mode="ios" position="stacked">Teléfono</IonLabel>
          <IonInput
            name="phone"
            value={phone}
            minlength="8"
            onIonInput={handleInputChange}
            type="phone"
            placeholder="Ingresa teléfono de contacto"
            required
          />
        </IonItem>
        <Spacer height={32} />
        <Button
          type="form"
          form="other-login-form"
          label="Ingresar"
          disabled={disableRegisterButton}
          loading={loading}
        />
      </form>
      <CountryModal
        showModal={showCountryModal}
        setShowModal={setShowCountryModal}
        selectedCountry={country.id}
      />
      <HelpCenterModal
        showModal={showModalHelpCenter}
        setShowModal={setShowModalHelpCenter}
        numberCase={error?.case}
        country={country}
      />
    </div>
  );
};

export default OtherMethod;
