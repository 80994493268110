import { apiCall } from "../config/apiConfig";
import { environment } from "../const/environment";

const PropertiesServices = {
    getAll() {
        return apiCall.get(`${environment.API_PUBLIC}/properties`,);
    },
    getAllByCountry({countryId}) {
        return apiCall.get(`${environment.API_PUBLIC}/countries/${countryId}/properties`,);
    }
}

export {
    PropertiesServices
}