import {
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  IonItem,
} from "@ionic/react";
import "./Record.scss";
import { phonePortraitOutline } from "ionicons/icons";
import { useAnalytics } from "../../../../hooks/useAnalytics";

export const Record = ({ loading, redemptions, error }) => {
  const { logEvent } = useAnalytics();
  const handleChange = (e) => {
    logEvent("Ver_Historial_Canje");
  };
  return (
    <IonAccordionGroup
      mode="ios"
      className="accordion-group-record"
      onIonChange={handleChange}
    >
      <IonAccordion mode="ios" value="record" className="accordion-record">
        <IonItem mode="ios" fill="outline" slot="header" color="none">
          <h3>Historial de canjeados</h3>
        </IonItem>
        <div className="ion-padding" slot="content">
          {!loading && error && (
            <div className="placeholder">
              <img src="assets/icon/error.png" alt="error" />
              <p className="description">
                Ha ocurrido un error, por favor intenta más tarde!
              </p>
            </div>
          )}
          {!error && !loading && !redemptions?.length ? (
            <div className="placeholder">
              <IonIcon src="assets/icon/record.svg" />
              <p className="description">
                No has canjeado beneficios en esta categoría
              </p>
            </div>
          ) : (
            <ul className="records">
              {!loading &&
                redemptions?.map((record) => (
                  <li key={record.id}>
                    <div className="title-content">
                      <IonIcon icon={phonePortraitOutline} />
                      <div className="title">
                        <p className="description">{record?.benefit}</p>
                        {record?.date ? (
                          <p className="date">
                            {`  Canjeado el ${new Date(
                              record?.createdAt
                            ).toLocaleDateString("es", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })} a las  ${new Date(
                              record?.createdAt
                            ).toLocaleTimeString("es", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}`}
                          </p>
                        ) : (
                          <p className="date">Canjeado</p>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
};
