import { IonIcon, IonItem, IonLabel, IonReorder } from '@ionic/react';
import { reorderFourOutline } from 'ionicons/icons';
import './ItemDetailIcon.scss';

const ItemDetailIcon = ({ color = "dark", lines = "inset", icon, detail=false, title, subtitle, onClick = () => { }, order, disabled= false }) => {
    return (
        <IonItem lines={lines} id="item_detail_icon" button detail={detail} onClick={onClick} disabled={disabled}>
            {icon && <IonIcon color={color} icon={icon} slot="start" />}
            {order && <IonReorder slot="start" />}
            <IonLabel color={color}>
                <h3>{title}</h3>
                {subtitle && <p>{subtitle}</p>}
            </IonLabel>
        </IonItem>
    )
}

export default ItemDetailIcon
