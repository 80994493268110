import { IonIcon, IonImg } from "@ionic/react";
import { layersOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { useAnalytics } from "../../../../hooks/useAnalytics";
import "./CategoryItem.scss";

const CategoryItem = ({ id, amountPartners, picture, title }) => {
  const history = useHistory();
  const { logEvent } = useAnalytics();

  const goToStoresScreen = () => {
    logEvent("view_item_list", { item_category: id });
    history.push(`/categories/${id}`);
  };

  return (
    <div id="category_item" onClick={goToStoresScreen}>
      <div className="category_item_title">
        <h3>{title}</h3>
        <div className="category_item_title_icon">
          <IonIcon icon={layersOutline} />
          <p>
            {amountPartners} Establecimiento{amountPartners > 1 && "s"}
          </p>
        </div>
      </div>
      <IonImg src={picture} onIonError={e => {
        e.target.src = 'https://clubclaro.com.gt/content/images/default.jpeg'
      }} />
    </div>
  );
};

export default CategoryItem;
