import { IonCol } from "@ionic/react";
import { useEffect } from "react";
import { useParams } from "react-router";
import Placeholder from "../../../../components/common/placeholder/Placeholder";
import CouponSkeleton from "../../../../components/coupon-skeleton/CouponSkeleton";
import Coupon from "../../../../components/coupon/Coupon";
import { ViewStates } from "../../../../const/viewStates";
import { useCoupons } from "../../../../hooks/useCoupons";
import { useSegments } from "../../../../hooks/useSegments";
import AuthStore from '../../../../store/AuthStore'

const StoreCouponsList = () => {

    const { state, loadCouponsByPartner } = useCoupons();
    const { id } = useParams();
    const user = AuthStore.useState(s => s.user);
    const {loadSegments} = useSegments()
    const fakeArray = Array.from(Array(16).keys());

    const fetchs = async () => {
        if (user.role === 'guest') {
            loadCouponsByPartner(id);
         } else {
             const {segments} = await loadSegments() ?? {segments: []}
             loadCouponsByPartner(id, segments, user?.id)
         }
    }
    useEffect(() => {
        fetchs();
    }, []);

    return (
        <>
            {

                (state.viewState === ViewStates.EMPTY || state.viewState === ViewStates.ERROR) &&
                <Placeholder state={state.viewState} />

            }
            {
                state.viewState === ViewStates.LOADING &&
                fakeArray.map((_, index) => (
                    <IonCol key={index} size="6" size-md="6" size-xl="4">
                        <CouponSkeleton key={index} />
                    </IonCol>
                ))
            }

            {
                state.viewState === ViewStates.CONTENT &&
                state.data.map(coupon => (
                    <IonCol key={coupon.id} size="6" size-md="6" size-xl="4">
                        <Coupon key={coupon.id} {...coupon} />
                    </IonCol>
                ))
            }
        </>
    )

}

export default StoreCouponsList
