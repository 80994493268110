import { LocalNotifications } from '@capacitor/local-notifications'
import { notifications } from 'ionicons/icons'
class Notifications {
    async schedule(history, data) {

        try {
            data = data ? data :     {
                "lat": 14.642437,
                "lng": -90.51313,
                "picture": "c5285a9e-6251-5efe-ab31-d7d06d585870",
                "position": 3,
                "id": 3603,
                "title": "Villa Lucre",
                "benefitId": 635,
                "partnerTitle": "Papa John's",
                "partnerId": 762,
                "distance": 9.85,
                "benefitTitle": "¡Disfruta de -30% en Pizzas del menú regular!"
            }
            // Request/ check permissions
            await LocalNotifications.requestPermissions()

            LocalNotifications.registerActionTypes({
                types: [
                    {
                        id: 'VIEW_MORE',
                        actions: [{
                            id: 'view',
                            title: 'Ver más',
                        }, {
                            id: 'remove',
                            title: 'Cerrar',
                            destructive: true
                        }]
                    }
                ]
            })

            // Clear old notifications in prep for refresh (OPTIONAL)
            const pending = await LocalNotifications.getPending()
            if (pending.notifications.length > 0)
                await LocalNotifications.cancel(pending)

            LocalNotifications.addListener('localNotificationReceived', (notification) => {
                console.log(notification)
            })

            LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction) => {
                console.log(`action: ${notificationAction.actionId} --- input: ${JSON.stringify(notificationAction)}`)
                console.log(history)
                if (['view', 'tap'].includes(notificationAction.actionId)) {
                    history.push(`/coupon/${notificationAction.notification?.extra?.benefitId}`)
                }
            })

            LocalNotifications.addListener('url')


            await LocalNotifications.schedule({
                notifications: [{
                    title: data?.partnerTitle,
                    body: data?.benefitTitle,
                    id: new Date().getTime(),
                    actionTypeId: 'VIEW_MORE',
                    extra: {
                        ...data
                    },
                    attachments: [{
                        id: 'benefit',
                        url: 'https://www.saborusa.com/wp-content/uploads/2019/10/Animate-a-disfrutar-una-deliciosa-pizza-de-salchicha-Foto-destacada.png'
                    }],
                    schedule: {
                        // on: { // swap this out for at or every as needed
                        //     hour,
                        //     minute
                        // }
                    }
                }]
            })

        } catch (error) {
            console.error(error)
        }
    }
}

export default new Notifications()