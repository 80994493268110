import { IonButton, IonButtons,  IonContent,  IonHeader, IonIcon, IonicSafeString, IonItem, IonLabel, IonList, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import { countries } from '../../../const/countries';
import { updateUser } from '../../../store/AuthStore';
import './CountryModal.scss';
import { getAdvancedConfigByCountry } from '../../../store/AdvancedConfigStore';

const CountryModal = ({showModal, setShowModal, selectedCountry}) => {
    
    const select = (country) => {
        getAdvancedConfigByCountry({countryId: country.id});
        updateUser({country});
        setShowModal(false);
    }

    return (
        <IonModal id="country_modal" mode="ios" isOpen={showModal}  onDidDismiss={() => setShowModal(false)}>
        <IonHeader mode="ios">
            <IonToolbar mode="md">
                <IonButtons slot="start">
                    <IonButton shape="round" onClick={() => setShowModal(false)}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonButtons>
                    <IonTitle size="large">Seleccione su país</IonTitle>
            </IonToolbar>
        </IonHeader>
       <IonContent>
       {countries.length && <IonList className="country_modal_list">
            {countries.map(country => (
                <IonItem button onClick={() => select(country)} lines="none" detail="false"  key={country.id} className={selectedCountry === country.id && 'selected'}>
                    <IonIcon lazy={true} src={country?.icon}/>
                    <IonLabel>
                            <h2><b>(+{country.areaCode})</b> {country.name}</h2> 
                    </IonLabel>
                </IonItem>
            ))}
        </IonList>}
       </IonContent>
      </IonModal>
    )
}

export default CountryModal
