import ReactLottie from "react-lottie-player";

export default function Lottie({ animationData, loop, play, style, ...rest }) {
  return (
    <ReactLottie
      animationData={animationData}
      loop={loop}
      play={play}
      style={style}
      {...rest}
    />
  );
}
