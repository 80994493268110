import { IonChip, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ViewStates } from '../../../../const/viewStates';
import { useSubcategories } from '../../../../hooks/useSubcategories';
import './SubcategoriesChips.scss';

const SubcategoriesChips = ({onValueChange}) => {

    const { id } = useParams();
    const {state, loadSubcategories} = useSubcategories();
    const [selected, setSelected] = useState('all');

    const onChange = (id) => {
        setSelected(id);
        onValueChange(id);
    }

    useEffect(() => {
        loadSubcategories(id);
    }, [id])

    return (
        <div id="subcategories_chips_content">
        <IonChip onClick={() => onChange('all')} className={selected === 'all' && 'selected'}>
            <IonLabel> Todos </IonLabel>
        </IonChip>
        {
        state.viewState === ViewStates.CONTENT &&
        state.data.map(sub => (
            <IonChip key={sub.id} onClick={() => onChange(sub.id)} className={selected === sub.id && 'selected'}>
                <IonLabel> {sub.title} </IonLabel>
            </IonChip>
            ))
        }  
        </div>
        )
}

export default SubcategoriesChips
