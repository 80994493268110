import { IonContent, IonHeader, IonModal } from "@ionic/react";
import ResponsiveContainer from "../../common/responsive-container/ResponsiveContainer";
import ModalHeader from "../modal-header/ModalHeader";
import './HelpCenterModal.scss';

const HelpCenterModal = ({ showModal, setShowModal = () => { }, numberCase, country }) => {
    return (
        <IonModal id="help_center_modal" mode="ios" isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
            <ModalHeader title="" onClick={setShowModal} />
            <IonContent>
                <ResponsiveContainer>
                    <div className="container-help-center">
                        <h3>Contáctanos</h3>
                        <p>No fue posible autenticarse en Claro Club</p>
                        <p>Llama a call center {country?.phone} e indica el siguiente numero de caso:</p>
                        <h3>{numberCase}</h3>
                    </div>
                </ResponsiveContainer>
            </IonContent>
        </IonModal>
    );
}

export default HelpCenterModal;