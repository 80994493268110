import { apiCall } from "../config/apiConfig";

export const AuthService = {
    register: (params) => {
        return apiCall.post('/register', params);
    },
    login: (credentials) => {
        return apiCall.post('/auth', credentials);
    }
}

