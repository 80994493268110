import { Geolocation } from '@capacitor/geolocation';
import { useEffect, useState } from "react";
import { isPlatform } from '@ionic/react';

const positionOptions = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 50000
}

export const useGeolocation = () => {

    const [coordinates, setCoordinates] = useState({ lat: 14.6083754, lng: -90.7131131});

    useEffect(() => {
        getCurrentPosition();
    }, [])

    const getCurrentPosition = async () => {
        // const {location} = await Geolocation.checkPermissions();
        const {coords} = await Geolocation.getCurrentPosition(positionOptions);
        setCoordinates({lat: coords.latitude, lng: coords.longitude});          
    };
    return {
        coordinates
    }

}