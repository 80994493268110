import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { useClient } from "./useClient";
import { Capacitor } from "@capacitor/core";
import AuthStore from "../store/AuthStore";
import { countries } from "../const/countries";
import { useHistory } from "react-router";

export const usePushNotifications = () => {
  const history = useHistory();

  const user = AuthStore.useState((s) => s.user);
  const { handleUpdate } = useClient();

  const eventListener = () => {
    // On success, we should be able to receive notifications

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received: " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      ({ actionId, notification }) => {
        if (actionId === "tap") {
          console.log(
            "pushNotificationActionPerformed: " + JSON.stringify(notification)
          );
          history.push(`/coupon/${notification.data?.benefitId}`);
        }
      }
    );
  };

  const getDeviceAndGlobalTopics = () => {
    const countryTopic = countries[user.country.id - 1].topic;
    let topics = [`global-${countryTopic}`];
    const device =
      Capacitor.getPlatform() === "ios"
        ? "ios"
        : Capacitor.getPlatform() === "android"
        ? "android"
        : "";
    if (device && device !== "")
      topics = [...topics, `${device}-${countryTopic}`];
    return topics;
  };

  const deviceAndGlobalSubscribeTopic = (session) => {
    saveTopics({
      session,
      topics: [...getDeviceAndGlobalTopics()],
      isGlobalAndDevice: true,
    });
  };

  const saveTopics = async ({
    session,
    topics = [],
    isGlobalAndDevice = false,
  }) => {
    try {
      session = session || user;
      const key = `${user.country.areaCode}${user.phone}`;
      const cache = JSON.parse(localStorage.getItem(key));
      if (!cache) {
        topics.map((topic) => subscribe(topic));
      } else {
        if (!isGlobalAndDevice && topics.length)
          topics = [...topics, ...getDeviceAndGlobalTopics()];
        //unsubscribe topic
        cache
          .filter((i) => !topics.includes(i))
          .map((topic) => unsubscribe(topic));
        //subscribe topic
        topics
          .filter((i) => !cache.includes(i))
          .map((topic) => subscribe(topic));
      }

      localStorage.setItem(key, JSON.stringify([...topics]));
    } catch (error) {
      Promise.reject(error);
    }
  };

  const updateAllTopics = async () => {
    const key = `${user.country.areaCode}${user.phone}`;
    const cache = JSON.parse(localStorage.getItem(key));
    if (cache) {
      cache.filter((topic) => unsubscribe(topic));
      cache.filter((topic) => subscribe(topic));
    }
  };

  const subscribe = async (topic) => {
    FCM.subscribeTo({ topic })
      .then(({ message }) => console.log(message))
      .catch((err) => console.log(err));
  };

  const unsubscribe = async (topic) => {
    FCM.unsubscribeFrom({ topic })
      .then(() => console.log("unsubscribed from topic"))
      .catch((err) => console.log(err));
  };

  const verifyToken = async (user, token) => {
    const TOKEN_STORAGE = localStorage.getItem("TOKEN_FCM");
    if (!TOKEN_STORAGE || token !== TOKEN_STORAGE) {
      console.log({ TOKEN_STORAGE, token });
      token !== TOKEN_STORAGE && updateAllTopics();
      localStorage.setItem("TOKEN_FCM", token);
      await handleUpdate(token, user.id);
    }
  };

  const pushNotificationsInit = async (user) => {
    try {
      const { receive } = await PushNotifications.checkPermissions();
      if (receive === "prompt") {
        await PushNotifications.requestPermissions();
      }

      if (receive !== "granted") {
        throw new Error("User denied permissions!");
      }

      await PushNotifications.register();
      if (Capacitor.getPlatform() === "ios") {
        const { token } = await FCM.getToken();
        verifyToken(user, token);
      }
      if (Capacitor.getPlatform() === "android") {
        PushNotifications.addListener("registration", async ({ value }) => {
          await verifyToken(user, value);
        });
      }

      deviceAndGlobalSubscribeTopic(user);
      eventListener();
    } catch (error) {
      console.log({ error });
      return Promise.reject(error);
    }
  };

  return {
    pushNotificationsInit,
    saveTopics,
  };
};
